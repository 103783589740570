.no-csstransforms3d .mp-pusher,
.no-js .mp-pusher {
  padding-left: 0
}

.no-csstransforms3d .mp-menu .mp-level,
.no-js .mp-menu .mp-level {
  display: none
}

.no-csstransforms3d .mp-menu > .mp-level,
.no-js .mp-menu > .mp-level {
  display: block
}

.mp-menu {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 300px;
  height: 100vh;
  -webkit-transition: transform .5s;
  transition: transform .5s;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%)
}

.mp-level {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 50px);
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%)
}

.mp-menu > .mp-level {
  top: 17rem;
}

.mp-menu > .mp-level.mp-level-open {
  height: calc(100% - 50px)
}

.mp-level.mp-level-open {
  overflow: auto;
  height: calc(100vh - 50px)
}

.mp-level.mp-level-open.mp-level-overlay {
  overflow: hidden;
  height: 200vh
}

.mp-level.mp-level-open.mp-level-overlay > ul > li:not(.m_current) {
  visibility: hidden;
  opacity: 0
}

.mp-level.mp-level-overlay {
  cursor: pointer
}

.mp-level.mp-level-overlay.mp-level:before {
  width: 100%;
  height: 100%;
  opacity: 1;
  background: 0 0
}

.mp-level,
.mp-pusher {
  -webkit-transition: transform .5s;
  -moz-transition: transform .5s;
  transition: transform .5s
}

.mp-overlap .mp-level.mp-level-open {
  -webkit-transform: translateX(-40px);
  -ms-transform: translateX(-40px);
  transform: translateX(-40px);
  box-shadow: 1px 0 2px rgba(0,0,0,.2)
}

.mp-menu > .mp-level,
.mp-menu > .mp-level.mp-level-open,
.mp-menu.mp-overlap > .mp-level,
.mp-menu.mp-overlap > .mp-level.mp-level-open {
  -webkit-transition: transform .5s,-webkit-transform .5s;
  -moz-transition: transform .5s,-moz-transform .5s;
  transition: transform .5s;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  box-shadow: none
}

.mp-cover .mp-level.mp-level-open {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0)
}

.mp-cover .mp-level.mp-level-open > ul > li > .mp-level:not(.mp-level-open) {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%)
}

.mp-menu .title {
  font-size: 16px;
  font-weight: 600;
  line-height: 40px;
  display: block;
  min-height: 40px;
  margin: 0 20px;
  padding: .7em 0;
  text-transform: uppercase;
  color: rgba(0,0,0,.7);
  text-shadow: 0 0 1px rgba(0,0,0,.1)
}

.mp-menu .title .text {
  margin-left: 20px;
  vertical-align: middle
}

.mp-menu .title .picto + .text {
  margin-left: 0
}

.mp-menu .close_bloc .title,
.mp-menu .mp-level .mp-level .mp-level .title {
  color: #fff
}

.mp-menu .mp-level .mp-level .mp-level .title a {
  line-height: 18px;
  display: inline-block;
  padding-bottom: 3px;
  vertical-align: middle
}

.mp-menu .title a:hover,
.mp-menu ul li .title a:hover {
  color: #56c1ff;
  background: 0 0
}

.mp-menu .title .picto,
.mp-menu .title a .picto {
  font-size: 40px;
  line-height: 40px;
  overflow: visible;
  width: auto;
  margin: 0 5px
}

.mp-menu .title .picto + .centered-height {
  width: calc(100% - 60px)
}

.mp-menu.mp-overlap .title:before {
  font-size: 75%;
  line-height: 1.8;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 8px;
  -webkit-transition: opacity .3s,-webkit-transform .1s .3s;
  -moz-transition: opacity .3s,-moz-transform .1s .3s;
  transition: opacity .3s,transform .1s .3s;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  opacity: 0
}

.mp-overlap .mp-level.mp-level-overlay > .title:before {
  -webkit-transition: -webkit-transform .3s,opacity .3s;
  -moz-transition: -moz-transform .3s,opacity .3s;
  transition: transform .3s,opacity .3s;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  opacity: 1
}

.mp-menu ul {
  overflow: hidden;
  list-style: none;
  text-align: left;
  padding-left: 2rem;
}

.mp-menu > .mp-level > .level1 {
  position: relative;
  overflow: auto;
  width: 300px;
  margin: 0;
  padding: 0
}

.mp-menu ul li:before {
  line-height: 30px;
  position: absolute;
  z-index: -1;
  left: 10px;
  color: rgba(0,0,0,.8)
}

.mp-menu ul li {
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  display: block;
  padding: .6rem 2rem;
  outline: 0
}

.mp-menu ul.level1 > li {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: .6rem;
  transition: color .3s;
  &:hover {
    color: $color4;
  }
  .icon {
    line-height: 0;
    transition: transform .3s;
  }
  &.open-level2 .icon {
    transform: rotate(90deg);
  }
}

.mp-menu ul.level2 {
  height: 0;
  margin-bottom: 1rem;
  overflow: hidden;
}

.mp-menu ul li.panier_link_mobile{
  margin-left: auto;
  margin-right: auto;
  .button{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 1.2rem 3rem;
    font-size: 2rem;
    border-radius: 4.8rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.mp-menu ul li.sep-horizontal {
  display: block;
  width: 75%;
  height: .1rem;
  padding: 0;
  margin: 1rem auto;
  background-color: #781629
}

.mp-menu ul li > a .icon {
  font-size: 12px
}

#mp-menu .mp-level ul {
  list-style: none
}

#mp-menu .mp-level-2 a {
  line-height: 20px;
  vertical-align: middle;
  text-transform: none
}

#mp-menu .mp-level-2 .centered-height a {
  line-height: 1;
  display: inline-block;
  float: none;
  vertical-align: middle
}

#mp-menu .mp-level-2 .c_container a,
#mp-menu .mp-level-2 .c_container span {
  line-height: 20px;
  color: #000
}

#mp-menu .mp-level-2 .c_container span {
  color: #000
}

#mp-menu .mp-level-2 a.mp-back {
  text-transform: uppercase
}

#mp-menu .mp-level-2 a.mp-back:hover {
  color: #000
}

#mp-menu .mp-level-2 ul {
  margin: 0;
  padding: 0
}

#mp-menu .mp-level-2 li {
  padding: 0 5px
}

.mp-level .espace_mobile .text,
.mp-level a .text {
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  padding-left: 0;
  -webkit-transition: border-color .3s ease-in,padding .3s ease-in;
  -moz-transition: border-color .2s ease-in,padding .2s ease-in;
  transition: border-color .2s ease-in,padding .2s ease-in;
  vertical-align: middle
}

.mp-level .espace_mobile:hover .text,
.mp-level a:hover .text {
  padding-left: 5px
}

.mp-level .espace_mobile {
  line-height: 22px;
  padding: .7em 1em .7em 1em;
  cursor: pointer;
  color: #fff;
  border: none;
  outline: 0;
  background-color: transparent
}

.mp-level .espace_mobile:hover .picto,
.mp-level .espace_mobile:hover .text {
  color: #000
}

.mp-level .espace_mobile .picto {
  font-size: 22px;
  overflow: visible;
  width: 24px;
  margin: 0 10px 0 0
}

.mp-menu.out ul li a:hover {
  background: rgba(0,0,0,.2);
  box-shadow: inset 0 -1px transparent
}

.mp-level.mp-level-overlay > ul > li:first-child > a,
.mp-menu.out .mp-level.mp-level-overlay > ul > li > a {
  box-shadow: inset 0 -1px transparent
}

.mp-level.mp-level-overlay > ul > li:first-child > a {
  box-shadow: inset 0 -1px transparent,inset 0 1px transparent
}

#mp-menu .mp-level .picto_menu {
  position: static;
  margin: 0 0 0 6px;
  -webkit-transition: border-color .2s ease-in,margin .3s ease-in;
  -moz-transition: border-color .2s ease-in,margin .3s ease-in;
  transition: border-color .2s ease-in,margin .3s ease-in;
  vertical-align: middle;
  border-color: transparent transparent transparent rgba(0,0,0,.7)
}

#mp-menu .mp-level a:hover .picto_menu {
  border-color: transparent transparent transparent #fff
}

.mp-back {
  font-size: 1.2em;
  font-weight: 700;
  position: relative;
  display: block;
  padding: 1em 1em 1em 2.4em;
  -webkit-transition: color .2s,background .3s,padding .2s;
  -moz-transition: color .2s,background .3s,padding .2s;
  transition: color .2s,background .3s,padding .2s;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
  outline: 0;
  background: rgba(0,0,0,.1);
  box-shadow: inset 0 1px rgba(0,0,0,.1)
}

.mp-back:hover {
  padding: 1em 1em 1em 2.7em
}

.mp-menu.out .mp-level.mp-level-overlay > .mp-back {
  color: transparent;
  background: 0 0;
  box-shadow: none
}

#mp-menu .mp-back::before,
#mp-menu .mp-level-2 .mp-back::before {
  font-family: kfont!important;
  margin-right: 20px;
  content: '\ea01';
  vertical-align: middle
}

#mp-menu .mp-back .picto_menu {
  border-color: transparent rgba(0,0,0,.7) transparent transparent
}

#mp-menu .mp-level .mp-back:hover .picto_menu {
  border-color: transparent #fff transparent transparent
}

.mp-level li {
  margin-left: 0
}

.m_event:after {
  font-family: kfont!important;
  float: right;
  margin-right: 40px;
  content: '\ea01';
  transform: scaleX(-1);
  vertical-align: middle;
  color: #000
}

#fancybox-content > div,
.fancybox-inner {
  overflow: hidden!important
}

#fancybox-content > div img {
  width: 100%
}

.mp-pusher {
  -webkit-transition: transform .5s;
  transition: transform .5s
}

.fancy_visible .scroller,
.menu_visible .scroller {
  overflow: hidden;
  height: 100%
}

.menu_visible html {
  overflow: hidden
}

.mp-fancy {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 340px;
  height: 100%;
  -webkit-transform: translateX(-340px);
  -ms-transform: translateX(-340px);
  transform: translateX(-340px)
}

.scroller:before {
  position: absolute;
  z-index: 9999;
  top: 0;
  right: 0;
  display: block;
  width: 0;
  height: 0;
  content: ''
}

.scroller.mp-pushed:before {
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
  -webkit-transition: background .5s;
  transition: background .5s;
  background: rgba(0,0,0,.5)
}

.mp-pusher.fancy_visible {
  -webkit-transform: translateX(340px);
  -ms-transform: translateX(340px);
  transform: translateX(340px)
}

.mp-pusher.menu_visible {
  -webkit-transform: translateX(300px);
  -ms-transform: translateX(300px);
  transform: translateX(300px)
}

.mp-pusher::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  content: '';
  opacity: 0
}

.mp-pusher.fancy_visible {
  -webkit-transform: translateX(340px);
  -ms-transform: translateX(340px);
  transform: translateX(340px)
}

#mp-fancy > .header {
  position: relative;
  border-bottom: 5px solid #5cb39b
}

#menu_launcher {
  display: none;
  position: absolute;
  top: 4.5rem;
  left: 1rem;
  font-size: 20px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  color: #fff
}

.page_home #menu_launcher{
  display: none;
}

#header.sticky #menu_launcher {
  top: .4rem
}

.header_top .line2{
  position: relative;
}

#menu_launcher a {
  display: block
}

#menu_launcher .text {
  font-size: 1.1em;
  line-height: 1;
  padding-bottom: 5px;
  vertical-align: middle;
  text-transform: uppercase;
  color: #191818
}

.picto-menu {
  position: absolute;
  top: 16px;
  left: 6px;
  display: inline-block;
  width: 28px;
  height: 2px;
  transition: all .3s ease-in-out 0s;
  background: #000
}

.picto-menu:after,
.picto-menu:before {
  position: absolute;
  left: 0;
  display: inline-block;
  width: 28px;
  height: 2px;
  content: '';
  transition: all .3s ease-in-out 0s;
  -webkit-transform-origin: center center 0;
  transform-origin: center center 0;
  background: #000 none repeat scroll 0 0
}

.picto-menu:before {
  top: 8px
}

.picto-menu:after {
  top: -8px
}

.menuhidden .picto-menu:before {
  height: 2px;
  transform: rotate(45deg)
}

.menuhidden .picto-menu:after {
  height: 2px;
  transform: rotate(-45deg)
}

.menuhidden .picto-menu:after,
.menuhidden .picto-menu:before {
  top: 0;
  transform-origin: 50% 50% 0
}

.menuhidden .picto-menu {
  background: transparent none repeat scroll 0 0
}

#mh_close {
  text-align: right;
  cursor: pointer;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 10px;
  right: 20px
}

#mh_close .icon {
  padding: 10px;
  cursor: pointer
}

#mp-menu #mh_close {
  cursor: pointer;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 10px;
  right: 20px
}

#logo_mh {
  width: 240px;
  margin: .5rem auto .5rem;
  text-align: center;
  img{
    object-fit: contain;
  }
  .button {
    text-transform: none;
    border-radius: 4.8rem;
    font-size: 1.8rem;
    padding: .8rem 1.6rem;
    &:hover {
      color: $color4!important;
    }
  }
}

#logo_mh::after {
  content: "";
  display: block;
  width: 75%;
  margin: 2rem auto;
  height: .1rem;
  background-color: #e6a61c
}

#mp-menu #mh_close {
  position: absolute;
  top: 40px;
  right: 20px;
  width: 40px;
  height: 40px;
  cursor: pointer
}

.symbol {
  font-size: 1em;
  position: relative;
  display: inline-block
}

.symbol-close {
  position: relative;
  width: 30px;
  height: 2px;
  margin: 19px 5px;
  transform: rotate(45deg);
  background: #3d3d3d
}

.symbol-close:after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 30px;
  height: 2px;
  content: '';
  transform: rotate(90deg);
  transform-origin: 50% 50%;
  background: #3d3d3d
}

.info_menu_desktop {
  font-size: 1.6rem
}

@media (max-width:1499px) {
  .header_top .line2 #logo{
    margin-left: 6rem;
  }
  #menu_launcher{
    top: 2.2rem;
  }
}
@media (max-width:992px) {
  .page_home #menu_launcher{
    display: block;
  }
  .mp-pusher.menu_visible {
    -webkit-transform: translateX(300px);
    -ms-transform: translateX(300px);
    transform: translateX(300px)
  }

  .mp-level.mp-level-open.mp-level-overlay {
    overflow: hidden;
    height: 200vh
  }
}

@media only screen and (max-width:767px) {
}

@media only screen and (max-width:575px) {
  .mp-menu,
  .mp-menu .title,
  .mp-menu > .mp-level > ul {
    width: 300px
  }

  #mp-menu #mh_close {
    top: 0
  }

  .mp-pusher.fancy_visible {
    -webkit-transform: translateX(340px);
    -ms-transform: translateX(340px);
    transform: translateX(340px)
  }

  .mp-pusher.menu_visible {
    -webkit-transform: translateX(300px);
    -ms-transform: translateX(300px);
    transform: translateX(300px)
  }

  .mp-pusher.fancy_visible {
    -webkit-transform: translateX(340px);
    -ms-transform: translateX(340px);
    transform: translateX(340px)
  }
}