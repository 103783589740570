.navliste ul{
  position: relative;
  margin-bottom: 3rem;
  padding: 0;
  text-align: center;
  li{
    display: inline-block;
    margin: 0 .3rem;
    font-size: 2.5rem;
    list-style-type: none;
    &.current a{
      color: $color3;
    }
    &.navig .icon{
      vertical-align: baseline;
      font-size: 1.5rem;
    }
    .link{
      position: relative;
    }
  }
}

.sub_nav_content {
  position: absolute;
  z-index: 2;
  top: 4.4rem;
  right: 0;
  left: 0;
  overflow: hidden;
  max-height: 0;
  transition: all .1s ease-in-out;
  .sub_nav {
    padding: .5rem;
    text-align: center;
    border: .1rem solid #e3e3e3;
    background-color: #fff;
    font-size: .5rem;
    .sub_nav li {
      margin: .1rem;
    }
  }
}

.truncate.shownav{
  > .link::before,
  > .link::after{
    position: absolute;
    z-index: 3;
    left: 50%;
    width: 0;
    height: 0;
    content: '';
    border-style: solid;
  }
  > .link::before {
    bottom: -1.1rem;
    margin-left: -.6rem;
    border-width: 0 .6rem .9rem .6rem;
    border-color: transparent transparent #ccc transparent;
  }
  > .link::after {
    bottom: -1.2rem;
    margin-left: -.5rem;
    border-width: 0 .5rem .8rem .5rem;
    border-color: transparent transparent #fff transparent;
  }
  .sub_nav_content {
    max-height: 50rem;
  }
}

@media (max-width: $breakpoint-xs) {
  .navliste ul li.hidden_mobile{
    display: none;
  }
}