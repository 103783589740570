#cms.cms_code_merci-huttopia,
#cms.cms_code_huttopia,
#cms.cms_code_merci-luckey,
#cms.cms_code_luckey{
  #header,
  #chemin{
    display: none;
  }
  .h3{
    line-height: 1.2;
    margin-bottom: 2rem;
  }
  .h4{
    text-transform: none;
  }
  hr{
    border-top: 1px solid rgba(0,0,0,0.3);
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  input,
  select{
    padding-top: 0;
    cursor: pointer;
  }
  .valid-form input[type="radio"] + label,
  .valid-form input[type="checkbox"] + label{
    display: inline;
    cursor: pointer;
    font-weight: 700;
  }
  .log_error{
    ul{
      list-style-type: none;
      .icon{
        margin-right: .5rem;
        color: $color2;
      }
    }
  }
}

.formule-huttopia,
.formule-luckey{
  text-align: center;
  margin-bottom: 1rem;
}

.cms_code_merci-huttopia .nl-cms.newsletter_footer .newsletter_container,
.cms_code_merci-luckey .nl-cms.newsletter_footer .newsletter_container{
  text-align: left;
  em.invalid{
    color: $color2;
  }
}

.huttopia-liste{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .h4{
    width: 100%;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  .huttopia-card{
    width: calc(50% - 2rem);
    background-color: #fff;
    margin-bottom: 2.5rem;
    > img{
      width: 100%;
    }
    label{
      width: 100%;
      img{
        object-fit: contain;
        width: 100%;
        cursor: pointer;
      }
    }
    .huttopia-recette-input{
      display: flex;
      align-items: center;
      padding: 1rem;
      label{
        line-height: 1.1;
      }
      select{
        width: 2.5rem;
        text-align: center;
        height: 2.5rem;
        margin-right: 1.5rem;
        border:none;
        background-color: #ccc;
        padding-left: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }
    }
  }
}

@media (max-width: $breakpoint-xs) {
  .huttopia-liste .huttopia-card{
    width: 100%;
  }
}