#chemin {
  display: flex;
  align-items: center;
  background-color: #f4f4f4;
  overflow: hidden;
  min-height: 4rem;
  padding-left: 2rem;
}

#chemin,
#chemin a {
  color: $color2;
}

#chemin a.bread_link:hover,
#chemin a:hover {
  color: $color3;
}

.breadcrumb_sep{
  margin-left: 1rem;
  margin-right: 1rem;
}

@media (max-width: $breakpoint-xs) {
  #chemin{
    min-height: 3.5rem;
    padding-left: 0.5rem;
    font-size: 1.4rem;
  }
  .breadcrumb_sep {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}