.gallery{
  padding-left: 0;
}

/*! Slick slider */
.slick-prev,
.slick-next{
  width: 3rem;
  height: 3rem;
}

.slick-prev {
  left: 3.5rem;
  z-index: 1
}

.slick-next {
  right: 3.5rem;
  z-index: 1
}

.slick-next::before,
.slick-prev::before {
  font-family: kfont!important;
  color: #fff;
  transition: color .3s;
  font-size: 3rem;
}

.slick-dotted.slick-slider {
  margin-bottom: 35px;
  .slick-dots{
    margin-bottom: -.2rem;
    li button{
      padding: 0;
      &::before {
        content: "";
        font-family: Sans-serif!important;
        background-color: $color4;
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 50%;
      }
    }
  }
}

/* BX SLIDER */
.bx-wrapper,
.bx-wrapper .bx-viewport {
  box-shadow: none;
  border: none!important;
  left: auto;
  background: 0 0
}

.bx-wrapper {
  margin-bottom: 0;
  position: static
}

.bx-wrapper img {
  width: 100%;
  display: block
}

.bx-wrapper li,
.bx-wrapper ul {
  margin: auto;
  padding: 0
}

.bx-wrapper .bx-controls-direction .bx-next,
.bx-wrapper .bx-controls-direction .bx-prev {
  background: 0 0;
  text-indent: inherit;
  z-index: 1;
  color: #fff
}

.bx-wrapper .bx-controls-direction a:focus,
.bx-wrapper .bx-controls-direction a:visited {
  color: #fff
}

.bx-wrapper .bx-controls-direction a:active,
.bx-wrapper .bx-controls-direction a:hover {
  color: #0082b8
}

.bx-wrapper .bx-next:before,
.bx-wrapper .bx-prev:before {
  font-family: kfont!important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  font-size: 2.5rem;
  line-height: 4rem;
  height: 4rem;
  width: 4rem;
  text-align: center;
  display: block;
  margin: 0 auto
}

.bx-wrapper .bx-prev:before {
  content: "\EA01"
}

.bx-wrapper .bx-next:before {
  content: "\EA02"
}
