.page_article #footer{
  margin-bottom: 13.5rem;
}
#fiche{
  background-color: #F6F6F6;
  .custom_cart_container{
    position: relative;
    overflow: hidden;
    .header-configurateur {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 3rem;
      margin-bottom: 6rem;
      img {
        max-width: 15rem;
      }
      p {
        text-align: center;
        color: $color1;
        margin-top: 1.2rem;
      }
    }
    h1{
      padding-top: 2rem;
      margin-top: 6rem;
    }
    .semaine-message-information{
      color: $color2;
    }
  }

  .bloc_selection_active {
    background-color: #dfece2;
    .recette_card {
      margin-top: 3rem;
    }
  }

  .list_tags,
  .slider_container_semaine {
    .slick-slide {
      text-align: center;
      margin-left: .3rem;
      margin-right: .3rem;
      min-height: 0;
      &.hidden {
        display: none!important;
      }
    }
    .slick-arrow::before {
      font-size: 1.6rem;
      color: $color1;
    }
    .slick-prev {
      left: -3rem;
      top: 50%;
    }
    .slick-next {
      right: -3rem;
    }
    .slick-prev,
    .slick-next {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .slider_container_semaine {
    max-width: 120rem;
    margin-left: auto;
    margin-right: auto;
    .custom_green_button {
      width: calc(100% - .5rem);
      padding-left: 1rem;
      padding-right: 1rem;
      margin-bottom: 0;
      margin-top: 0;
      white-space: nowrap;
    }
  }

  .list_tags {
    margin-top: 3rem;
    margin-left: 1rem;
    margin-right: 1rem;
    .slick-disabled {
      display: none!important;
    }
    .slick-track {
      display: flex;
    }
    .tag-recette {
      position: relative;
      height: 5rem;
      border: .1rem solid #DEE2E6;
      border-radius: .8rem;
      color: $color1;
      background-color: #fff;
      padding: 1.2rem 1.4rem;
      cursor: pointer;
      transition: all .3s;
      &:hover {
        border-color: $color1;
      }
      &.active {
        color: $color4;
        border-color: $color4;
        background-color: #ECF5EE;
        .absolute-text {
          font-weight: 700;
        }
      }
      &.disabled {
        pointer-events: none;
        background-color: #e1e1e1;
        color: #8e8c8c;
      }
      .fake-text {
        opacity: 0;
        visibility: hidden;
      }
      .absolute-text {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-wrap: nowrap;
      }
    }
    .slick-slide.disabled .tag-recette {
      pointer-events: none;
      background-color: #e1e1e1;
      color: #8e8c8c;
    }
  }


  .liste_recettes_toggle_c{
    position: relative;
  }
  .options-list-c{
    overflow: hidden;
    .h2,
    .h4{
      color: $color1;
    }
    .h4{
      text-transform: none;
      margin-top: 2rem;
      font-size: 2.4rem;
    }
    .recette-card-list{
      position: relative;
      &::before{
        content: url("../img/theme/article-option.svg");
        position: absolute;
        top: -12rem;
        right: 1rem;
        display: block;
      }
    }
  }
}

.page_article{
  position: relative;
  #chemin{
    margin-bottom: 8rem;
  }
  .slider-single{
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .slider-nav{
    margin-top: 1rem;
    .slick-track {
      margin: 1rem -.4rem 0;
      padding-bottom: 1rem;
    }
    .slick-slide{
      margin: 0 .4rem;
      cursor: pointer;
      img{
        object-fit: contain;
        box-shadow: 0 .3rem .4rem 0 #cfcfcf;
        cursor: pointer;
        height: 7.5rem;
        border: 2px solid transparent;
      }
      &.slick-current img{
        border-color: $color1;
      }
    }
  }
  form.green_block{
    border: none;
    margin-bottom: 0!important;
    color: #fff;
    background-color: $color1;
    &::before{
      content: '';
      display: block;
      width: 120vw;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -30vw;
      background-color: $color1;
    }
    h2{
      position: relative;
      color: #fabc4e;
      margin-bottom: 2rem;
    }
  }
  .temoignages{
    position: relative;
    margin-top: 0;
    &::before{
      content: '';
      display: block;
      width: 120vw;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -30vw;
      background-color: #ecf5ee;
    }
  }
  .achat-produit-c{
    padding: 2rem 1.4rem 1rem;
    color: $color1;
    background-color: #F4F4F4;
    font-size: 2rem;
    font-weight: 700;
    border-radius: 1.4rem;
    .prix{
      display: flex;
      align-items: center;
    }
    .prix-highlight{
      font-size: 5rem;
      color: #FABC4E;
      margin-right: 1.6rem;
    }
    .button.dispo{
      border-radius: 8px;
      margin-left: 1rem;
      height: 6.4rem;
      max-width: calc(100% - 8rem);
    }
    .qt-submit-block{
      display: flex;
      justify-content: space-between;
      margin-top: .2rem;
      select{
        width: 7rem;
        height: 6.4rem;
        text-align: center;
        margin-bottom: .5rem;
        option:disabled{
          background-color: #ddd;
        }
      }
    }
  }
  .achat-produit-container .link-more-gourmand{
    display: inline-block;
    color: $color2;
    line-height: 1.4;
    font-size: 1.6rem;
    font-weight: 400;
    margin-top: 2rem;
    &:hover{
      color: $color1;
    }
  }

  .recettes_semaine{
    position: relative;
    margin-top: 12rem;
    &::before {
      content: '';
      display: block;
      width: 120vw;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -30vw;
      background-color: $color1;
      z-index: -1;
    }
    .h2{
      margin-bottom: 5.5rem;
    }
    .gallery_content::before{
      content: url(../img/theme/slider-recettes-home.webp);
      position: absolute;
      top: 5.8rem;
      left: -10.1rem;
      display: block;
    }
  }
}

.option_aidesup_container .icon-aide,
.icon-robot{
  display: block;
  font-size: 2.5rem;
  text-align: center;
  height: 5rem;
  width: 5rem;
  padding: 4px;
  line-height: 4.8rem;
  color: #fff;
  background-color: $color3;
  border-radius: 50%;
}

.icon-leaf{
  display: block;
  font-size: 3rem;
  text-align: center;
  height: 5rem;
  width: 5rem;
  padding: 7px;
  line-height: 4.8rem;
  background-color: #04B404;
  color: #ffffff;
  border-radius: 50%;
}

/* form options custom */
.custom_green_button{
  cursor: pointer;
  margin-bottom: 0;
  margin-left: .2rem;
  margin-right: .2rem;
  padding: 2px 20px 2px;;
  border: 1px solid $color4;
  border-radius: 2rem;
  color: #fff;
  background-color: $color4;
  transition: all .3s;
  &:hover{
    border-color: $color1;
  }
}
:checked + em + .custom_green_button,
:checked + .custom_green_button {
  color: #fff;
  background-color: $color1;
  border-color: $color1;
}

:disabled + .custom_green_button {
    color: $color10;
    background-color: #fff;
    border-color: $color10;
    cursor: not-allowed;
}

.option_nb_personnes{
  .option_nb_personnes_content{
    position: relative;
    .custom_green_button{
      width: 9.5rem;
      margin-bottom: .8rem;
      text-transform: none;
    }
    .custom_green_button_1{
      margin-left: 0;
    }
  }
}


/* custom_green_button_2 */
.custom_green_button_2{
  cursor: pointer;
  margin-bottom: 0;
  margin-left: .2rem;
  margin-right: .2rem;
  padding: 2px 20px 2px;;
  border: 1px solid $color1;
  border-radius: 0rem;
  color: #fff;
  background-color: $color1;
  transition: all .3s;
  &:hover{
    border-color: $color4;
  }
}
:checked + em + .custom_green_button_2,
:checked + .custom_green_button_2 {
  color: #fff;
  background-color: $color4;
  border-color: $color4;
}

:disabled + .custom_green_button_2 {
  color: $color10;
  background-color: #fff;
  border-color: $color10;
  cursor: not-allowed;
}

.option_nb_personnes{
  .option_nb_personnes_content{
    position: relative;
    .custom_green_button_2{
      width: 9.5rem;
      margin-bottom: .8rem;
      text-transform: none;
    }
    .custom_green_button_1{
      margin-left: 0;
    }
  }
}

/* /form options custom */

.custom_cart_option{
  display: flex;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
  .custom_label_container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 28rem;
    text-align: right;
    .icon{
      margin-left: 1rem;
    }
  }
  > *{
    margin-left: .8rem;
    margin-right: .8rem;
  }
  .collapse-radio .custom_green_button{
    margin-left: 0;
    margin-right: 0;
  }
  .collapse-radio .custom_green_button.radio_collapse{
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .collapse-radio .custom_green_button.radio_collapse ~ .custom_green_button.radio_collapse {
    border-radius: 0 .4rem .4rem 0;
    margin-left: -.1rem;
  }
  .valid-form em.invalid{
    padding-left: 0;
  }
  .option_aidesup_container{
    display: flex;
    align-items: center;
  }
  .small-text{
    font-size: 1.2rem;
    width: 16rem;
    font-style: italic;
    margin-left: 1rem;
  }
  #bloc-robot-collapse{
    text-align: center;
  }
}

.option_nb_personnes .custom_label_container{
  margin-bottom: .8rem;
}

.bloc_formules{
  position: relative;
}

.custom_cart_option em{
  position: absolute;
  bottom: -2.2rem;
  left: 0;
  width: 28rem;
}

.popin_tableau{
  max-width: 120rem;
  table{
    border-color: $color3;
    tbody{
      tr{
        &:nth-child(2n + 1){
          background-color: $color5;
        }
        &:first-child{
          color: #fff;
          background-color: $color3;
        }
        td{
          border-color: #eccb83;
          &:first-child{
            padding: .5rem .8rem;
          }
        }
      }
    }
  }
  .italic{
    font-style: italic;
    font-size: 1.4rem;
  }
}

.semaine_livraison_form{
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  .custom_green_button{
    text-align: center;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    padding: 2px 10px 2px;
    font-size: 1.5rem;
  }
  input:disabled + .custom_green_button,
  input:disabled + .custom_green_button:hover {
    color: $color10;
    background-color: #fff;
    border-color: $color10;
    cursor: not-allowed;
  }

  .custom_green_button.semaine_desactive,
  .custom_green_button.semaine_desactive:hover {
    color: $color10;
    background-color: #fff;
    border-color: $color10;
  }
}

.bandeau_video{
  display: block;
  text-align: center;
  color: #094952;
  background: #f7f7f9;
  padding: 10px 0;
  border-top: 1px solid #c1c1c1;
  border-bottom: 1px solid #c1c1c1;
}

//.bandeau_video{
//  display: flex;
//  align-items: center;
//  width: 100%;
//  background: #f7f7f9;
//  font-weight: 700;
//  padding: 1rem;
//  border-top: .1rem solid #c1c1c1;
//  border-bottom: .1rem solid #c1c1c1;
//  text-align: center;
//  .icon{
//    margin-left: .8rem;
//    font-size: 2rem;
//    line-height: 1.2;
//  }
//}
//
//.recette_card_video{
//  margin-top: .7rem;
//  .icon{
//    line-height: 1;
//    font-size: 2rem;
//  }
//}
//
//.grey_tag{
//  padding: .3rem .5rem;
//  margin-right: 1rem;
//  text-transform: uppercase;
//  color: #fff;
//  background-color: #737c83;
//  border-radius: .5rem;
//  font-size: 1.3rem;
//  font-weight: 700;
//}

.recettes_list{
  margin-top: 2rem;
  .remise{
    display: none;
  }
  .configurateur-resume-c{
    .text-mobile{
      display: none;
    }
    .text-desktop{
      display: block;
    }
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 13.5rem;
    background: $color1;
    padding-top: 1rem;
    padding-bottom: 1rem;
    z-index: 2;
    border-top: .1rem solid #f6f6f6;
    .configurateur-resume{
      display: flex;
      justify-content: space-between;
      .nb-personnes-c{
        margin-right: .5rem;
      }
      .configurateur-resume-prix-c{
        @include flex_center;
        flex-direction: column;
        opacity: 0;
        visibility: hidden;
        width: 19rem;
        margin-right: .8rem;
        margin-bottom: .4rem;
        background-color: #57787d;
        padding: .5rem;
        border-radius: 0.7rem;
        text-align: center;
        .icon{
          display: block;
          font-size: 3rem;
          color: #ff718a;
          margin-left: auto;
          margin-right: auto;
          animation-name: slidein;
          animation-duration: 2s;
          animation-iteration-count: infinite;
          animation-timing-function: ease-in;
        }
      }
    }
    .title-resume{
      display: flex;
      align-items: center;
      font-size: 1.6rem;
      color: $color2;
      line-height: 1;
    }
    .stitle-resume{
      color: $color4;
      font-size: 1.8rem;
      &.stitle-active{
        color: $color2;
      }
    }
    .texte{
      color: #fff;
      line-height: 1.3;
      margin-top: .5rem;
    }
    .liste-recette-resume {
      display: flex;
      margin-left: -.4rem;
      margin-right: 2rem;
    }
    .nb-personnes{
      display: flex;
      margin-left: -.4rem;
      margin-right: -.4rem;
      > div{
        margin-left: .4rem;
        margin-right: .4rem;
      }
    }
    .card-nb-personnes{
      width: 4.3rem;
      height: 4.3rem;
      @include flex_center;
      border-radius: 50%;
      color: #262525;
      background-color: #57787D;
      cursor: pointer;
      font-size: 3rem;
      margin-top: .3rem;
    }
    input:checked + .card-nb-personnes{
      color: $color1;
      background-color: $color2;
    }
    .resume-nb-recettes.highlight{
      font-size: 2.4rem;
    }
    .resume-form{
      .recettes_header{
        margin-top: 1rem;
        font-size: 1.4rem;
        .highlight{
          color: #80B2B8;
        }
      }
    }
    .lowlight{
      display: none;
      font-size: 1.4rem;
    }
  }
  .liste-tags {
    gap: 0;
  }
}

.info-bulle-resume{
  display: flex;
  flex-direction: column;
  text-align: center;
  height: auto;
  position: absolute;
  left: 50%;
  bottom: calc(100% + 1.6rem);
  margin-left: -15rem;
  opacity: 1;
  padding: 2rem;
  transition: opacity 0.6s ease-in-out 0s;
  width: 30rem;
  background: rgba(255, 255, 255, 0.98) none repeat scroll 0% 0%;
  border-radius: 2rem;
  box-shadow: rgba(0, 0, 0, 0.4) 0 .5rem 3rem .1rem;
  .arrow-info-bulle{
    border-style: solid;
    border-width: 1rem;
    border-color: rgb(255, 255, 255) transparent transparent;
    border-image: none 100% / 1 / 0 stretch;
    left: 50%;
    margin-left: -1rem;
    position: absolute;
    top: 100%;
  }
}

.card-recette-resume{
  position: relative;
  width: 7.6rem;
  height: 7.6rem;
  border-radius: 50%;
  border: .3rem solid #33735C;
  margin: .5rem .4rem 0;
  background: transparent;
  transition: opacity .3s;
  cursor: pointer;
  &.card-recette-active:not(.derniere-recette){
    border-color: $color2;
    img{
      opacity: 1;
      animation: none;
    }
  }
  .circle-box{
    position: absolute;
    top: -.3rem;
    right: 0;
    bottom: 0;
    left: -.4rem;
    width: 7.8rem;
    height: 7.8rem;
  }
  .circle-wrapper{
    width: 3.9rem;
    height: 7.6rem;
    position: absolute;
    top: 0;
    overflow: hidden;
    &.circle-wrapper-right{
      right: 0;
    }
    &.circle-wrapper-left {
      left: .1rem;
    }
  }
  .circle-whole {
    width: 7.6rem;
    height: 7.6rem;
    border: .3rem solid transparent;
    border-radius: 50%;
    position: absolute;
    top: 0;
    transform: rotate(-135deg);
    &.circle-right {
      border-top: .3rem solid $color2;
      border-right: .3rem solid $color2;
      right: 1px;
      animation: circleRight .5s linear forwards;
    }
    &.circle-left {
      border-bottom: .3rem solid $color2;
      border-left: .3rem solid $color2;
      left: 1px;
      animation: circleLeft .5s linear forwards;
    }
  }
  .quantite{
    position: absolute;
    color: #fff;
    font-size: 3rem;
    line-height: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .icon-cross{
    display: none;
    position: absolute;
    color: #fff;
    font-size: 3rem;
    line-height: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  img{
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    object-fit: cover;
    opacity: 0;
    animation: apparition .8s linear forwards;
    transition: filter .3s;
  }
  &:hover{
    img{
      filter: brightness(.6);
    }
    .icon-cross{
      display: block;
    }
    .quantite{
      display: none;
    }
  }
  &.card-resume-options{
    img{
      filter: brightness(.6);
    }
  }
}

.recettes_list .configurateur-resume-c .liste-recette-resume .card-recette-resume.animate-add-recette{
  border-color: $color2;
}

@keyframes apparition {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes circleRight {
  0% {
    transform: rotate(-135deg);
  }
  50%,
  100% {
    transform: rotate(45deg);
  }
}

@keyframes circleLeft {
  0%,
  50% {
    transform: rotate(-135deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
  }
}

@keyframes slidein {
  0% {opacity: 0;}
  50% {opacity: 1;}
  100% {opacity: 0;}
}

.add_title{
  position: relative;
  display: block;
  padding: 2rem 1rem;
  width: 100%;
  .h3{
    margin: 0;
    padding-right: 4.5rem;
  }
  .h3,
  .icon{
    color: #721c24;
  }
  .icon{
    position: absolute;
    right: 2rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2.5rem;
  }
}

.push_article{
  .h4{
    text-align: center;
    margin-top: 3rem;
  }
}

#recap_cabas_popin_mobile{
  margin-bottom: 3.2rem;
  .recap_cabas_container{
    @include box-shadow;
    .option_aidesup_container{
      display: flex;
      align-items: center;
      margin-top: 1.5rem;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      line-height: 1.2;
      .icon{
        margin-right: 1.2rem;
        min-width: 4.2rem;
      }
    }
  }
}

.recap_cabas_container{
  .button{
    width: 100%;
    font-size: 2.2rem;
  }
  .button-validation{
    display: block;
    width: calc(100% - 3rem);
    margin-left: auto;
    margin-right: auto;
  }
  .push_recap{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    .icon{
      font-size: 4rem;
      line-height: 1;
      margin-right: auto;
    }
    .push_text{
      margin-right: auto;
      line-height: 1.1;
      .text-sm{
        font-size: 1.5rem;
      }
    }
    &:hover{
      color: #fff;
      background-color: $color3;
      border-color: $color3;
    }
  }
  .total-recap-container{
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 100%;
    .total-recap{
      display: flex;
      align-items: baseline;
      justify-content: space-around;
      .highlight{
        text-transform: uppercase;
        font-size: 2.2rem;
        font-weight: 700;
        p{
          display: inline;
          margin: 0;
        }
      }
    }
    .total-recap-push{
      text-align: center;
      font-style: italic;
    }
  }
  .recap-content{
    padding: 1rem 2rem 2rem;
    font-size: 1.3rem;
    width: 100%;
    > .h5{
      text-align: left;
    }
    .recap-header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      .italic-text{
        font-style: italic;
        font-weight: 700;
        font-size: 1.2rem;
      }
      .h5{
        margin-top: 0;
        p{
          display: inline;
          margin: 0;
        }
      }
    }
    .resume_button_container{
      display: flex;
      justify-content: space-between;
      margin-top: .5rem;
      .resume_button_titre{
        max-width: calc(100% - 3rem);
        &.resume_recette_cadeau{
          color: $color2;
          font-size: 1.5rem;
          font-weight: 700;
          .icon{
            font-size: 2rem;
            margin-right: .5rem;
          }
        }
        .highlight{
          font-weight: 700;
        }
      }
    }
    > h5{
      margin-top: 1rem;
    }
    .underline_link_container{
      margin-top: 3rem;
      font-size: 1.5rem;
    }
  }
}

.infos-recap-content{
  text-align: center;
  font-weight: 700;
  margin-top: 2.2rem;
  > div{
    margin-top: 1.2rem;
    line-height: 1.2;
    .icon-bestof{
      color: $color2;
    }
	.icon-leaf {
		background: #04b404;
		font-size: 1.5rem;
		color: #fff;
		width: 3rem;
		height: 3rem;
		padding: 3px;
		line-height: 3rem;
	}
  }
}

.recap_cabas_popin_link{
  display: none;
}

.fidelite_nohome{
  margin: 4rem 3rem;
  .row > div{
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    text-align: center;
    h3{
      margin-bottom: 1.2rem;
    }
    .avis_verif{
      max-height: 10rem;
    }
  }
}

.sticky_recap{
  display: none;
}

#popin-ajout-panier {
  h2{
    margin-bottom: 2rem;
  }
  ul{
    list-style: none;
    padding-left: 0;
    a{
      margin-top: 1rem;
      margin-bottom: 1rem;
      width: 23rem;
    }
  }
}

/* Section SEO toubio */

.seotoubio{
  margin-top: 5rem;
  margin-bottom: 5rem;
  .h2{
    margin-bottom: 4rem;
  }
}

.card_seotoubio_c{
  display: flex;
  justify-content: center;
  .card_seotoubio{
    position: relative;
    .h3{
      line-height: 1.2;
      margin-top: 2rem;
      margin-bottom: 1rem;
      height: 6.5rem;
      overflow: hidden;
    }
    .card_seotoubio_desc_c{
      position: relative;
      .card_seotoubio_desc{ 
        height: 0;
        overflow: hidden; 
        .card_seotoubio_desc_content{
          padding: 0 1.5rem 1rem;
		  p {
			text-align: left;
		  }
		  h2 {
			text-align: left;
			margin-bottom: 2rem;
		  }
        }
      }
    }
  }
}

.expand_text_seotoubio{
  margin-bottom: 1rem;
  .icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    line-height: 1;
    border-radius: 50%;
    color: $color1;
    background-color: #f7f7f9;
    cursor: pointer;
    font-size: 1.8rem;
    transition: all .3s;
    margin-left: auto;
    margin-right: auto;
    &:hover{
      color: #fff;
      background-color: $color2;
    }
  }
  &.open .icon{
    transform: rotate(180deg);
    color: #fff;
    background-color: $color2;
  }
}

.wrapper-tooltip {
	color: #555; 
	font-family: Raleway,sans-serif;
	margin: 0 auto;
	padding: 0;
	position: relative;
	text-align: center;
	width: 100%;
	cursor: pointer;
	line-height: 30px;
	font-size: 1.25em;
	font-weight: bold;
	-webkit-transform: translateZ(0); /* webkit flicker fix */
	-webkit-font-smoothing: antialiased; /* webkit text rendering fix */
	
	#js_prix_pers_repas {
		color: $color2;
	}
	
	.apartirde {
		display: none;
	}
	.tooltip {
		font-family: Raleway,sans-serif;
		font-size: 1em;
		text-align: center;
		background: #094952;
		bottom: 100%;
		color: #fff;
		display: block;
		left: 0;
		width: 100%;
		margin-bottom: 10px;
		opacity: 0;
		padding: 20px;
		pointer-events: none;
		position: absolute;
		z-index: 999;
		-webkit-transform: translateY(10px);
		  -moz-transform: translateY(10px);
		  -ms-transform: translateY(10px);
		   -o-transform: translateY(10px);
			transform: translateY(10px);
		-webkit-transition: all .25s ease-out;
		  -moz-transition: all .25s ease-out;
		   -o-transition: all .25s ease-out;
			transition: all .25s ease-out;
		-webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
		  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
		   -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
			box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
			
			&::before { 
				bottom: -20px;
				content: " ";
				display: block;
				height: 20px;
				left: 0;
				position: absolute;
				width: 100%; 
			}
			
			&::after {
			border-left: solid transparent 10px;
				border-right: solid transparent 10px;
				border-top: solid #094952 10px;
				bottom: -10px;
				content: " ";
				height: 0;
				left: 50%;
				margin-left: -13px;
				position: absolute;
				width: 0;
			}
			
		&.show {
			opacity: 1;
			pointer-events: auto;
			-webkit-transform: translateY(0px);
			  -moz-transform: translateY(0px);
			  -ms-transform: translateY(0px);
			   -o-transform: translateY(0px);
				transform: translateY(0px);
			}
			
		button {
			display: block;
			margin: 10px auto;
			background: #fff;
			border: none;
			padding: 10px;
			border-radius: 30px;
			color: #094952;
			cursor: pointer;
			font-weight: bold;
			
			&:hover {
				background: #ececec;
				color: #094952;
			}
		}

	}
	
}

#js-prix-repas {
    -webkit-animation-name: animation;
    -webkit-animation-duration: 1s;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;    
    -webkit-animation-play-state: running;
    
    animation-name: animation;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;    
    animation-play-state: running;
    
	color: #094952;
	font-weight: bold;
}

/* Toast mobile : nombre de produits mini */
.toast-mobile-nb-recette{
  visibility: hidden;
  opacity: 0;
  .alert-container{
    top: auto;
    bottom: 8rem;
    cursor: pointer;
    .container{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon-close{
        line-height: 0;
        font-size: 2.5rem;
        transition: color .3s;
      }
    }
    &:hover .icon-close{
      color: $color3;
    }
  }
}

@-webkit-keyframes animation {
    0%     { color: #094952; }
    50%  { color: #781629; }
    100%  { color: #094952; }
}

@keyframes animation {
    0%     { color: #094952; }
    50%  { color: #781629; }
    100%  { color: #094952; }
}

/* Tutoriel IntroJS */
.introjs-helperNumberLayer{
  background: $color2;
}
.introjs-button:hover,
.introjs-button:active,
.introjs-button:focus{
  color: initial;
}

/* Popin spé semaine */
.popin_semaine{
  padding: 0;
}

@media (max-width: $breakpoint-xl) and (min-width: $breakpoint-md) {
  .info-produit .downwave-title::before{
    bottom: -5.4rem;
  }
}

@media (max-width: $breakpoint-xl) {
  #fiche .custom_cart_option{
    flex-wrap: wrap;
  }
  .recettes_list .configurateur-resume-c{
    .configurateur-resume .configurateur-resume-prix-c{
      width: 14rem;
    }
    button[type="submit"]{
      font-size: 2rem;
      padding: .8rem 1.2rem;
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .content_card .button.button-xl,
  .recap_cabas_container .button.button-xl{
    font-size: 2rem;
    padding: .8rem 2.2rem;
  }
  .recap-header{
    flex-wrap: wrap;
    & > div{
      margin-left: auto;
    }
  }
  .add_title {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .recettes_list{
    margin-top: 0;
    .configurateur-resume-c{
      height: 19.2rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      .configurateur-resume{
        flex-wrap: wrap;
        .liste-recette-resume-c{
          width: calc(100% - 30rem);
          margin-bottom: 0.5rem;
          order: 1;
          .card-recette-resume{
            width: 5.5rem;
            height: 5.5rem;
            border-width: 0.2rem;
            img{
              width: 5.1rem;
              height: 5.1rem;
            }
            .circle-box{
              top: -0.2rem;
              left: -0.3rem;
              width: 5.9rem;
              height: 5.9rem;
            }
            .circle-wrapper{
              width: 2.9rem;
              height: 5.6rem;
              &.circle-wrapper-right {
                right: .1rem;
              }
            }
            .circle-whole{
              width: 5.6rem;
              height: 5.6rem;
              border-width: .2rem;
              &.circle-left {
                border-bottom-width: .2rem;
                border-left-width: .2rem;
              }
              &.circle-right {
                border-top-width: .2rem;
                border-right-width: .2rem;
              }
            }
          }
        }
        .configurateur-resume-prix-c{
          order: 2;
          width: 19rem;
        }
        .nb-personnes-c{
          order: 3;
        }
        .resume-form{
          display: flex;
          align-items: center;
          order: 4;
          max-width: calc(100% - 22rem);
          form{
            order: 2;
            margin-left: 2rem;
          }
          .recettes_header {
            margin-top: 0;
          }
        }
      }
    }
  }
  .qt-submit-block{
    flex-wrap: wrap;
  }
  .page_article .achat-produit-c .button.dispo{
    width: 100%;
    max-width: 40rem;
    margin-left: 0;
  }
  .page_article #footer{
    margin-bottom: 19.2rem;
  }
  #fiche{
    .semaines-list {
      flex-wrap: wrap;
    }
    .custom_cart_container h1{
      margin-top: 1rem;
      margin-bottom: 4rem;
    }
  }
  .add_title{
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media (max-width: $breakpoint-md) {
  #fiche{
    margin-top: 0;
    padding-top: 0;
    .options-list-c{
      .h4{
        font-size: 1.8rem;
      }
      .recette-card-list::before{
        top: -13rem;
        right: -5rem;
        transform: scale(0.6);
      }
    }
  }
  .custom_cart_option{
    flex-wrap: wrap;
    > * {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
    .option_aidesup_container{
      margin-left: auto;
      margin-right: auto;
      margin-top: 1rem;
    }
  }
  .content_card .button.button-xl,
  .recap_cabas_container .button.button-xl {
    font-size: 1.6rem;
    padding: 0.5rem 0.5rem;
  }
  .recap_cabas_container{
    .push_recap .icon {
      font-size: 3rem;
    }
    .total-recap-container .total-recap {
      flex-wrap: wrap;
      padding-left: .5rem;
      padding-right: .5rem;
    }
    .recap-content{
      padding-left: 1.2rem;
      padding-right: 1.2rem;
      .recap_selection > div{
        margin-left: -.5rem;
        margin-right: -.5rem;
      }
    }
  }
  .green_block.choix_semaine_container{
    padding-bottom: 3rem;
    .semaine_livraison_form{
      flex-wrap: wrap;
      .custom_green_button{
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
      }
    }
  }
  #recap_cabas_popin_mobile{
    margin-bottom: 2rem;
    .recap_cabas_container .option_aidesup_container{
      font-size: 1.3rem;
      margin-left: .5rem;
      margin-right: .5rem;
    }
    .resume_button_titre{
      max-width: calc(100% - 2rem);
    }
    .button_cross{
      height: 2rem;
      line-height: 1.8rem;
      width: 2rem;
      font-size: 0.8rem;
    }
  }
  .recettes_list .configurateur-resume-c button[type="submit"]{
    font-size: 1.5rem;
    padding: .8rem 0.6rem;
  }
  .page_article .achat-produit-c .button.dispo{
    margin-left: auto;
    margin-right: auto;
  }
  .achat-produit-container{
    order: -1;
  }
  .info-produit-c{
    order: -2;
  }
  .bloc-slider-nav{
    max-width: 50rem;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: $breakpoint-sm) {

  .green_block.big_block{
    padding: 1rem 2rem;
  }
  #fiche .green_block.custom_cart_container{
    margin-bottom: 2rem;
  }
  .custom_cart_option {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
    .custom_label_container {
      width: 100%;
      justify-content: start;
      text-align: left;
      margin-bottom: .5rem;
    }
    .option_aidesup_container {
      margin-left: 0;
    }
    .bloc_formules.collapse-radio{
      display: flex;
      justify-content: space-between;
      width: 100%;
      text-align: center;
      label{
        width: calc(50% - 1rem);
        text-align: center;
        max-width: 28rem;
        text-transform: initial;
        font-size: 1.4rem;
        line-height: 1.4;
        padding: .5rem .5rem .5rem;
      }
      .custom_green_button.radio_collapse,
      .custom_green_button.radio_collapse ~ .custom_green_button.radio_collapse{
        border-radius: 4px;
      }
    }
  }
  .choix_semaine_container.green_block{
    background-color: #fff;
    .semaine_livraison_form{
      display: block;
    }
  }
  #fiche {
    .tuto_button{
      display: none;
    }
    .remise{
      display: block;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
    .custom_cart_container .header-configurateur img {
      display: none;
    }
  }
  .green_block.choix_semaine_container .semaine_livraison_form{
    .custom_green_button{
      display: block;
      width: 100%;
      border: none;
      color: #333;
      background-color: $color5;
      margin: 0 auto;
      padding-top: 1rem;
      padding-bottom: 1rem;
      text-align: center;
      cursor: default;
    }
    .slick-prev,
    .slick-next{
      width: 4rem;
      height: 4rem;
      background-color: $color4;
    }
    .slick-prev{
      left: 0;
    }
    .slick-next{
      right: 0;
    }
    .slick-next::before,
    .slick-prev::before{
      font-size: 2rem;
    }
  }

  #article section.temoignages{
    display: block;
  }

  #recap_cabas_popin_mobile{
    display: none;
  }

  .page_article .fancybox-close-small {
    padding: 0;
    width: 5rem;
    height: 5rem;
    &:hover{
      color: $color2;
    }
  }

  .recap_cabas_container {
    margin-left: 2rem;
    margin-right: 2rem;
    padding: 0;
    form{
      display: flex;
      flex-wrap: wrap;
    }
    .total-recap-container{
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      .total-recap{
        justify-content: start;
        > .highlight{
          margin-right: 1.5rem;
        }
      }
      .total-recap-push{
        text-align: left;
      }
      &::after{
        content: "";
        display: block;
        height: .1rem;
        width: 100%;
        margin-top: 1rem;
        background-color: $color8;
      }
    }
    .recap-content{
      .recap-header {
        align-items: end;
      }
      .recap_selection{
        margin-top: 1rem;
        > div{
          margin-top: 0;
          padding: 1rem 2rem .5rem;
          border-bottom: 1px solid $color6;
          background-color: $color5;
          &:first-child{
            border-top: 1px solid $color6;
          }
        }
        &.recap_selection_options > div:nth-child(2){
          border-top: 1px solid $color6;
        }
        .resume_button_titre{
          width: calc(100% - 2rem);
        }
      }
    }
    .button.push_recap{
      order: 3;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
    .button.button-validation{
      order: 2;
      margin-bottom: 1.5rem;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      max-width: 35rem;
    }
  }
  .fidelite,
  .rassurances{
    display: block;
  }
  #article .push_article,
  .fidelite_nohome{
    display: none;
  }
  .toast-mobile-nb-recette.visible {
    opacity: 1;
    visibility: visible;
  }
  .recettes_list .configurateur-resume-c{
    height: 21.2rem;
    .lowlight{
      font-size: 1.1rem;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  #fiche .semaines-list{
    justify-content: center;
  }
  .option_nb_personnes .option_nb_personnes_content .custom_green_button{
    margin-left: 0;
  }
  .recettes_list .configurateur-resume-c{
    .liste-recette-resume {
      margin-right: 1rem;
    }
    .resume-nb-recettes.highlight {
      font-size: 1.7rem;
    }
    .configurateur-resume{
      .liste-recette-resume-c{
        width: calc(100% - 18rem);
        .card-recette-resume{
          width: 4.5rem;
          height: 4.5rem;
          img{
            width: 4.1rem;
            height: 4.1rem;
          }
          .circle-box{
            top: -0.2rem;
            left: -0.3rem;
            width: 4.9rem;
            height: 4.9rem;
          }
          .circle-wrapper{
            width: 2.4rem;
            height: 4.6rem;
            &.circle-wrapper-right {
              right: .1rem;
            }
          }
          .circle-whole{
            width: 4.6rem;
            height: 4.6rem;
          }
        }
      }
      .resume-form{
        display: block;
        margin-top: .5rem;
        form{
          text-align: center;
          margin-left: 0;
        }
        button[type="submit"]{
          margin-bottom: .2rem;
        }
      }
      .configurateur-resume-prix-c{
        width: 15rem;
        font-size: 1.4rem;
      }
    }
  }
}

@media (max-width: $breakpoint-xs) {
  .page_article #footer{
    margin-bottom: 16.2rem;
  }
  #fiche .options-list-c .recette-card-list::before {
    display: none;
  }
  #fiche .semaines-list .custom_green_button{
    width: 28rem;
  }
  #fiche .slider_container_semaine {
    max-width: calc(100% - 5rem);
  }
  .custom_cart_container .custom_green_button{
    width: 100%;
    text-align: center;
    max-width: 28rem;
    margin: .5rem auto;
  }
  .option_nb_personnes .option_nb_personnes_content{
    text-align: center;
    .custom_green_button{
      margin-right: .4rem;
      margin-left: .4rem;
      height: 4rem;
      font-size: 2.5rem;
      line-height: 1.2;
    }
  }
  .custom_cart_option{
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    .relative,
    input{
      margin-left: auto;
      margin-right: auto;
    }
    .option_aidesup_container{
      width: 100%;
      justify-content: center;
    }
  }

  .custom_label_container{
    .text-label{
      font-weight: 700;
    }
  }

  .popin_tableau table tbody tr td{
    font-size: 1.2rem;
  }

  .option_nb_personnes .option_nb_personnes_content{
    margin-left: auto;
    margin-right: auto;
  }

  .choix_semaine_container .br_mobile{
    font-size: 1.6rem;
  }

  #semaine_date_fin{
    display: block;
  }

  .recettes_list{
    margin-top: 1rem;
  }

  .sticky_recap{
    flex-wrap: wrap;
    padding: 0.3rem 1rem;
    justify-content: center;
    .sticky_recap_content{
      width: 100%;
      justify-content: center;
      line-height: 1.1;
      margin-top: 4px;
      margin-bottom: 4px;
      .sep-vertical{
        margin-left: 1.5rem;
        margin-right: 1.5rem;
      }
    }
  }

  .toast-mobile-nb-recette .alert-container{
    bottom: 11rem;
  }

  .recap_cabas_container{
    margin-left: 0;
    margin-right: 0;
  }

  .list_tags {
    display: none;
  }

  .recettes_list .configurateur-resume-c{
    //height: 16.2rem;
    height: 13.5rem;
    z-index: 6;
    .text-mobile{
      display: block;
    }
    .text-desktop{
      display: none;
    }
    .lowlight{
      display: none;
    }
    .texte{
      margin-top: 3.5rem;
      line-height: 1.2;
      font-size: 1.3rem;
    }
    .card-nb-personnes{
      width: 4rem;
      height: 4rem;
    }
    .configurateur-resume{
      padding-left: .8rem;
      padding-right: .8rem;
      .liste-recette-resume-c{
        display: flex;
        width: 100%;
        margin-bottom: 0;
        font-size: 1.4rem;
        .mobile-only,
        .stitle-resume{
          font-size: 1.4rem;
        }
        .text{
          font-size: 1.3rem;
        }
        .liste-recette-resume{
          display: none;
        }
      }
      .configurateur-resume-prix-c{
        width: 13rem;
        height: 6rem;
        order: 3;
        margin-top: -2rem;
        margin-right: 0;
        .icon{
          font-size: 2.4rem;
          line-height: 1;
        }
        .resume-nb-recettes.highlight{
          text-align: center;
          line-height: 1;
          font-size: 1.6rem;
          + .texte{
            margin-top: .4rem;
            line-height: 1.2;
          }
        }
        .resume-nb-recettes{
          margin-top: 0;
          font-size: 1.1rem;
        }
      }
      .resume-form{
        display: flex;
        width: 100%;
        max-width: none;
        margin-top: 0.4rem;
        .recettes_header{
          margin-top: -4rem;
          pointer-events: none;
        }
        form{
          margin-left: auto;
          margin-top: -2rem;
          button[type="submit"]{
            padding: 1.1rem 3.6rem;
          }
        }
      }
    }
  }
}

.page_article.produit_id_11 .custom_cart {
  display:none;
}
