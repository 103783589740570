/* Section bandeau remise */
.remise{
  @include flex_center;
  background-color: #E6A61C;
  padding: 1.3rem 1rem 1.8rem;
  & > *{
    color: #fff;
  }
  .h3{
    font-size: 2.4rem;
    font-weight: 300;
    color: #fff;
    margin-top: 0;
    margin-bottom: 0;
    .highlight{
      font-size: 3.2rem;
    }
  }
  p {
    margin-bottom: 0;
    display: inline;
  }
}

@media (max-width: $breakpoint-md) {
  .remise{
    .h3{
      font-size: 2rem;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .remise{
    padding-top: .8rem;
    padding-bottom: 1rem;
    .h3{
      font-size: 1.7rem;
    }
  }
}

@media (max-width: $breakpoint-xs) {
  .remise{
    .h3{
      font-size: 1.6rem;
      strong:first-child{
        display: block;
      }
    }
  }
}
