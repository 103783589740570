
/* Button */
#body_cms .content .button,
.button {
  display: inline-block;
  position: relative;
  background-color: $color4;
  border: 1px solid $color4;
  color: #fff;
  font-weight: 300;
  font-size: 2.3rem;
  padding: .8rem 4.6rem;
  line-height: 1;
  margin: .5rem .5rem .5rem 0.5rem;
  text-transform: none;
  cursor: pointer;
  text-align: center;
  transition: all .3s ease-in-out;
  border-radius: 3rem;

  > p,
  .txt > p {
    margin: 0;
    padding: 0;
  }

  &:hover,
  &:focus {
    color: $color3;
    background-color: #fff;
    border:1px solid $color3;
    text-decoration: none;
    opacity: 1;
    outline: 0;
  }

  &.button-icon{
    padding: .2rem 1.2rem;
    .icon{
      margin-right: 1.5rem;
      font-size: 2rem;
      &::before{
        line-height: 1.4;
      }
    }
  }
  &.small{
    font-size: 1.5rem;
    padding: .8rem 2rem;
  }
}

.button.button-outline{
  color: $color3;
  background-color: #fff;
  border-color: $color2;
  &:hover{
    color: #fff;
    background-color: $color3;
    border-color: $color2;
  }
}

.button.button-color2{
  background-color: $color2;
  border-color: $color2;
  &:hover,
  &:focus {
    color: $color2;
    background-color: #fff;
    border-color: $color2;
  }
}

.button.button-outline-color4{
  color: $color4;
  border-color: $color4;
  background-color: #fff;
  border-radius: 4.8rem;
  &:hover{
    color: #fff;
    background-color: $color4;
  }
}


.button.button-color4{
  background-color: $color4;
  border-color: $color4;
  &:hover,
  &:focus {
    color: $color4;
    background-color: #fff;
    border-color: $color4;
  }
}

.button.button-color12{
  background-color: $color12;
  border-color: $color12;
  &:hover,
  &:focus {
    color: $color12;
    background-color: #fff;
    border-color: $color12;
  }
}

.button.button-color5 {
  background-color: $color2;
  border-color: $color2;
  &:hover,
  &:focus {
    color: #fff;
    background-color: $color4;
    border-color: $color4;
  }
}

.button.button-rond{
  display: inline-block;
  font-family: 'Raleway', sans-serif;
  color: #fff;
  background-color: $color1;
  border-radius: 2rem;
  font-size: 1.8rem;
  text-transform: none;
  padding: .6rem 1.5rem;
  border: none;
}
a, .lien-off {
  &.button.button-whatsapp {
    display: inline-flex;
    align-items: center;
    background-color: $color14;
    color: #fff;
    border: 1px solid $color14;
    border-radius: 2rem;
    padding: 1rem 2rem;
    margin: .5rem 0;
    font-size: 1.8rem;
    > .icon {
      display: inline-flex;
      font-size: 3rem;
      margin-right: 1rem;
    }
    &:hover,
    &:focus {
      background-color: #fff;
      color: $color14;
      border:1px solid $color14;
    }
  }
}
.footer_content {
  a, .lien-off {
    &.button.button-whatsapp {
      &:hover,
      &:focus {
        border:1px solid #fff;
      }
    }
  }
}


.button.button-rond{
  display: inline-block;
  font-family: 'Raleway', sans-serif;
  color: #fff;
  background-color: $color1;
  border-radius: 2rem;
  font-size: 1.8rem;
  text-transform: none;
  padding: .6rem 1.5rem;
  border: none;
}

.button.button-vert { background-color:#6cc332; color:#fff; border:1px solid #6cc332;}
.button.button-rouge { background-color:#dd3b59; color:#fff; border:1px solid #dd3b59; }
.button.button-orange { background-color:#ED8200; color:#fff; border:1px solid #ED8200; }
.button.button-violet { background-color:#92278f; color:#fff; border:1px solid #92278f; }
.button.button-blanc { background-color:transparent; color:#6e9619; border:1px solid #6e9619; }
.button.button-bleu { background-color:#388ed7; color:#fff; border:1px solid #388ed7; }
.button.button-gris { background-color:#ABA9A9; color:#fff; border:1px solid #ABA9A9; }


.button.button-vert:hover,
.button.button-vert:focus { background-color:rgba(255,255,255,0.8); color:#6cc332; border:1px solid #6cc332; }

.button.button-rouge:hover,
.button.button-rouge:focus { background-color:rgba(255,255,255,0.8); color:#dd3b59; border:1px solid #dd3b59; }

.button.button-orange:hover,
.button.button-orange:focus { background-color:#fff; color:#ED8200; border:1px solid #ED8200; }

.button.button-violet:hover,
.button.button-violet:focus { background-color:#fff; color:#92278f; border:1px solid #92278f; }

.button.button-blanc:hover,
.button.button-blanc:focus { background-color:#3d3d3d; color:#fff; border:1px solid #3d3d3d; }

.button.button-bleu:hover,
.button.button-bleu:focus { background-color:rgba(255,255,255,0.8); color:#388ed7; border:1px solid #388ed7; }

.button.button-gris:hover,
.button.button-gris:focus { background-color:#fff; color:#ABA9A9; border:1px solid #ABA9A9; }

.button.button-small {
  font-size: 1rem;
  padding: .5rem 1rem;
}
.button.button-xl{
  padding: 1.4rem 3rem;
}
.button.button-xl.button-icon {
  font-size: 1.6rem;
  padding: .2rem 2rem;
  .icon{
    font-size: 4.5rem;
    line-height: 1;
  }
}

button:disabled,
button.disabled,
button:disabled:hover{
  background-color: $color8!important;
  border-color: $color8!important;
  color: #fff!important;
  cursor: not-allowed;
  transition-duration: 1000ms;
  transition-property: background-color;
}

.button_cross {
    display: block;
    height: 2.6rem;
    line-height: 2.6rem;
    width: 2.6rem;
    font-size: 1.3rem;
    text-align: center;
    padding: 0;
    color: #fff;
    background-color: $color2;
    border: 1px solid $color2;
    border-radius: 50%;
    cursor: pointer;
    transition: color .3s, background-color .3s;
    &:hover{
      color:$color2;
      background-color: #fff;
    }
}


.underline_link_container{
  text-align: center;
  padding-bottom: .8rem;
  > a,
  > button{
    display: inline-block;
    line-height: 1;
    border: none;
    background-color: transparent;
    &::after{
      content: "";
      display: inline-block;
      height: 1px;
      width: 100%;
      background-color: #333;
      transition: all .3s ease-in;
    }
    &:hover::after{
      background-color: $color3;
    }
  }
  &.color2_link{
    a,
    button{
      color: $color2;
      transition: all .3s ease-in;
      cursor: pointer;
      &:hover{
        color: $color3;
      }
      &::after{
        background-color: $color2;
      }
      &:hover::after{
        background-color: $color3;
      }
    }
  }
}

.loader-css-c{
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .5);
  z-index: 1001;
  .loader-css-content{
    @include flex_center;
    width: 100%;
    height: 100%;
  }
}

.loader {
  position: relative;
  width: 120px;
  height: 14px;
  border-radius: 0 0 15px 15px;
  background-color: #3e494d;
  box-shadow: 0 -1px 4px #5d6063 inset;
  animation: panex 0.5s linear alternate infinite;
  transform-origin: 170px 0;
  z-index: 10;
  perspective: 300px;
  &::before{
    content: '';
    position: absolute;
    left: calc( 100% - 2px);
    top: 0;
    z-index: -2;
    height: 10px;
    width: 70px;
    border-radius: 0 4px 4px 0;
    background-repeat: no-repeat;
    background-image: linear-gradient(#6c4924, #4b2d21), linear-gradient(#4d5457 24px, transparent 0), linear-gradient(#9f9e9e 24px, transparent 0);
    background-size: 50px 10px , 4px 8px , 24px 4px;
    background-position: right center , 17px center , 0px center;
  }
  &::after{
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    z-index: -2;
    transform: translate(-50% , -20px) rotate3d(75, -2, 3, 78deg);
    width: 55px;
    height: 53px;
    background: #fff;
    background-image:
            radial-gradient(circle 3px , #fff6 90%, transparent 10%),
            radial-gradient(circle 12px , #ffc400 90%, transparent 10%),
            radial-gradient(circle 12px , #ffae00 100%, transparent 0);
    background-repeat: no-repeat;
    background-position: -4px -6px , -2px -2px , -1px -1px;
    box-shadow: -2px -3px #0002 inset, 0 0 4px #0003 inset;
    border-radius: 47% 36% 50% 50% / 49% 45% 42% 44%;
    animation: eggRst 1s ease-out infinite;
  }
}

@keyframes eggRst {
  0% ,  100%{  transform: translate(-50%, -20px) rotate3d(90, 0, 0, 90deg); opacity: 0; }
  10% , 90% {  transform: translate(-50%, -30px) rotate3d(90, 0, 0, 90deg); opacity: 1; }
  25%  {transform:  translate(-50% , -40px) rotate3d(85, 17, 2, 70deg) }
  75% {transform:  translate(-50% , -40px) rotate3d(75, -3, 2, 70deg) }
  50% {transform:  translate(-55% , -50px) rotate3d(75, -8, 3, 50deg) }
}
@keyframes panex {
  0%{  transform: rotate(-5deg)  }
  100%{  transform: rotate(10deg)  }
}

@media (max-width: $breakpoint-lg) {
  .button.button-xl.button-icon {
    font-size: 1.6rem;
    padding: .2rem 1rem;
    .icon{
      font-size: 2.8rem;
      margin-right: .6rem;
    }
  }
}

@media (max-width: $breakpoint-xs) {
  .button{
    font-size: 1.8rem;
    padding: .8rem 1.6rem;
  }
  .button.button-xl {
    padding: 0.8rem 1.2rem;
    &.button-icon{
      font-size: 1.4rem;
      padding: .2rem .4rem;
    }
  }
}