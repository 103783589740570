.page_catalogue .temoignages{
  margin-top: 0;
}
.listing-catalogue-c{
  .downwave-title{
    display: inline-block;
    margin-top: 8rem;
    margin-bottom: 1rem;
    &::before{
      left: 50%;
      top: -5rem;
      bottom: auto;
      transform: translateX(-50%);
    }
  }
  .desc-paniers{
    max-width: 85rem;
    margin-left: auto;
    margin-right: auto;
  }
  .liste-paniers-c{
    background-color: #f4f4f4;
    padding-top: 7rem;
    padding-bottom: 6rem;
    margin-top: 5rem;
  }
  .liste-paniers{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    .panier-card{
      position: relative;
      width: calc(100% / 3 - 3rem);
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      margin-bottom: 1rem;
      @include box-shadow();
      .overlay_recette{
        text-align: center;
        .icon{
          margin-top: .8rem;
        }
      }
    }
  }
  .panier-card-content{
    text-align: center;
    background-color: #fff;
    padding: 2rem .5rem 5.5rem;
    .titre-panier{
      font-size: 1.8rem;
      font-weight: 700;
      color: $color1;
      height: 4.7rem;
      overflow: hidden;
      line-height: 1.3;
    }
    .prix-panier{
      font-size: 2.8rem;
      font-weight: 700;
      color: $color2;
    }
    .desc-panier{
      font-size: 1.5rem;
      font-weight: 400;
      color: $color4;
      text-transform: uppercase;
      height: 4.5rem;
      overflow: hidden;
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .listing-catalogue-c .liste-paniers{
    margin-left: -.5rem;
    margin-right: -.5rem;
    .panier-card{
      width: calc(100% / 3 - 1rem);
      margin-left: .5rem;
      margin-right: .5rem;
    }
  }
}

@media (max-width: $breakpoint-md) {
  .listing-catalogue-c .liste-paniers .panier-card{
    width: calc(50% - 1rem);
  }
}

@media (max-width: $breakpoint-xs) {
  .listing-catalogue-c .liste-paniers{
    margin-left: 0;
    margin-right: 0;
    .panier-card{
      width: 100%;
    }
  }
}