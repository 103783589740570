/* Rassurances */
.rassurances{
  background-image: url('../img/theme/bg-rassurances.webp');
  background-size: cover;
  background-repeat: no-repeat;
  .container-xl{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 3rem 1.5rem;

    .rassurance_card{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: calc(50% - 2rem);
      margin-top: 4rem;
      margin-bottom: 4rem;
      .rassurance_card_header{
        text-align: center;
        margin-right: 1rem;
        width: 16rem;
        .rassurance_card_title{
          font-size: 1.8rem;
          font-weight: 500;
          text-transform: uppercase;
          color: #11474e;
          line-height: 1.1;
          margin-top: 1rem;
        }
      }
      .tooltip-rassu{
        width: calc(100% - 22rem);
      }
      h3{
        margin-bottom: 1.2rem;
        text-align: left;
      }
      .icon{
        display: inline-block;
        font-size: 5rem;
        margin-right: 2.5rem;
        width: 6rem;
        &::before{
          vertical-align: middle;
        }
      }
      .sep-vertical-rassurance{
        margin-right: 2rem;
      }
      &.rassurance_color4{
        .icon{
          color: $color4;
        }
        .sep-vertical-rassurance{
          background-color: $color4;
        }
      }
      &.rassurance_color2{
        .icon{
          color: $color2;
          font-size: 6rem;
        }
        .sep-vertical-rassurance{
          background-color: $color2;
        }
      }
      &.rassurance_color7{
        .icon{
          color: $color7;
        }
        .sep-vertical-rassurance{
          background-color: $color7;
        }
      }
      &.rassurance_color3{
        .icon{
          color: $color3;
        }
        .sep-vertical-rassurance{
          background-color: $color3;
        }
      }
    }
  }
}


@media (max-width: $breakpoint-md) {
  .rassurances .container-xl .rassurance_card{
    .rassurance_card_header{
      width: 10rem;
    }
    .tooltip-rassu{
      width: calc(100% - 16rem);
    }
    .sep-vertical-rassurance{
      margin-right: 0;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .rassurances .container-xl .rassurance_card{
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    .icon{
      width: auto;
    }
    &.rassurance_color2 .icon{
      font-size: 5rem;
    }
    .sep-vertical-rassurance{
      margin-right: 2rem;
      height: 100%;
    }
    .rassurance_card_header {
      width: 15rem;
    }
  }
}

@media (max-width: $breakpoint-xs) {
  .rassurances .container-xl .rassurance_card{
    flex-direction: column;
    .rassurance_card_header{
      margin-bottom: 1rem;
      width: 20rem;
      margin-right: 0;
    }
    .sep-vertical-rassurance{
      display: none;
    }
    .tooltip-rassu {
      width: 100%;
    }
  }
}