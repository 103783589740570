/* Page /partenariat-xxxxxxxxxxxxxxxxxx */

#partenariat_page {
	margin: 0 auto;
	padding: 0; 
	&.partenariat { 
		&.saumon-ecosse-label-rouge {
			.pull-left {
				float: left;
			}
			.pull-right {
				float: right;
			}
			.part-presentation {
				width: 95%;
				overflow: hidden;
				background: url(../img/theme/Poisson1-inverse.png), url(../img/theme/Poisson2-inverse.png);
				background-position: 50px 35px, bottom right;
				background-repeat: no-repeat, no-repeat;
				background-size: 250px, 250px; 
				margin: 0 auto;
				padding: 50px 0 75px 0;
				align-items: center;
				text-align: center;
				display: flex;
				flex-direction: column;
				p {
					font-size: 22px;
				}
			}
			.part-fonctionnement {
				width: 95%;
				overflow: hidden;
				align-items: center;
				text-align: center;
				display: flex;
				flex-direction: column;
				overflow: hidden; 
				margin: 0 auto 50px;
				padding: 0;
				a:hover {
					opacity: 1;
				}
				h2 {
					margin: 0 0 50px 0;
				}
				&-pictures {
					display: flex;
					img { 
						&.cabas {
							height: 300px;
							margin:0 0 50px 0;
						}
						&.avis {
							height: 300px;
						}
					}
				}
			}
			.part-produit {
				width: 65%;
				overflow: hidden;
				margin: 0 auto 100px;
				padding: 0;
				text-align: justify;
				.line {
					margin: 35px 0 0 0;
					overflow: hidden;
				}
				p {
					font-size: 18px;
					font-weight: bold;
					line-height: 30px;
				}
				img {
					width: 400px;
					&.pull-right {
						margin: 0 0 0 35px;
					}
					&.pull-left {
						margin: 0 35px 0 0;
					}
				}
			}
			.part-recettes {
				width: 95%;
				overflow: hidden;
				margin: 0 auto 50px;
				padding: 0;
				h2 {
					margin: 0 0 50px 0;
				}
				&-recette {
					width: 95%;
					overflow: hidden; 
					margin: 0 auto 35px;
					padding: 0;
					display: flex;
					flex-direction: row;
					background: url(../img/theme/dots-red.jpg);
					background-repeat: no-repeat;
					background-size: 600px;
					padding-bottom: 20px;
					&.left {
						background-position: bottom left;
					}
					&.right {
						flex-direction: row-reverse;
						background-position: bottom right;
					}
					.wave-bleu {
						background: url(../img/theme/Vagues_Bleues-vf.png);
						background-position: -250px -88px;
						background-repeat: no-repeat;
						background-size: 600px;
						overflow: hidden;
						width: 60%; 
						margin: 0 auto;
						img {  
							margin: 50px;
							padding: 0;
						}
					}
					.wave-jaune {
						background: url(../img/theme/Vagues_Oranges-vf.png);
						background-position: -250px -88px;
						background-repeat: no-repeat;
						background-size: 600px;
						overflow: hidden;
						width: 60%; 
						margin: 0 auto;
						img {  
							margin: 50px;
							padding: 0;
						}
					}
					.titre {
						font-weight: bold;
						font-size: 3rem;
						font-family: Raleway,sans-serif;
						font-style: normal;
					}
					.delai {
						font-weight: bold;
						font-size: 2rem;
						color: #e6a61c;
						font-family: Raleway,sans-serif;
						font-style: normal;
					}
					.content {
						width: 100%;
						overflow: hidden;
						text-align: center;
						margin: 25px 0 50px 0;
						padding: 0;
						&-desc {
							width: 80%;
							margin: 0 auto;
							overflow: hidden;
							h3 {
								color: #802633;
								font-family: Raleway, sans-serif;
								font-style: normal;
								font-weight: bold;
								line-height: 30px;
								text-transform: none;
								margin: 20px 0;
								padding: 0;
								font-size: 2rem;
							}
							p {
								font-size: 2rem;
							}
							.button {
								text-transform: uppercase;
								font-size: 2rem;
								font-weight: bold;
								line-height: 3rem;
								margin: 20px 0 0 0;
							}
						}
					}
				}
			}
			.part-avis {
				width: 95%;
				overflow: hidden;
				margin: 0 auto 100px;
				padding: 0;
			}
			h1 {
				color: #e6a61c;
				font-style: normal;
				font-weight: normal;
				font-family: Raleway, sans-serif;
				font-size: 50px;
				margin: 0 0 50px 0;
				text-align: center;
				text-transform: none;
			}
			.h2 {
				color: #094952;
				font-family: Raleway, sans-serif;
				font-style: normal;
				font-weight: bold;
				line-height: 40px;
				text-align: center;
				text-transform: none;
				font-size: 3rem !important;
			}
			h2 {
				color: #094952;
				font-family: Raleway, sans-serif;
				font-style: normal;
				font-weight: bold;
				line-height: 40px;
				text-align: center;
				text-transform: none;
			}
			.color-label {
				color: #802633;
			}
			.labels {
				margin: 35px;
				padding: 0;
				img {
					height: 100px;
					margin: 0 20px;
				}
			}
			.photos {
				margin: 30px 0;
				padding: 0;
				img {
					height: 300px;
					margin: 20px; 
					align-items: center;
					justify-content: center;
				}
			}
		}
	} 
}

/* MEDIA QUERIES */
 
@media (max-width: $breakpoint-md) {
	#partenariat_page {
		&.partenariat {
			&.saumon-ecosse-label-rouge {
				h1 {
					font-size: 45px;
				}
				.labels {
					margin: 35px;
					padding: 0;
					img {
						height: 75px;
						margin: 0 20px;
					}
				}
				.part-presentation {
					background-size: 100px, 150px;
					background-position: 25px 35px,bottom right;
					.photos {
						img {
							margin: 0 0 20px 0;
						}
					}
				}
				.part-produit {
					width: 95%;
					img {
						width: 100%;
						&.pull-right {
							margin: 0 auto 20px auto;
						}
						&.pull-left {
							margin: 0 auto 20px auto;
						}
					}
				}
				.part-fonctionnement {
					&-pictures {
						display: flex;
						flex-direction: column;
						margin: 0 0 50px 0;
					}
				}
				.part-recettes {
					&-recette {
						overflow: hidden;
						text-align: center;
						margin: 0 auto 35px;
						flex-direction: column;
						&.left {
							flex-direction: column;
							background-position: bottom center;
						}
						&.right {
							flex-direction: column; 
							background-position: bottom center;
						}
						.wave-bleu {
							background-position: none;
							img {
								float: none;
								display: block;
								margin: 0 auto 20px;
								width: 400px;
								overflow: hidden; 
								padding: 0;
							}
						}
						.wave-jaune {
							background-position: none;
							img {
								float: none;
								display: block;
								margin: 0 auto 20px;
								width: 400px;
								overflow: hidden; 
								padding: 0;
							}
						}
						.titre {
							font-size: 2rem;
						}
						.delai {
 							font-size: 1.75rem;
							width: 80%;
							margin: 0 auto;
						}
						.content {
							overflow: hidden;
							&-desc {
								width: 95%;
								clear: left;
								h3 {
									margin: 10px 0;
								}
							}
						}
					}
				}
					
			}
		} 
	} 
}

@media (max-width: $breakpoint-sm) {
	#partenariat_page {
		&.partenariat {
			&.saumon-ecosse-label-rouge {
				.part-presentation {
					background-size: 100px, 150px;
					background-position: 25px 35px,bottom right;
					margin: 0 auto 20px;
					h1 {
						margin: 0 0 25px 0;
					}
					.h2 {
						font-size: 2.5rem !important;
					}
				}
				.part-fonctionnement {
					margin: 0 auto 50px;
					h2 {
						margin: 0 0 25px 0;
					}
				}
				.part-produit {
					width: 85%;
					margin: 0 auto 50px;
				}				
				.part-recettes {
					h2 {
						margin: 0 auto 25px;
						width: 75%;
					}
					&-recette {
						.wave-bleu {
							background: none;
							width: 100%;
							img {
								float: none;
								display: block;
								margin: 0 auto;
								width: 300px;
								overflow: hidden; 
								padding: 0;
							}
						}
						.wave-jaune {
							background: none;
							width: 100%;
							img {
								float: none;
								display: block;
								margin: 0 auto;
								width: 300px;
								overflow: hidden; 
								padding: 0;
							}
						}
					}
				}
				.part-avis {
					width: 85%; 
					margin: 0 auto 50px;
				}	
			}
		} 
	} 
}

@media (max-width: $breakpoint-xs) {

}