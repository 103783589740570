/* Avis vérifié (global) */
.avis_verif{
  > div{
    margin-top: -.8rem;
  }
  &.avis_verif_vertical{
    width: 13rem;
    img{
      max-width: 10rem;
      height: auto;
    }
  }
  &.avis_verif_horizontal {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    img{
      max-width: 10rem;
      margin-right: 3rem;
    }
  }
  .rating{
    padding-left: 0;
    margin-top: .5rem;
    margin-bottom: 0;
    .icon{
      color: $color2;
      margin-left: .1rem;
      margin-right: .1rem;
    }
  }
  .rating_value{
    font-size: 2.7rem;
    margin-bottom: 0;
  }
  .nb_avis{
    font-size: 1.2rem;
  }
}

/* Section temoignages (homepage) */
.temoignages{
  margin-top: 10.5rem;
  .green_block {
    background-color: #ECF5EE;
    padding-top: 6rem;
    padding-bottom: 4.5rem;
    .container{
      position: relative;
      display: flex;
      justify-content: space-around;
      margin-top: 2rem;
      text-align: center;
      > div {
        width: 13rem;
        margin-top: 1.5rem;
        margin-bottom: 4rem;
      }
      .h2{
        align-self: center;
        width: 36rem;
        line-height: 1.3;
        text-align: left;
        margin-top: 0;
        margin-left: 5rem;
      }
      .bribe_avis{
        width: calc(100% - 58rem);
        align-self: center;
        line-height: 1.5;
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 3rem;
        .slick-slide{
          padding-top: 2rem;
          li.avis_card{
            max-width: 64rem;
            padding: 3rem 1rem 2rem;
            background-color: #fff;
            border-radius: 3.3rem;
            margin-right: 11rem;
            margin-top: -2rem;
            color: #074851;
            .rating{
              padding-left: 0;
              margin-bottom: 1rem;
              .icon{
                color: $color2;
                margin-left: .1rem;
                margin-right: .1rem;
              }
            }
            .avis_verif_commentaire{
              text-align: center;
              height: 7.2rem;
              overflow: hidden;
            }
            .avis_verif_nom{
              text-align: center;
              font-weight: 700;
              margin-top: -.5rem;
            }
          }
        }
        .slick-dots{
          bottom: -6rem;
          li{
            margin: 0 .1rem;
          }
          li button::before{
            background-color: #fff;
            opacity: 1;
            transition: background-color .3s;
          }
          li button:hover::before{
            background-color: #cbdad8;
          }
          li.slick-active button::before{
            background-color: #A7C0BE;
          }
        }
        .slick-next::before,
        .slick-prev::before {
          color: $color10;
        }
        .slick-prev{
          left: 3rem;
        }
        .slick-next{
          right: 14rem;
        }
      }
    }
  }

  .avis_verif_horizontal {
    display: none;
  }
  .avis_verif_horizontal {
    justify-content: space-around;
    margin-top: 1.5rem;
    img{
      max-width: 15rem;
      margin-right: 0;
    }
  }
}


@media (max-width: $breakpoint-xl) {
  .temoignages{
    margin-top: 4.5rem;
  }
  .temoignages .green_block .container .h2{
    margin-left: 0;
  }
  .temoignages .green_block .container .bribe_avis{
    .slick-slide li.avis_card{
      max-width: 95%;
    }
    .slick-prev{
      left: -3rem;
    }
    .slick-next{
      right: -.5rem;
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .temoignages {
    .green_block{
      .bribe_avis{
        width: calc(100% - 20rem);
        .slick-slide li{
          max-width: 90%;
        }
      }
    }
  }
  .temoignages .green_block .container .bribe_avis .slick-next{
    right: -1.2rem;
  }
}


@media (max-width: $breakpoint-md) {
  .temoignages .logo_temoignages {
    margin-left: -1rem;
    margin-right: -1rem;
    img {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
  .temoignages .green_block .container .bribe_avis .slick-next{
    right: -.5rem;
  }
  .avis_verif.avis_verif_horizontal img {
    max-width: 9rem;
    margin-right: 1rem;
  }
  .temoignages .green_block{
    padding-top: 0;
    padding-bottom: 1rem;
    .container{
      flex-wrap: wrap;
      .h2{
        width: 100%;
        text-align: center;
        margin-bottom: 1.5rem;
        margin-top: 3rem;
      }
      .bribe_avis{
        width: calc(100% - 20rem);
        margin-bottom: 6rem;
        .slick-dots{
          bottom: -4rem;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .temoignages{
    margin-bottom: 1rem;
    .green_block{
      flex-wrap: wrap;
      .container{
        .h2{
          font-size: 2rem;
        }
        .bribe_avis .slick-slide li.avis_card{
          padding: 2rem .5rem;
        }
      }
      > div,
      > .bribe_avis{
        width: 100%;
      }
      .icon-citation{
        text-align: left;
        left: 2rem;
      }
    }
    .avis_verif_horizontal{
      img{
        max-width: 9rem;
      }
    }
  }
  .temoignages .green_block .container .bribe_avis .slick-next{
    right: -1.5rem;
  }
}

@media (max-width: $breakpoint-xs) {
  .temoignages{
    margin-top: 3.5rem;
    .green_block .container{
      > div{
        margin-top: 0;
        margin-bottom: 0;
      }
      .bribe_avis {
        width: 100%;
      }
    }
  }
}