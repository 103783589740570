/* Page listing des actus */


.header_blog {
  .header_img_blog {
    height: 100%;
    width: 100%;
    min-height: 20rem;
    max-height: 42rem;
    aspect-ratio: 1920/420;
    background-position: center left;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .header_img_blog_mobile {
    display: none;
    height: 100%;
    width: 100%;
    min-height: 20rem;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.header_blog_filtres {
  background-color: #ecf5ee;
  padding-top: 1rem;
  padding-bottom: 3rem;
  text-align: center;
  .container {
    position: relative;
  }
  .h3 {
    margin-top: 1.5rem;
    margin-bottom: .5rem;
  }
  .button-retour {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1.5rem;
    padding: .8rem 1.6rem;
    border-radius: 4rem;
  }
  .liste-badges,
  .liste_filtres {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: .6rem;
  }
  .liste-badges {
    margin-bottom: 3rem;
  }
  .badge {
    font-size: 1.6rem;
    font-weight: 400;
    border-radius: .8rem;
    padding: 1.8rem 1.5rem;
    color: $color1;
    border: .1rem solid #DEE2E6;
    background-color: #fff;
    transition: all .3s;
  }
  a.badge:hover {
    color: #fff;
    background-color: $color1;
  }
}

.liste_filtres .badge {
  border-radius: 4rem;
  padding: 1.2rem 1.5rem;
  font-size: 1.6rem;
  font-weight: 400;
  color: #11474e;
  border: .1rem solid #dee2e6;
  background-color: #fff;
  text-transform: none;
  transition: all .3s;
  &:hover {
    cursor: default;
  }
}

.liste_articles {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  .news_list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    border: .1rem solid #CFCFCF;
    border-radius: .8rem;
    header{
      .link_image_news {
        position: relative;
        display: block;
        .date_news {
          position: absolute;
          top: .8rem;
          right: .8rem;
          font-size: 1.6rem;
          font-weight: 700;
          padding: 1rem 1.2rem;
          border-radius: 4rem;
          color: #fff;
          background-color: $color2;
        }

        img {
          object-fit: cover;
        }
      }
      img{
        aspect-ratio: 625/450;
        /*height: 100%;*/
        width: 100%;
        border-top-left-radius: .8rem;
        border-top-right-radius: .8rem;
        object-fit: cover;
        @media (max-width: $breakpoint-xl) {
          height: 100%;
        }
      }
      .title {
        font-size: 2.2rem;
      }
    }
    section .news_txt {
      @include line-clamp(3);
      line-height: 1.3;
    }
    footer{
      text-align: center;
      margin-bottom: 2.3rem;
      .button {
        font-size: 1.6rem;
        font-weight: 500;
        padding: 1.2rem 3rem;
        border-radius: 4.8rem;
        text-transform: none;
      }
    }
  }
  article {
    position: relative;
    width: calc(100% / 2 - 1.4rem);
    background-color: #fff;
    overflow: hidden;
    border-radius: .8rem;
    section {
      padding: 1.2rem;
      .title {
        font-size: 2.2rem;
      }
      .liste_filtres {
        margin-top: .8rem;
      }
      .badge {
        font-size: 1.4rem;
        padding: .8rem 1.2rem;
      }
    }
  }
}

.liste-tags {
  display: flex;
  justify-content: center;
  gap: .6rem;
  margin-top: 1rem;
  .button-tag {
    font-size: 1.2rem;
  }
}

.button-tag {
  pointer-events: none;
}

/* Page détail de l'actu */
.news_detail {
  .header_blog_filtres .h3 {
    font-size: 4rem;
    margin-bottom: 1.5rem;
  }
  .button-retour {
    font-size: 1.5rem;
    padding: .8rem 1.6rem;
    border-radius: 4rem;
    text-transform: none;
    margin-bottom: 2rem;
  }
}

.news_content {
  margin-bottom: 3rem;
  .img_container{
    position: relative;
    margin-bottom: 2rem;
    .date {
      position: absolute;
      top: .8rem;
      left: .8rem;
      font-size: 1.6rem;
      font-weight: 700;
      padding: 1rem 1.2rem;
      border-radius: 4rem;
      color: #fff;
      background-color: #fabc4e;
      z-index: 1;
    }
    ul {
      list-style-type: none;
      padding-left: 0;
    }
    .main_image img {
      max-height: 45rem;
      object-fit: cover;
      border-radius: 1.6rem;
    }
    .main_image_noslider img {
      aspect-ratio: 625/450;
      /*height: 100%;*/
      width: 100%;
    }
    .image_defaut img {
      object-fit: contain;
      background-color: #f7f7f7;
    }
    @media (max-width: $breakpoint-sm) {
      .image_defaut {
        display: none;
      }
      &.no_image .date {
        top: -2.5rem;
      }
    }
  }
  #lst_vignette {
    display: flex;
    gap: .6rem;
    margin-top: .6rem;
    margin-left: -.6rem;
    margin-right: -.6rem;
    img {
      display: inline-block;
      width: 8rem;
      aspect-ratio: 1;
      object-fit: cover;
    }
  }
  .news_txt {
    p {
      line-height: 1.5;
      margin-bottom: 1.6rem;
    }
  }
}

/* Blocs push Newsletter et inspiration */
.blog-push-bloc {
  margin-top: 3rem;
  .green-bloc {
    color: #fff;
    background-color: $color1;
    padding: 4rem;
    border-radius: .4rem;
    margin-bottom: 2.4rem;
    .h3 {
      text-align: left;
      color: #fff;
      margin-bottom: 1.6rem;
    }
    p {
      margin-bottom: 4rem;
    }
    form {
      text-align: center;
    }
    input {
      width: 100%;
      height: 4.8rem;
      border-radius: .4rem;
      padding-left: 1rem;
      margin-bottom: .8rem;
      border: none;
    }
    .button {
      font-size: 1.6rem;
      text-transform: none;
      font-weight: 500;
      border-radius: 4rem;
      padding: 1rem 3rem;
    }
  }
  .inspiration-bloc {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
    border-radius: .4rem;
    padding: 7.7rem 4rem;
    background-size: cover;
    background-repeat: no-repeat;
    .h3 {
      color: #fff;
      margin-bottom: 1.6rem;
    }
    .button {
      font-size: 1.6rem;
      text-transform: none;
      font-weight: 500;
      border-radius: 4rem;
      padding: 1rem 3rem;
    }
  }
}


@media (max-width: $breakpoint-lg) {
  .blog-push-bloc{
    .green-bloc {
      padding: 2rem;
    }
    .inspiration-bloc{
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}

@media (max-width: $breakpoint-md) {
  .liste_articles{
    gap: 1rem;
    article {
      width: calc(50% - 1rem);
    }
  }
}

@media (max-width: $breakpoint-xs) {
  .liste_articles{
    article {
      width: 100%;
    }
  }
  .header_img_blog {
    display: none;
  }
  .header_blog .header_img_blog_mobile {
    display: block;
  }
}


/* Blog et fiche css wordpress */
.page_blog-fiche,
.page_blog {
  img {
    max-height: 666px;
    border-radius: .4rem;
    object-fit: contain;
    width: 100%;
  }
  h2 {
    text-align: left;
  }
  h3 {
    font-size: 1.5em;
    text-align: left;
    clear: both;
    margin-top: 1.5em;
    margin-bottom: .5em;
  }
  p {
    margin-bottom: 1.5em;
  }

  .blocks-gallery-grid {
    padding: 0;

    .blocks-gallery-item {
      list-style-type: none;
    }
  }
  .wp-block-image {
    margin: 2.2em 0 1.6em 0;
    margin-top: 0px;
    margin-bottom: 1em;

    /*+ p:before,
    + p:after
    {
      content: '.';
      display: block;
      overflow: hidden;
      visibility: hidden;
      width: 0;
      height: 0;
    }
    + p:after {
      clear: both;
    }*/


    /*&:not(.is-style-rounded) img {
      border-radius: inherit;
    }*/

    .alignleft {
      float:left;
      margin: .6em 1.5em 1.5em 0;
    }
    .alignright {
      float:right;
      margin: .6em 0 1.5em 1.5em;
    }
    &.aligncenter {
      text-align: center;
    }
    &.alignfull img,
    &.alignwide img {
      width: 100%
    }
    .aligncenter,
    .alignleft,
    .alignright {
      display:table
    }
    .aligncenter > figcaption,
    .alignleft > figcaption,
    .alignright > figcaption {
      display:table-caption;
      caption-side:bottom;
    }

    .aligncenter {
      margin-left:auto;
      margin-right:auto;
    }
    figcaption {
      margin-top:.5em;
      margin-bottom:1em;
    }
    &.is-style-circle-mask img,
    &.is-style-rounded img {
      border-radius:9999px;
    }

  }
  ul, ol {
    margin: 0 0 1.5em 0;
    line-height: 1.8;
  }
  .wp-block-button__link {
    line-height: 1;
    padding: 1em 2em;
    color: #fff;
    display: inline-block;
    text-align: center;
  }
  .ob-link-img {
    display: inline-flex;
    margin: 1rem;
  }
}



