#avis_page{
  ul{
    padding-left: 0;
    list-style-type: none;
    .green_block{
      position: relative;
      padding-top: 4rem;
      box-shadow: 5px 5px 5px 0 #ddd;
      .avis_text{
        width: 100%;
      }
      .date_avis{
        position: absolute;
        top: 0;
        right: 0;
        color: #fff;
        background-color: $color2;
        padding: .5rem 1rem;
      }
    }
  }
  &.temoignages {
    .logo_avis_verif {
      box-shadow: 5px 5px 5px 0 #ddd;
      max-width: 40rem;
      margin-left: auto;
      margin-top: 0;
      .avis_verif_horizontal {
        display: flex;
        width: 100%;
      }
    }
  }
}
