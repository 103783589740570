.valid-form{
  input[type="email"],
  input[type="text"],
  input[type="password"],
  textarea,
  select{
    display: block;
    border: 1px solid #dee2e6;
    padding-left: 1.2rem;
    &:focus{
      border-color: #a4afba;
      outline: none;
      box-shadow: transparent;
    }
    &.valid{
      box-shadow:  0 0 1.5px 1px green;
    }
    &.invalid{
      box-shadow:  0 0 1.5px 1px #781629;
    }
  }

  input[type="checkbox"] + label{
    margin-left: .8rem;
    font-weight: 700;
    cursor: pointer;
    margin-bottom: 0;
  }

  .icon-password{
    position: absolute;
    top: .9rem;
    right: .8rem;
    font-size: 3rem;
    cursor: pointer;
  }

  textarea{
    width: 100%;
    resize: none;
    height: 15rem;
  }

  em.valid,
  em.invalid{
    margin-top: .2rem;
    padding-left: .5rem;
    font-size: 1.1rem;
    font-style: italic;
  }

  em.invalid {
    color: #781629;
  }

  em.invalid + em.valid{
    display: none;
  }
}

.align-right-c{
  display: flex;
  justify-content: flex-end;
}

.align-center-c{
  display: flex;
  justify-content: center;
}

.custom_select select{
  padding: 1rem;
}

/* Focus effect sur input */
.custom-form{
  input[type="email"],
  input[type="text"],
  input[type="password"],
  textarea,
  select{
    height: 5.5rem;
    width: 100%;
    padding-top: 1.5rem;
    transition: all 0s;
  }

  .input-effect{
    position: relative;
    margin-bottom: 2rem;
    text-align: left;
    z-index: 1;
  }
  .focus-effect{
    padding: 7px 15px;
    border: 1px solid #ccc;
    position: relative;
    background: transparent;
  }
  .focus-effect ~ label{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 5.5rem;
    width: 100%;
    color: #888;
    background-color: #fff;
    margin-bottom: 0;
    padding-left: 1.2rem;
    padding-top: 1.6rem;
    transition: all .2s linear;
    z-index: -1;
    letter-spacing: .5px;
  }
  .focus-effect:focus ~ label,
  .has-content.focus-effect ~ label{
    top: 0;
    font-size: 11px;
    color: #888;
    padding-top: 0;
    transition: all .2s linear;
  }
}
/* /Focus effect */

/* Formulaire CMS */
.custom-form select{
  padding-top: 0;
}