.sep {
  margin: 1.5rem 0;
  height: .1rem;
  background-color: #d4d8da
}

.sep-vertical{
  display: inline-block;
  height: 1.6rem;
  width: .1rem;
  margin: 0 .8rem -.2rem;
  background-color: $color1;
}

.sep-vertical-rassurance{
  display: block;
  height: 120%;
  width: .3rem;
  background-color: $color1;
}

.sep-horizontal{
  display: block;
  height: .1rem;
  width: 100%;
  background-color: $color8;
}

.sep-horizontal-short{
  display: block;
  height: .1rem;
  width: 50%;
  min-width: 30rem;
  background-color: $color8;
  margin: 3rem auto;
}

.sep-horizontal-mobile{
  display: none;
}

.grey_block{
  background-color: $color6;
}

.green_block{
  background-color: #edf6ef;
}

.white_block{
  background-color: #fff;
}

.white_block.big_block,
.grey_block.big_block,
.green_block.big_block{
  padding-top: 3rem;
  padding-bottom: 4rem;
}

@media (max-width: $breakpoint-sm) {
  .sep-horizontal-mobile{
    display: block;
    width: 25rem;
    height: .1rem;
    background-color: $color8;
    margin: 1.5rem auto 0;
  }
  .green_block.big_block,
  .grey_block.big_block{
    padding: 1rem 2rem;
  }
  .br_mobile{
    display: block;
    margin-top: .8rem;
  }
}