.espace_client{
  .green_block,
  .neutre_block {
    position: relative;
    z-index: 0;
  }

  .button-xl{
    text-transform: uppercase;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-left: 3rem;
    font-size: 2rem;
  }

  .button{
    font-size: 1.8rem;
    padding: 0.6rem 2.5rem;
  }

}

#espaceClientInt {
  &.abonnement {
    .item-bon-reduction {
      .custom-form {
        .input-effect {
          width: 22rem;
        }
      }
      .text-center {
        font-size: 1.2rem;
      }
    }
  }
}

.bloc-decs {

  .item-bloc {

    &.min-decs {
      @include line-clamp(3);
    }
    &.full-decs {
      + .content-action {
        .plus-desc {
          .icon {
            transform: rotate(-90deg);
          }
        }
      }
    }
  }

  .plus-desc {
    cursor: pointer;
    color: $color1;
    .icon {
      font-size: 1.2rem;
      transition: all 0.1s ease-in;
    }

    @media (min-width: $breakpoint-xl) {
      display: none;
    }
  }
}


.espace_client{
  h1{
    padding-bottom: 1rem;
    margin-top: 0;
  }
  .underline{
    margin-bottom: 5rem;
  }
  .align-right-c{
    margin-top: 1rem;
    margin-bottom: 3rem;
    &.no-mb{
      margin-top: 1rem;
      margin-bottom: 0;
    }
    .button{
      width: 26rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  select{
    padding-left: .5rem;
  }

}

#espaceClientInt .double-btn-c a{
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.espace_client form.green_block,
.green_block.bordered{
  margin: 1rem auto;
  border: .1rem solid #dee2e6;
  padding: 2rem;
}
.espace_client form.neutre_block,
.neutre_block.bordered {
  margin: 1rem auto;
  padding: 2rem;
}

.espace_client form.green_block,
.espace_client form.neutre_block {
  /*max-width: 80rem;*/
}

.espace_clients {
  .row-1 {

    @media (max-width: $breakpoint-sm) {
      .item-1 {
        order: 2;
      }
      .item-2 {
        order: 1;
      }
    }
  }
}

/* Page home espace client */
.card_gestion_backoffice {
  text-align: center;

  a {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 12rem;

    width: 100%;
    max-width: 60rem;
    margin-top: .8rem;
    margin-bottom: .8rem;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    border-radius: .3rem;

    .icon {
      display: block;
      margin-bottom: .5rem;
      font-size: 3.5rem;
      margin-right: 2rem;
      line-height: .8;
    }

  }
}

.enquetes_client .underline{
  margin-bottom: 2rem;
  margin-top: 4rem;
}

/* Page infos perso */
#profile_backoffice{
  .custom-form .align-center-c{
    margin-top: 2rem;
  }
  h2{
    margin-top: 3rem;
    margin-bottom: 1.5rem;
  }
  .change_mdp_container{
    display: flex;
    justify-content: space-between;
    .input-effect{
      width: calc(50% - 2rem);
    }
  }
}

.form_input_container{
  display: flex;
  justify-content: space-between;
  > label{
    width: 20rem;
  }
  .input-effect{
    margin-left: auto;
    margin-right: auto;
    input{
      min-width: 50rem;
    }
    &.radiobox{
      min-width: 50rem;
      height: 5.5rem;
      display: flex;
      align-items: center;
      input{
        min-width: 0;
      }
      label{
        margin-left: 1rem;
        margin-right: 4rem;
        margin-bottom: 0;
      }
      em{
        bottom: -.2rem;
      }
    }
  }
}

.page_livraison .mode_livraison .nouvelle-adresse {
  margin-top: 3rem;
}
.page_livraison .mode_livraison li .new_adress{
  .radio-box{
    display: flex;
    justify-content: space-between;
    max-width: 50rem;
    > span{
      display: block;
      width: calc(50% - 1rem);
    }
    input{
      display: none;
    }
    label{
      justify-content: center;
    }
    input:checked + label{
      border-color: #e6a61c;
      background-color: rgba(230,166,28,.1);
    }
  }
  .input-effect{
    margin-left: 0;
    width: 100%;
    max-width: 50rem;
    input{
      min-width: 0;
    }
    label{
      position: absolute;
      top: 0;
      display: block;
      padding-top: 1.6rem;
      padding-left: 1.2rem;
      transition: all .2s linear;
    }
    .focus-effect:focus ~ label,
    .has-content.focus-effect ~ label{
      padding-top: 0;
    }
  }
}

.form_radio_container{
  margin-bottom: 2rem;
  height: 5.5rem;
  > div{
    display: flex;
    align-items: center;
    position: relative;
    min-width: 50rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: .2rem;
    label{
      margin-bottom: .1rem;
      margin-right: 3rem;
    }
    em{
      position: absolute;
      bottom: -.2rem;
      left: 0;
    }
  }
}

/* Page détail achat */
#espaceClientInt .totalshopline{
  text-align: right;
  margin-top: 1rem;
  font-size: 2rem;
  .aVal{
    font-size: 2.2rem;
    font-weight: 700;
    color: #e6a61c;
  }
}

#espaceClientInt .product_line .description{
  margin-left: .8rem;
  ul{
    line-height: 1.1;
    li{
      margin: .3rem 0 .3rem 1.2rem;
      a{
        margin: 0
      }
    }
  }
}

#espaceClientInt .liste-paiements{
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 3rem;
  .header-zone.green_block,
  .header-zone.neutre_block {
    width: calc(25% - 2rem);
    padding-bottom: 2rem;
    margin-bottom: 0;
  }
}

/* Page adresses */

.adress_second{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}

/* Block login */
.espace_client_login{
  background-color: #F2F2F6;
  padding-top: 2rem;
  .login_container{
    width: calc(100% - 3rem);
    max-width: 78rem;
    margin-left: auto;
    margin-right: auto;
  }
  .espaceClient_tab{
    margin-top: 4rem;
    padding-bottom: 3rem;
    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active,
    .tab-content{
      background-color: #fff;
      color: $color1;
      border-bottom-color: transparent;
    }
    .nav-tabs{
      border: none;
      .nav-link{
        margin-bottom: 0;
        padding: 2rem 4rem;
        font-family: 'Poppins', sans-serif;
        font-size: 1.8rem;
        border: none;
      }
    }
    .tab-content{
      position: relative;
      z-index: 1;
      padding: 3rem 3rem 2rem;
      border: none;
      form{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }
      .inputs-c{
        display: flex;
        align-items: flex-start;
        margin-right: 1rem;
        .input-effect{
          margin-bottom: 0;
          & + .input-effect{
            margin-left: .8rem;
          }
        }
      }
      .button-xl{
        height: 5.5rem;
        padding-right: 2.8rem;
        padding-left: 2.8rem;
        margin-bottom: 0;
        margin-top: 0;
        line-height: 1;
      }
      .submit-c{
        position: relative;
        a{
          font-size: 1.4rem;
          font-weight: 500;
          color: $color4;
        }
      }
    }
  }
}

.espaceClient_reseau{
  text-align: center;
  margin-bottom: 3rem;
  .espaceClient_reseau_button{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 2rem;
    img{
      width: 3rem;
      margin-right: 1rem;
    }
    .text{
      text-transform: uppercase;
      color: #323338;
      font-weight: 700;
      letter-spacing: .1rem;
    }
  }
}

/* /Page login */

/* Page register */

#espaceClient-register .register_container{
  width: calc(100% - 4rem);
  max-width: 50rem;
  margin: 3rem auto 4rem;
  .green_block,
  .neutre_block {
    padding: 1.5rem 2rem;
    .h3{
      margin-bottom: 3rem;
    }
    .form_radio_container em{
      bottom: -1.3rem;
    }
  }
  .shipping-address{
    height: 0;
    overflow: hidden;
  }
}

/* Page fidélité */
#espaceClientInt.fidelite_ec{
  h2{
    margin-top: 3rem;
    margin-bottom: 2rem;
  }
}

/* Page parrainages */
#espaceClientInt.parrainages{
  h2{
    margin-top: 3rem;
    margin-bottom: 2rem;
  }
  .input-effect{
    margin-top: 1rem;
    max-width: 50rem;
    margin-left: auto;
    margin-right: auto;
    input{
      max-width: 50rem;
    }
  }
  .parrainage_link_container{
    display: flex;
    align-items: center;
    .parrainage_link_input{
      padding-top: .5rem;
      margin-right: 1rem;
    }
    a{
      &:hover{
        opacity: .8;
        color: #e6a61c;
        text-decoration: none;
      }
      .icon{
        font-size: 3.5rem;
        margin-left: .8rem;
        line-height: 1;
      }
    }
  }
}

/* Page abonnement */
#espaceClientInt.abonnement {
  .green_block,
  .neutre_block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    text-align: center;
    /*max-width: 82rem;*/
    margin-bottom: 2rem;
    padding: 2rem 2rem 0;

    li {
      margin-top: 1.4rem;
      margin-bottom: 1.4rem;
      .num-semaine{
        font-weight: 700;
        line-height: 1;
      }
      .button.disabled {
        cursor: default;
        color: #fff;
        background-color: $color8;
        border-color: $color8;

      }
    }

    &.liste-semaines-abo{
      justify-content: flex-start;
      li{
        width: 11.7rem;
        margin-left: .6rem;
        margin-right: .6rem;
      }
      .button{
        padding-left: .5rem;
        padding-right: .5rem;
      }
    }

    .button {
      display: block;
      max-width: 18rem;
      margin: .5rem auto 0;
      padding: .6rem 1.5rem;
      .libelle_hover {
        display: none;
      }

      &:hover {
        .libelle_classic {
          display: none;
        }

        .libelle_hover {
          display: block;
        }
      }
    }
  }

  .sep {
    max-width: 20rem;
    margin-left: auto;
    margin-right: auto;
  }


  form {
    margin-top: 2rem;

    .selectbox_formule {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      select {
        width: 50%;
        max-width: 37rem;
        background-color: transparent;
        border: 1px solid $color15;
      }

      label {
        width: 20rem;
        margin-right: 2rem;
        text-align: left;
      }

      select {
        height: 4rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        cursor: pointer;
      }
    }

    .submitbox_formule {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      margin-bottom: 2rem;
      .button{
        margin-left: 0;
        margin-right: 0;
      }
    }

    .box_recettes {
      margin-top: 3rem;
    }


    .button.btn-add-addres{
      display: inline-block;
      margin-bottom: 1rem;
      max-width: none;
    }

    .recette-info {
      font-size: 80%;
      font-weight: 400;
      width: 100%;
      text-align: left;
      margin-left: 0;
      margin-right: 0;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      margin-top: 1rem;
      margin-bottom: 2rem;

      li {
        display: flex;
        flex-direction: column;

        width: calc(100% / 3 - 1rem);
        background-color: #fff;
        padding: .5rem;
        font-size: 1.5rem;
        margin: .5rem .5rem 1rem;
        border-radius: 1rem;
        transition: all .2s ease-in-out;

        &.li-item {
          border: 1px solid $color9;
        }

        &.no-selected {
          background-color: $color9;
        }

        a {
          line-height: 1.1;
        }
        .img_container_card {
          height: 100%;

          img.recette{
            aspect-ratio: 230/150;
            margin-bottom: .5rem;
          }
          img.label{
            width:40px;
          }
        }

        .recette_info {
          margin-top: 0;
          margin-bottom: 0;
        }


        .checkbox_formule,
        .libelle_formule {
          width: 50%;
        }

        .checkbox_formule {
          width: 100%;
          margin-top: auto;
          margin-bottom: 1rem;
          text-align: left;

          color: $color1;
          font-size: 1.8rem;
          font-weight: bold;
          text-align: center;

          label {
            cursor: pointer;
            width: 100%;
            text-align: center;
            text-transform: uppercase;
            font-size: 1.5rem;
            color: $color4;
            font-weight: normal;

            margin-left: 0;
            margin-right: 0;
            padding-left: 5px;
            padding-right: 5px;



            .icon {
              font-size: 3.2rem;
            }
          }
        }

        .libelle_formule {
          display: inline-block;
          text-align: right;
          margin-right: 5rem;
        }

        .recette_button {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          min-height: 5rem;

          .add-recette {
            position: relative;
            right: 0;
            bottom: 0;
            margin-top: .5rem;
            margin-bottom: 0;
            margin-left: auto;
            margin-right: 1rem;
            &.disable {
              background-color: #D3D3D3;
              order: 2;

              + .desc_disable {
                display: flex;
                flex-direction: column;
                color: $color5;
                order: 1;
                flex: 1;
                margin-right: 2rem;
                margin-bottom: 0;
                @media (max-width: $breakpoint-md) {
                  margin-right: 1rem;
                }
              }
            }
          }
          .item_selected {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            background-color: $color4;
            color: #fff;
            font-size: 2rem;
            padding: 8px 5px;
            transition: all .3s;
            width: 100%;
            cursor: pointer;

            .icon {
              display: flex;
              margin-right: .8rem;
              font-size: 2.4rem;
              margin-left: auto;
            }
            .item-delete {
              margin-left: auto;
              margin-right: 1rem;
              font-size: 3.2rem;
              color: $color2;
              transition: all .3s;
            }
            &:hover,
            &:focus {
              .item-delete {

                //transform: scale(1.2);
                transform: scale(1.2) rotateY(360deg);
                color: $color13;
              }
            }
          }
        }
      }
    }

    .choix_transporteur{

      li {
        width: 100%;

        label{
          display: block;
          padding-top: .6rem;
          padding-bottom: .6rem;
          padding-left: 1rem;
          line-height: 1.1;
        }

        .nomtr{
          width: auto;
          max-width: calc(100% - 10rem);
        }

        .transporteur-options {
          text-align: left;
          text-transform: capitalize;
        }
      }
    }

    .choix_date_livraison {
      justify-content: center;
    }

    &.custom-form {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left;
      width: 100%;
      max-width: 55rem;
      margin-top: 0;
      padding-bottom: 2rem;

      .input-effect {
        margin-bottom: 0;
        width: 25rem;
      }

      .button {
        margin: 0;
      }
    }
  }

  .mode_livraison {
    max-width: 100%;
    h3 {
      margin-bottom: 1.5rem;
    }

    .green_block,
    .neutre_block,
    .nav-tabs .nav-item.active {
      background-color: #fff;
    }
  }

  .green_block .choix_transporteur,
  .neutre_block .choix_transporteur {
    li {
      margin-bottom: 0.2rem;

      label {
        margin: 0;
      }
    }
  }
  .align-right-c .button{
    width: auto;
  }
}

.livraison_popup{
  input{
    padding-left: .6rem;
  }
  .liste_relais{
    margin-top: 1.5rem;
    label{
      display: block;
      border: 1px solid #094952;
      line-height: 1.1;
      width: 100%;
      margin-bottom: 1.2rem;
      padding: .4rem 1rem;
      cursor: pointer;
      text-transform: capitalize;
      .highlight{
        font-weight: 700;
      }
    }
    input{
      display: none;
    }
    label:hover,
    input:checked + label{
      border-color: #e6a61c;
      background-color: #f7f7f9;
    }
  }
}

/* Page Mode de paiement */
.choix_mode_paiement a {
  color: $color2;
  &:hover{
    color: $color3;
  }
}

.choix_mode_paiement{
  .entete-paiement{
    color: $color1;
    font-weight: 700;
    margin-bottom: 1.8rem;
  }
  .li_mp_pag{
    label{
      flex-direction: column;
    }
    .nommp{
      display: flex;
      text-align: center;
      > div{
        margin-left: 2rem;
        margin-right: 2rem;
      }
      img{
        margin-top: 1rem;
        display: block;
      }
    }
  }
  .li_mp_edr {
    label {
      flex-direction: column;
    }
  }
}

/* Page modifier mode de paiement */
#espaceClientInt.abonnement{
  .header-zone{
    display: flex;
    gap: 2rem;
    margin-left: auto;
    margin-right: auto;
    .button{
      display: inline-flex;
      align-items: center;
      width: calc(25% - 1rem);
      padding-left: 1.2rem;
      padding-right: 1.2rem;
      line-height: 1.2;
      .icon{
        line-height: 0;
        margin-right: .6rem;
      }
    }
    .light{
      display: block;
      text-transform: initial;
      font-size: 1.2rem;
    }
    #btn-payer{
      width: 100%;
      justify-content: center;
    }
  }
  .liste-paiements .paiement-card{
    padding-top: 0;
    .paiement-card-header{
      width: 100%;
    }
    .h3{
      margin-bottom: 1.5rem;
    }
    img{
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 3rem;
      max-width: 18rem;
      height: 8rem;
      object-fit: contain;
    }
    .delete-paiement{
      width: 100%;
      font-size: 1.2rem;
      text-align: right;
      margin-top: 2rem;
    }
  }
}


/* Page Lost/reset Password */
#lostpassword form,
#resetpassword form{
  margin-top: 4rem;
  margin-bottom: 3rem;
  .input-effect{
    max-width: 60rem;
    margin-left: auto;
    margin-right: auto;
    height: 10rem;
  }
}

@media (max-width: $breakpoint-md) {
  .card_gestion_backoffice a .icon{
    margin-right: 0;
  }
  #espaceClientInt.abonnement .green_block.liste-semaines-abo,
  #espaceClientInt.abonnement .neutre_block.liste-semaines-abo {
    justify-content: space-around;
  }
  #espaceClientInt .liste-paiements{
    gap: 1rem;
    .header-zone.green_block,
    .header-zone.neutre_block {
      padding: 2rem 1rem;
      width: calc(100% / 3 - 2rem);
    }
  }
  #espaceClientInt.abonnement .header-zone{
    flex-wrap: wrap;
    gap: 0 1rem;
    .button{
      width: calc(50% - 1rem);
      font-size: 1.6rem;
      min-height: 4.8rem;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  #home_backoffice .h2{
    margin-bottom: 2rem;
  }
  #home_backoffice .align-right-c{
    margin-top: 1rem;
  }
  .form_input_container{
    flex-wrap: wrap;
    > label{
      width: 100%;
    }
    .input-effect{
      width: 100%;
    }
  }
  .espace_client form.green_block,
  .espace_client form.neutre_block {
    .focus-effect{
      min-width: 0;
    }
    .form_radio_container > div em{
      bottom: -1.5rem;
    }
  }
  #profile_backoffice .change_mdp_container {
    flex-wrap: wrap;
    .input-effect{
      width: 100%;
    }
  }
  #espaceClientInt.abonnement{
    a{
      width: 100%;
      padding-left: 1rem;
      padding-right: 1rem;
    }
    form {
      .selectbox_formule{
        flex-wrap: wrap;
        label,
        select {
          width: 100%;
        }
        label{
          text-align: center;
          margin-right: 0;
        }
        select{
          max-width: 30rem;
          margin-top: 0;
          margin-left: auto;
          margin-right: auto;
        }
      }
      ul li{
        width: calc(100% / 2 - 1rem);
      }
      &.custom-form{
        flex-wrap: wrap;
        .text{
          width: 100%;
          margin-bottom: 2rem;
        }
      }
    }
  }
  .espace_client_login .espaceClient_tab .tab-content{
    padding: 1rem;
    form {
      flex-wrap: wrap;
      justify-content: center;
      .inputs-c {
        flex-wrap: wrap;
        justify-content: center;
        .input-effect{
          width: 100%;
          max-width: 35rem;
          margin-bottom: 1rem;
          + .input-effect {
            margin-left: 0;
          }
        }
      }
    }
  }
  .step-content .espace_client_login .espaceClient_tab .tab-content form label{
    font-size: 1.3rem;
  }
  .espace_client_login .espaceClient_tab .tab-content{
    form{
      justify-content: center;
    }
    .button-xl{
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  #espaceClientInt.abonnement .header-zone{
    .button{
      width: 100%;
    }
  }
  #espaceClientInt.abonnement .liste-paiements .header-zone.green_block,
  #espaceClientInt.abonnement .liste-paiements .header-zone.neutre_block{
    width: calc(50% - 1rem);
  }
}

@media (max-width: $breakpoint-xs) {
  .espace_client_login {
    padding-top: 0;
  }
  .page_espace-client .espaceClient_tab,
  .page_panier .espaceClient_tab{
    .nav-tabs .nav-link {
      padding: 2rem 1rem;
      font-size: 1.4rem;
    }
    .tab-content {
      padding: 3rem 1rem 2rem;
      .button-xl {
        display: block;
        margin: 0 auto;
        font-size: 1.8rem;
      }
    }
  }
  .enquetes_client .button{
    width: 25rem;
    & + .button{
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
  }
  .espace_client{
    h1{
      padding-bottom: 0;
      padding-top: 1rem;
    }
    .align-right-c{
      justify-content: center;
      .button {
        min-width: 20rem;
      }
    }
    form.green_block,
    form.neutre_block {
      padding: 1rem;
    }
    .underline{
      margin-bottom: 2rem;
    }
  }
  .adress_second {
    flex-wrap: wrap;
  }
  #espaceClientInt.abonnement{
    .green_block,
    .neutre_block {
      padding-left: 1rem;
      padding-right: 1rem;
      .submitbox_formule{
        flex-wrap: wrap;
        margin-top: 0;
        .h3{
          width: 100%;
          margin-bottom: 1.5rem;
        }
      }
      li{
        width: 100%;
      }
    }
    form ul li{
      width: 100%;
      .checkbox_formule label{
        padding-top: .5rem;
        padding-bottom: .5rem;
      }
    }
  }
  .espace_client ul.choix_transporteur{
    li label{
      text-align: left;
    }
    .new_adress{
      label{
        display: block;
        min-height: 4rem;
        line-height: 4rem;
        margin-bottom: .2rem;
      }
      .radio-box label{
        display: inline-block;
      }
      input{
        width: 100%;
        height: 4rem;
      }
    }
  }
  #espaceClientInt.parrainages .parrainage_link_container{
    flex-wrap: wrap;
    justify-content: center;
    .parrainage_link_input{
      width: 100%;
      margin-bottom: 0.8rem;
    }
    a .icon{
      margin-left: 0;
      margin-right: 1.5rem;
    }
  }
  #espaceClientInt .liste-paiements .header-zone.green_block,
  #espaceClientInt .liste-paiements .header-zone.neutre_block {
    padding: 2rem 1rem;
    width: calc(50% - .5rem);
  }
  #espaceClientInt.abonnement .liste-paiements .header-zone.green_block,
  #espaceClientInt.abonnement .liste-paiements .header-zone.neutre_block{
    width: 100%;
  }
}

.caption-conecs {
  position: absolute;
  color: #000;
  font-size: 1.2rem;
  text-transform: none;
  text-align: center;
  display: block;
  left: 1rem;
  right: 1rem;
  bottom: 0.5rem;
  line-height: 1;
  font-weight: bold;
  @media (max-width: 400px) {
    font-size: 0.8rem;
  }
  p {
    margin-bottom: 0;
  }
}