/* Slider */
.slick-loading .slick-list
{
    background: none;
}
/* Icons */
@font-face
{
    font-family: 'slick';
}

.slick-prev:before,
.slick-next:before
{
    font-family: inherit;
}
