

#contact {
  form{
    &.green_block{
      position: relative;
      margin-top: 2rem;
      margin-left: auto;
      margin-right: auto;
      padding: 2rem;
      z-index: 0;
      max-width: 110rem;
      border: 1px solid #dee2e6;
      textarea{
        height: 18rem;
      }
    }
  }
  .btn_container{
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 3rem;
  }
}

body .custom-form {
  position: relative;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem;
  z-index: 0;
  max-width: 110rem;
  border: 1px solid #dee2e6;
  @media (max-width: $breakpoint-xs) {
    z-index: 1;
  }
  textarea {
    height: 10rem;
  }
  select {
    background-color: #fff;
  }
}