.page_home.landing {

  .skeepers-widget-wrapper-60c04e32-951b-4d7c-a040-6f2c55276418 {
    top: .1rem !important;

    @media (max-width: 580px) {
      width: 60px !important;
    }
  }
  .banner_landing_page {
    background-color: $color3;
    color: #FFF;
    font-size: 2rem;
    text-align: center;
    font-weight: 300;
    padding: 1rem;
    @media (max-width: 1100px) {
      font-size: 1.6rem;
      padding: 1rem;
      line-height: 1.1;
    }
    @media (max-width: 767px) {
      font-size: 1.4rem;
      padding: 1rem;
    }
    a {
      color: #FFF;
    }
    p {
      display: inline;
      margin: 0;
    }
    strong {
      font-weight: 700;
    }
  }
  .btn-landing {
    display: inline-block;
    background-color: $color4;
    color: white;
    border-radius: 8rem;
    font-size: 1.8rem;
    padding: 1.4rem;
    padding-left: 3.2rem;
    padding-right: 3.2rem;
    @media (max-width: 1500px) {
      font-size: 1.6rem;
      padding: 1rem;
      padding-left: 2.75rem;
      padding-right: 2.75rem;
    }
    @media (max-width: 767px) {
      padding: 0.75rem;
      font-size: 1.6rem;
      padding-left: 2.75rem;
      padding-right: 2.75rem;
    }
  }
  .header_top {
    .header_top_center {
      @media (max-width: 991px) {
        display: none;
      }
      .first-order-text {
        font-family: Rasbern,sans-serif;
        font-weight: 400;
        color: $color1;
        position: relative;
        padding-right: 6rem;
        text-align: center;
        line-height: 1.2;
        font-size: 2rem;
        display: inline-flex;
        white-space: nowrap;
        @media (max-width: 991px) {
          line-height: 1.1;
          font-size: 1.6rem;
          padding-right: 4rem;
        }
        &::after {
          content:"\EA50";
          font-family: kfont;
          font-size: 4.5rem;
          transform: rotateZ(20deg);
          display: block;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%) rotatez(20deg);
          color: $color2;
          @media (max-width: 991px) {
            font-size: 3.5rem;
          }
        }
        p {
          display: contents;
          margin: 0;
        }
      }
    }
    .line2 {
      height: 11.1rem;
      @media (max-width: 1100px) {
        height: 8rem;
      }
      @media (max-width: 580px) {
        height: 5rem;
      }
      @media (max-width: 991px) {
        margin-left: 0;
        margin-right: 0;
        max-width: 100%;
        #logo {
          margin-left: 0;
        }
      }
      @media (max-width: 580px) {
        #logo {
          width: 15rem;
          margin-top: -1.5rem;
        }
      }
      @media (max-width: 500px) {
        #logo {
          margin-right: 0.5rem;
        }
      }
    }
    .header_top_right {
      display: flex;
      align-items: center;
      @media (max-width: 1700px) {
        padding-right: 10rem;
      }
      @media (max-width: 1500px) {
        padding-right: 20rem;
      }
      @media (max-width: 1200px) {
        padding-right: 5rem;
      }
      @media (max-width: 1135px) {
        padding-right: 10rem;
      }
      @media (max-width: 991px) {
        padding-right: 15rem;
      }
      @media (max-width: 767px) {
        padding-right: 10rem;
      }
      @media (max-width: 500px) {
        padding-right: 8.5rem;
      }
      .btn-first-cart {
        margin-left: 0;
        white-space: normal;
        line-height: 1.1;
        cursor: default;
        font-size: 1.6rem;
        @media (max-width: 991px) {
          padding: 1rem;
          padding-left: 1.2rem;
          padding-right: 1.2rem;
        }
        @media (max-width: 900px) {
          font-size: 1.4rem;
        }
        @media (max-width: 580px) {
          font-size: 1.2rem;
        }
        @media (max-width: 500px) {
          font-size: 1rem;
          padding: 0.5rem;
        }
        p {
          margin: 0;
        }
      }
    }
    &.sticky {
      .first-order-text {
        line-height: 1.1;
        font-size: 1.6rem;
        transform: translateY(-0.3rem);
        padding-right: 4rem;
        &::after {
          font-size: 3.5rem;
        }
      }
      .header_top_right {
        .btn-first-cart {
          padding: 1rem;
          transform: translateY(-0.3rem);
          @media (max-width: 580px) {
            font-size: 1rem;
            padding: 0.5rem;
          }
        }
      }
      #logo {
        @media (max-width: 580px) {
          width: 15rem !important;
          margin-top: -1.5rem;
        }
      }
    }
  }
  .slider_landing_page {
    position: relative;
    .slick-arrow {
      display: none !important;
    }
    .slider-image {
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    .slider-image-mobile {
      display: none;
      @media (max-width: 575px) {
        display: block;
        img {
          height: 89rem;
          @media (max-width: 1500px) {
            height: 70rem;
          }
          @media (max-width: 767px) {
            height: 60rem;
          }
        }
      }
    }
    .slider-image-desktop {
      display: block;
      img {
        height: 89rem;
        @media (max-width: 1500px) {
          height: 70rem;
        }
        @media (max-width: 767px) {
          height: 60rem;
        }
      }
      @media (max-width: 575px) {
        display: none;
      }
    }
  }
  .order_bloc {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 510px;
    @media (max-width: 767.98px) {
      width: 100%;
    }
    .container-xl {
      @media (max-width: 1500px) {
        padding-left: 3rem;
        padding-right: 3rem;
      }
      @media (max-width: 380px) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }
    .container-xl-video {
      @media (min-width: 767.98px) {
        margin-left: 35px;
      }
    }
    .form_bloc {
      width: 48.5rem;
      background-color: #FFF;
      border-radius: 1.6rem;
      @media (max-width: 1500px) {
        width: 42rem;
      }
      @media (max-width: 767px) {
        margin: 0 auto;
      }
      @media (max-width: 485px) {
        width: 100%;
      }
    }
    .wrap {
      padding: 2.4rem;
    }
    .title {
      font-family: Rasbern,sans-serif;
      font-size: 4rem;
      padding: 0rem;
      color: $color1;
      line-height: 1.1;
      margin-bottom: 1.5rem;
      @media (max-width: 1500px) {
        font-size: 3rem;
      }
      @media (max-width: 580px) {
        font-size: 2.5rem;
      }
      p {
        margin: 0;
      }
    }
    .checkout_bloc {
      .field {
        margin-bottom: 2rem;
        &:last-child {
          margin-bottom: 0;
        }
        .lbl {
          line-height: 1;
          margin-bottom: 1rem;
          font-size: 1.6rem;
          color: $color1;
          font-family: 'Poppins', sans-serif;
          font-weight: normal;
        }
        .values {
          display: flex;
          align-items: center;
          .item {
            line-height: 1;
            width: 6.4rem;
            height: 4.8rem;
            label {
              padding: 0;
              margin: 0;
              position: relative;
              width: 6.4rem;
              height: 4.8rem;
              cursor: pointer;
              span {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
                display: block;
                color: $color1;
              }
            }
            input[type=radio] {
              position: absolute;
              opacity: 0;
            }

            input[type=radio] + label::before,
            input[type=radio] + label::after {
              content: '';
              position: absolute;
            }

            input[type=radio] + label::before {
              left: 50%;
              top: 50%;
              transform: translateX(-50%) translateY(-50%);
              display: inline-block;
              width: 6.4rem;
              height: 4.8rem;
              background-color: transparent;
              border: 1px solid #DEE2E6;
            }

            input[type=radio]:checked + label::before {
              background-color: rgba($color4,0.15);
              border: 1px solid $color4;
            }
            input[type=radio]:checked + label span {
              color: $color4;
              font-weight: bold;
            }
          }
        }
      }
      .promo {
        text-align: center;
        margin-top: 1.6rem;
        margin-bottom: 1.6rem;
        border: 1px solid #e62176;
        background-color: #FFF8FB;
        border-radius: 10rem;
        .value {
          position: relative;
          align-items: center;
          display: inline-flex;
          font-family: 'Rasbern',sans-serif;
          font-weight: 400;
          font-size: 1.6rem;
          color: #e62176;
          @media (max-width: 485px) {
            display: flex;
            flex-direction: row;
            padding: 0.5rem;
            padding-left: 3rem;
            padding-right: 3rem;
            line-height: 1.2;
          }
          @media (max-width: 400px) {
            font-size: 1.4rem;
            line-height: 1.1;
          }
          &::before {
            content: "\EA50";
            font-family: kfont;
            font-size: 2.5rem;
            display: block;
            color: #e62176;
            margin-right: 1rem;
          }
          p {
            margin: 0;
          }
        }
      }
      .prices {
        font-family: 'Rasbern',sans-serif;
        margin-top: 2.5rem;
        .price {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .lbl {
            font-family: 'Poppins', sans-serif;
            font-size: 1.3rem;
            padding-right: 1.5rem;
            color: $color1;
            p {
              margin-bottom: 0;
            }
          }
          .value {
            display: flex;
            align-items: center;
            color: $color1;
            font-size: 1.6rem;
            @media (max-width: 400px) {
              font-size: 1.3rem;
            }
          }
          .old_price {
            margin-right: 1rem;
            text-decoration : line-through;
            color: $color10;
          }
          &.total_price {
            .old_price {
              font-size: 1.2rem;
            }
          }
          &.per_plate {
            .lbl {
              font-size: 2.4rem;
              font-weight: 600;
              @media (max-width: 1500px) {
                font-size: 2rem;
              }
              @media (max-width: 400px) {
                font-size: 1.3rem;
              }
            }
            .value {
              font-size: 3.2rem;
              @media (max-width: 1500px) {
                font-size: 2.4rem;
              }
              @media (max-width: 400px) {
                font-size: 1.8rem;
              }
              .old_price {
                font-size: 2.4rem;
                @media (max-width: 1500px) {
                  font-size: 2rem;
                }
                @media (max-width: 400px) {
                  font-size: 1.3rem;
                }
              }
            }
          }
        }
      }
    }
    .btn-landing {
      width: 100%;
      p{
        margin: 0;
      }
    }
  }
  #concept {
    @media (max-width: 767px) {
      margin-bottom: 5rem;
    }
    .h2 {
      margin-top: 8rem;
      margin-bottom: 4rem;
      @media (max-width: 1199px) {
        margin-top: 5rem;
      }
      @media (max-width: 767px) {
        margin-top: 3rem;
        margin-bottom: 3rem;
      }
    }
    .btn-test {
      margin-top: 3rem;
      @media (max-width: 767px) {
        margin-top: 0;
      }
    }
    .btn-landing {
      p{
        margin: 0;
      }
    }
    .concept-card {
      @media (max-width: 767px) {
        margin-left: 0;
        margin-right: 0;
        padding-top: 1.5rem;
        margin-top: 1.5rem;
        border-top: 1px solid $color1;
      }
    }
    .concept-card > * {
      opacity: 1;
    }
    .concept-number {
      background-color:$color1;
      @media (max-width: 767px) {
        position: relative;
        left: 0;
        top: 0;
        transform: none;
      }
      &::after {
        display: none;
      }
    }
    .concept-container {
      .concept-sep {
        width: 100% !important;
      }
    }
    .gallery_content {
      width: 100%;
      .image {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    .concept-text {
      @media (max-width: 767px) {
        margin-bottom: 3rem;
        .h3 {
          margin-top: 1rem;
          margin-bottom: 0;
          font-size: 2rem;
        }
      }
    }
  }
  .avis_verifies {
    margin-bottom: 12rem;
  }
  .reassurance_slider {
    display: flex;
    align-items: center;
    flex-direction: column;
    .liste-rassu-slider {
      margin-top: 4rem;
      margin-bottom: 10rem;
      align-items: stretch;
      @media (max-width: 991px) {
        max-width: 100%;
      }
      @media (max-width: 767px) {
        margin-bottom: 5rem;
        margin-top: 1rem;
      }
    }
    .card-rassu-slider {
      padding: 0;
      margin: 0;
      width: 20%;
      border: 0;
      border-radius:0;
      padding: 0.5rem;
      &:hover {
        box-shadow: none !important;
      }
      @media (max-width: 767px) {
        width: calc(100% / 3);
      }
      @media (max-width: 480px) {
        width: calc(100% / 2);
      }
      .wrap-item {
        border-radius: 1.6rem;
        border: 3px solid $color3;
        height: 100%;
        padding: 1.5rem;
        &:hover {
          opacity: 1;
          box-shadow: none;
          cursor: default;
        }
        .tooltip-rassu {
          display: none;
        }
        .text-rassu {
          font-size: 1.8rem;
          text-transform: none;
          height: auto !important;
          overflow: hidden;
          @media (max-width: 1200px) {
            font-size: 1.6rem;
          }
          @media (max-width: 991px) {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
  .recettes_semaine {
    >.container {
      >.h2 {
        font-family: "Poppins", sans-serif;
        font-size: 3.1rem;
        font-weight: 700;
      }
    }
    #nav-recettes-semaine {
      margin-left: 0;
      &::before {
        display: none;
      }
    }
    .btn-more {
      margin-top: 3rem;
      padding-left: 9rem;
      padding-right: 9rem;
      @media (max-width: 991px) {
        margin-top: 1.5rem;
        margin-bottom: 3rem;
      }
    }
    .overlay_recette {
      display: none;
      cursor: default;
      pointer-events: none;
    }
    .btn-landing {
      p{
        margin: 0;
      }
    }
  }
  .faq {
    .green_block {
      padding-top: 6rem;
      padding-bottom: 6rem;
      @media (max-width: 767px) {
        padding-top: 3rem;
        padding-bottom: 3rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }
    #faq_title {
      .h2 {
        font-family: 'Poppins', sans-serif;
        font-size: 2.6rem;
        font-weight: 700;
        @media (max-width: 767px) {
          font-size: 2.2rem;
        }
      }
    }
    .btn-landing {
      p{
        margin: 0;
      }
    }
  }
  .social_network {
    margin-top: 10rem;
    margin-bottom: 10rem;
    @media (max-width: 1200px) {
      margin-top: 5rem;
      margin-bottom: 7rem;
    }
    @media (max-width: 991px) {
      margin-top: 3rem;
      margin-bottom: 5rem;
    }
    .h2 {
      margin-bottom: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 767px) {
        flex-direction: column;
      }
      p {
        margin-bottom: 0;
      }
      .logo {
        width: 6rem;
        margin-right: 1.5rem;
        @media (max-width: 1100px) {
          width: 4rem;
        }
        @media (max-width: 767px) {
          margin-right: 0;
          margin-bottom: 1.5rem;
        }
        img {
          max-width: 100%;
          height:auto;
        }
      }
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      padding-left: 10rem;
      padding-right: 10rem;
      justify-content: center;
      @media (max-width: 670px) {
        padding-left: 3rem;
        padding-right: 3rem;
      }
      @media (max-width: 480px) {
        padding-left: 0;
        padding-right: 0;
      }
      li {
        list-style-type: none;
        width: 30rem;
        @media (max-width: 1100px) {
          width: calc(100% / 3);
        }
        @media (max-width: 767px) {
          width: calc(100% / 2);
          display: flex;
          flex-direction: column;
          justify-content: stretch;
        }
        @media (max-width: 450px) {
          width: 100%;
        }
        .wrap_item {
          padding: 0.5rem;
          position: relative;
          height: 100%;
          a, .no_link {
            @media (max-width: 767px) {
              height: 100%;
              display: flex;
              flex-direction: column;
            }
          }
          .title {
            opacity: 0;
            position: absolute;
            left: 0.5rem;
            top: 0.5rem;
            width: calc(100% - 1rem);
            height: calc(100% - 1rem);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 1rem;
            transition: all 0.3s ease;
            @media (max-width: 767px) {
              opacity: 1;
              position: relative;
              width: 100%;
              height: auto;
              left: auto;
              top: auto;
              padding: 0;
              flex-grow: 1;
              background: $color1;
            }
            span {
              display: inline-flex;
              background-color: $color1;
              color: #FFF;
              font-family: Rasbern,sans-serif;
              padding: 0.5rem;
              line-height: 1.2;
              text-align: center;
              @media (max-width: 767px) {
                display: block;
                width: 100%;
              }
            }
          }
          &:hover {
            .title {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  #footer {
    .menu_footer {
      ul {
        &.niveau1 {
          justify-content: space-between;
          .accordion_item {
            &.n-1 {
              display: none;
            }
            &.n-0 {
              .niveau2 {
                >li {
                  display: none;
                  &:first-child {
                    display: block;
                  }
                }
              }
            }
          }
          .sep-horizontal-mobile {
            &.n-1 {
              display: none;
            }
          }
        }
      }
      .reseaux_footer {
        .h3 {
          text-align: left;
        }
      }
    }
  }
}

/*
.skeepers-widget-wrapper-60c04e32-951b-4d7c-a040-6f2c55276418 {
  position: absolute !important;
  left:0 !important;
}
*/

.section-top-chef {
  display: none;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 8.5rem;
  @media (max-width: 1200px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  @media (max-width: 680px) {
    flex-direction: column;
    margin-top: 3rem;
  }
  .textes {
    width: 50%;
    padding-left: 8.5rem;
    padding-right: 8.5rem;
    @media (max-width: 1200px) {
      padding-left: 5rem;
      padding-right: 5rem;
    }
    @media (max-width: 680px) {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      text-align: center;
    }
    h2 {
      margin-bottom: 2rem;
      p {
        @media (max-width: 680px) {
          text-align: center !important;
        }
      }
    }
  }
  .video {
    width: 50%;
    @media (max-width: 680px) {
      width: 100%;
    }
    .slider-image {
      position: relative;
      .image-container {
        width: 57.6rem;
        padding-bottom: 4.4rem;
        position: relative;
        @media (max-width: 1200px) {
          width: 100%;
        }
        img {
          height: auto;
          @media (max-width: 680px) {
            margin: 0 auto;
          }
        }
      }
      .button {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        padding: 2.3rem;
        padding-left: 4rem;
        padding-right: 4rem;
        font-weight: 500;
        border-radius: 8rem;
        white-space: nowrap;
      }
    }
  }
}

.logo-top-chef {
  margin-left: 0;
  display: inline-flex;
  transform: translateX(-50%);
  height: 8rem;
  width: auto;
  position: absolute;
  left: 50%;
  bottom: -1.3rem;
  z-index: 2;
  @media (max-width: 767px) {
    height: 6rem;
  }
  @media (max-width: 400px) {
    height: auto;
    position: relative;
    display: block;
    transform: none;
    left: auto;
    bottom: auto;
    width: 12rem;
  }
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

body.landing_type_3 {
  .banner_landing_page {
    padding-top: 0.5rem !important;
    padding-bottom: 6rem !important;
    position: relative;
    @media (max-width: 767px) {
      padding-bottom: 4rem !important;
    }
    @media (max-width: 400px) {
      padding-bottom: 0.5rem !important;
    }
    .wrap {
      @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
  .slider_landing_page {
    @media (max-width: 575px) {
      .gallery {
        margin-bottom: 2rem !important;
      }
    }
    @media (max-width: 380px) {
      .gallery {
        margin-bottom: 1rem !important;
      }
    }
    .slider-image-desktop {
      img {
        height: 67rem !important;
      }
    }
  }
  .order_bloc {
    left: auto !important;
    right: 3.5rem;
    @media (max-width: 767px) {
      position: relative !important;
      left: auto !important;
      right: auto !important;
      top: auto !important;
      transform: none !important;
      .form_bloc {
        border: 2px solid $color1;
      }
    }
    .container-xl-video {
      margin: 0;
      @media (max-width: 767px) {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
      }
    }
    .title {
      p.color13 {
        color: $color5 !important;
      }
    }
    .checkout_bloc {
      .promo {
        border: 1px solid $color5 !important;
        .value {
          color: $color5 !important;
          &::before {
            color: $color5 !important;
          }
        }
      }
    }
  }
  .slider_landing_page {
    .slider-image-mobile {
      img {
        height: auto !important;
      }
    }
  }
}