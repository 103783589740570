/* Header spé home */
.page_home .header_top .line2{
  #logo{
    width: 30rem;
    margin-top: -2rem;
    margin-left: 0;
    .baseline{
      font-size: 1.6rem;
      letter-spacing: .11rem;
    }
  }
  .isLogged .texte_connexion{
    display: none;
  }
}
.page_home #header.header_top.sticky + #contenu{
  margin-top: 13rem;
}
.page_home #header.header_top.sticky{
  & + #contenu{
    margin-top: 14rem;
  }
  #logo img {
    width: 29rem;
  }
}

.page_home .panier .noLogged{
  margin-right: 1.5rem;
}

/* slider home */
.slider_container_home{
  position: relative;
  .gallery_content{
    .slick-prev,
    .slick-next{
      top: auto;
      bottom: 8%;
    }
  }
  .gallery{
    margin-bottom: 0;
  }
  .gallery_content img{
    object-fit: cover;
    width: 100%;
    height: 60rem;
    margin-left: auto;
    margin-right: auto;
  }
  .slider-image-desktop{
    display: block;
  }
  .slider-image-mobile{
    display: none;
  }
  .islide_content{
    position: absolute;
    top: 35%;
    left: 50%;
    width: 100%;
    justify-content: center;
    display: flex;
    padding: 1.8rem 3rem 2.2rem;
    /*background-color: #fff;*/
    text-align: center;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 1.6rem;
    .h1{
      background: #fff;
      border-radius: 1.6rem;
      padding:0.5em;
      color: $color1;
      /*max-width: 60rem;*/
      width: fit-content;
      font-size: 5.4rem;
      font-weight: 700;
    }
    .islide_description{
      font-family: 'Poppins', sans-serif;
      color: #E6A61C;
      font-size: 3rem;
      text-transform: uppercase;
      font-weight: 500;
      margin-top: 2rem;
      margin-bottom: 2.2rem;
    }
    .button{
      font-size: 2.7rem;
      padding: 1.3rem 3.4rem;
    }
  }
}

.liste-rassu-slider{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-top: -6rem;
  position: relative;
  z-index: 1;
}

.card-rassu-slider{
  position: relative;
  width: calc(25% - 2rem);
  margin-left: 1rem;
  margin-right: 1rem;
  border: .1rem solid rgba(17, 71, 78, .4);
  padding: 2.5rem 1.5rem;
  border-radius: 1.2rem;
  max-width: 22rem;
  text-align: center;
  background-color: #fff;
  .image-rassu img{
    color: #E6A61C;
    font-size: 6rem;
  }
  .text-rassu{
    font-size: 1.6rem;
    font-weight: 500;
    text-transform: uppercase;
    color: $color1;
    line-height: 1.1;
    margin-top: 1.5rem;
    height: 7.2rem;
    overflow: hidden;
  }
  .tooltip-rassu{
    position: absolute;
    bottom: 22rem;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    background-color: #fff;
    box-shadow: .1rem .1rem 1rem -.1rem #aaa;
    border-radius: 5px;
    padding: .5rem;
    color: #333;
    font-size: 1.3rem;
    line-height: 1.2;
    opacity: 0;
    pointer-events: none;
    transition: opacity .5s;
    &::after {
      display: inline-block;
      content: '';
      position: absolute;
      background-color: #fff;
      height: 10px;
      width: 10px;
      transform: rotate(45deg) translateX(-50%);
      bottom: -.9rem;
      margin: auto;
      left: 50%;
      border-radius: 0 3px 0 0;
      box-shadow: 1px -1px 4px 0 #eee;
    }
  }
  &:hover{
    opacity: 1;
    @include box_shadow_selected;
    .tooltip-rassu{
      opacity: 1;
    }
  }
}

/* Concept */
#concept {
  margin-top: 2rem;
  margin-bottom: 10.8rem;
  .h2{
    margin-top: 4rem;
    margin-bottom: 6rem;
  }
  h3{
    margin-bottom: 2rem;
  }
  .button-xl{
    margin: 2rem 0;
  }
}

.concept-container{
  display: flex;
  justify-content: space-around;
  align-items: start;
  position: relative;
  margin-bottom: .5rem;
}

.concept-card {
  text-align: center;
  width: 100%;

  .concept-img {
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 0 2rem;

    .ratio {
      min-width: 26rem;
      > * {
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        object-fit: contain;
      }
    }
    img {
      max-width: 30rem;
    }

  }
  .h3 {
    font-family: 'Rasbern', sans-serif;
    font-size: 2.4rem;
    margin-top: 2rem;
  }

  img{
    /*height: auto;*/
  }
  > *{
    opacity: 0;
  }
}

.concept-number{
  display: inline-block;
  position: relative;
  font-size: 3.5rem;
  font-family: 'Rasbern', sans-serif;
  font-weight: 700;
  color: #fff;
  width: 6rem;
  height: 6rem;
  line-height: 2;
  border-radius: 50%;
  background-color: $color1;
  &::after{
    content: url('../img/theme/concept1.webp');
    display: block;
    position: absolute;
    top: -3rem;
    right: -3rem;
  }
}
.concept-number2::after{
  content: url('../img/theme/concept2.webp');
}
.concept-number3::after{
  content: url('../img/theme/concept3.webp');
}

.concept-sep-container{
  display: flex;
  align-items: center;
  position: absolute;
  top: 1.6rem;
  left: 0;
  width: 100%;
}
.concept-sep{
  height: .1rem;
  width: 0;
  align-self: center;
  margin-top: 1.5rem;
  background-color: $color6;
}

.concept-text {
  padding: 0 2rem;
  /*height: 15rem;*/
}

/* Section recettes de la semaine */
.recettes_semaine{
  background-color: $color1;
  padding-top: 6.4rem;
  padding-bottom: 7.8rem;
  .h2,
  .h4{
    text-align: center;
    color: $color2;
  }
  .h4{
    font-size: 2.1rem;
    margin-bottom: 6rem;
  }
  .libelle_semaine{
    display: none;
  }
}

#nav-recettes-semaine{
  position: relative;
  max-width: 141.8rem;
  margin-left: 4rem;
  &::before{
    content: url("../img/theme/slider-recettes-home.webp");
    position: absolute;
    top: -10.6rem;
    left: -10.1rem;
    display: none;
  }
  .slider_semaine{
    display: none;
  }
  .nav-tabs{
    position: relative;
    justify-content: center;
    margin-top: 5.6rem;
    border-bottom: 0;
    .switch-week{
      position: absolute;
      top: -4.2rem;
      color: #fff;
      font-weight: 700;
      &:hover{
        color: $color3;
      }
      .icon{
        line-height: 1.2;
      }
      &.prev-week{
        left: 4rem;
      }
      &.next-week{
        right: 2rem;
        .icon{
          margin-left: 1rem;
        }
      }
    }
  }
  .tab-content{
    text-align: center;
    .no_recette{
      margin-bottom: 2rem;
    }
  }
  .disabled_week{
    position: relative;
    margin-top: 4.1rem;
    padding: 3rem 1.5rem;
    background-color: #fff;
  }
}

.gallery_content{
  .slider_recettes{
    padding-left: 0;
    margin-bottom: 2rem;
    .slick-slide {
      margin-left: .8rem;
      margin-right: .8rem;
      a:hover{
        opacity: 1;
        .islide{
          color: $color2;
          .add-recette{
            background-color: $color2;
          }
        }
      }
      .img_recette_c{
        position: relative;
        img{
          object-fit: cover;
          height: 31.5rem;
          width: 100%;
        }
        img.label {
          position: absolute;
          top: 1rem;
          right: 1rem;
          width: 6rem;
          height: auto;
        }
      }
      .islide{
        position: relative;
        height: 11rem;
        padding: 2.2rem 1.5rem 1.5rem;
        background-color: #fff;
        line-height: 1.2;
        font-size: 1.6rem;
        font-weight: 500;
        text-align: center;
        color: #000;
        transition: color .3s;
        overflow: hidden;
        .titre-recette{
          display: inline-block;
          height: 4.6rem;
          font-weight: 700;
          overflow: hidden;
        }
        .type-recette{
          font-size: 1.6rem;
          text-align: center;
          max-width: calc(100% - 7rem);
          margin: .5rem auto;
          font-weight: 300;
          text-transform: uppercase;
        }
        p{
          overflow: hidden;
        }
      }
    }
    .slick-prev {
      top: 20rem;
      left: -8rem;
    }
    .slick-next {
      top: 20rem;
      right: -4rem;
    }
    .slick-next::before,
    .slick-prev::before {
      color: #fff;
      font-size: 6.5rem;
      opacity: 1;
    }
    .slick-next:hover::before,
    .slick-prev:hover::before {
      color: $color2;
    }
  }
}

/* Section Un service qui facilite la vie */
section.service-facile{
  .container{
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-top: 3rem;
    .img-soleil{
      position: absolute;
      top: 0;
      right: -13.6rem;
      display: none;
      img{
        width: 100%;
        height: auto;
      }
    }
    .left-col{
      position: relative;
      width: 50%;
      padding-top: 3rem;
      .push-promo-facile{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 28rem;
        height: 28rem;
        position: absolute;
        top: 54%;
        right: -19rem;
        transform: translateY(-50%) scale(0.85);
        color: #fff;
        background-color: $color2;
        border-radius: 50%;
        font-size: 1.8rem;
        text-align: center;
        font-weight: 300;
        line-height: 1;
        @media (max-width: 1199px) {
          transform: translateY(-50%) scale(0.6);
        }
        > div{
          margin-top: 4.5rem;
        }
        .highlight{
          display: block;
          font-size: 9.5rem;
          font-family: 'Rasbern';
          font-weight: 700;
          margin-top: 2.5rem;
          margin-left: .5rem;
          margin-bottom: .5rem;
          p{
            display: inline-block;
            margin-right: -2.5rem;
          }
          .exp{
            vertical-align: top;
            font-size: 6rem;
          }
        }
      }
    }
    .right-col{
      width: 35%;
      margin-top: 3rem;
      .h2{
        text-align: left;
        font-size: 5rem;
        line-height: 1.3;
        &.stitre{
          font-size: 3.2rem;
          line-height: 1.2;
          margin-top: 4.4rem;
          max-width: 45rem;
          .highlight{
            text-transform: uppercase;
            color: $color2;
          }
        }
      }
      .facile-contenu{
        margin-bottom: 4.6rem;
        margin-top: 4.4rem;
        line-height: 1.8;
        max-width: 47rem;
        a{
          font-size: 1.7rem;
          text-decoration: underline;
        }
      }
      .button{
        padding: .8rem 3.6rem;
      }
    }
  }
}

/* Section Alimentation durable */
.alimentation_durable{
  padding-top: 9.4rem;
  padding-bottom: 6rem;
  .h2{
    margin-bottom: 4rem;
  }
}
.card_durable_c{
  display: flex;
  justify-content: space-between;
  .card_durable{
    position: relative;
    width: calc(100% / 3 - 2rem);
    .h3{
      font-weight: 500;
      line-height: 1.5;
      margin-top: 2rem;
      margin-bottom: 1rem;
      height: 6.5rem;
      overflow: hidden;
    }
  }
}
.card_durable_desc_c{
  position: relative;
  .card_durable_desc{
    height: 0;
    overflow: hidden;
    z-index: 1;
    .card_durable_desc_content{
      padding: 0 1.5rem 1rem;
    }
  }
}

.expand_text_durable{
  margin-bottom: 1rem;
  .icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    line-height: 1;
    border-radius: 50%;
    color: $color1;
    cursor: pointer;
    font-size: 1.8rem;
    transition: all .3s;
    margin-left: auto;
    margin-right: auto;
    &:hover{
      color: #fff;
      background-color: $color2;
    }
  }
  &.open .icon{
    transform: rotate(180deg);
    color: #fff;
    background-color: $color2;
  }
}

/* Section medias */
.medias-home{
  margin-top: 13.9rem;
  .h2 span{
    position: relative;
    &::after{
      content: url("../img/theme/concept3.webp");
      display: block;
      position: absolute;
      right: -4.2rem;
      top: -2.8rem;
    }
  }
  .logo_temoignages{
    display: flex;
    justify-content: space-between;
    margin-top: 6.5rem;
    a{
      @include flex_center;
    }
    img{
      display: inline-block;
      object-fit: contain;
      margin-left: 3rem;
      margin-right: 3rem;
      max-width: 10rem;
      max-height: 5.5rem;
      opacity: .5;
      transition: opacity .3s;
    }
    a:hover img{
      opacity: 1;
    }
  }
}

/* Section Kits Recettes */

.kits_recettes{
  margin-top: 11.5rem;
  margin-bottom: 6rem;
  .h2{
    font-size: 3rem;
    margin-bottom: 1rem;
  }
}

.card_kitsrecettes_c{
  display: flex;
  justify-content: center;
  .card_kitsrecettes{
    position: relative;
    .h3{
      line-height: 1.2;
      margin-top: 2rem;
      margin-bottom: 1rem;
      height: 6.5rem;
      overflow: hidden;
    }
    .card_kitsrecettes_desc_c{
      position: relative;
      .card_kitsrecettes_desc{
        height: 0;
        overflow: hidden;
        .card_kitsrecettes_desc_content{
          padding: 0 1.5rem 1rem;
          p {
            text-align: left;
          }
          h2 {
            text-align: left;
            margin-bottom: 2rem;
          }
        }
      }
    }
  }
}

.expand_text_kitsrecettes{
  margin-bottom: 1rem;
  .icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    line-height: 1;
    border-radius: 50%;
    color: $color1;
    cursor: pointer;
    font-size: 1.8rem;
    transition: all .3s;
    margin-left: auto;
    margin-right: auto;
    &:hover{
      color: #fff;
      background-color: $color2;
    }
  }
  &.open .icon{
    transform: rotate(180deg);
    color: #fff;
    background-color: $color2;
  }
}

/* FAQ */
#home #faq_question_container {
  display: block;
  height: auto !important;
  visibility: visible;
}

/* MEDIA QUERIES */

@media (max-width: $breakpoint-xl) {
  .gallery_content .slider_recettes .slick-slide{
    .img_recette_c img{
      height: 23rem;
    }
    .islide{
      height: 14.5rem;
    }
  }
  .page_home .header_top .line2 #logo{
    width: 20rem;
    .baseline{
      font-size: 1.3rem;
      letter-spacing: 0;
    }
  }
  .page_home #header.header_top.sticky{
    + #contenu{
      margin-top: 12rem;
    }
    .line2 #logo{
      margin-top: -1rem;
    }
  }
  .plaisir_simplicite .green_block .simplicite_card .simplicite_card_title_c .simplicite_card_title{
    font-size: 1.4rem;
  }
  section.service-facile .container .left-col{
    img{
      width: 100%;
      height: auto;
    }
    .push-promo-facile{
      width: 23rem;
      height: 23rem;
      top: 42%;
      right: -17rem;
      > div{
        margin-top: 2.5rem;
      }
      .highlight {
        font-size: 8rem;
        p{
          margin-right: -2rem;
        }
      }
    }
  }
  .slider_container_home{
    .islide_content{
      right: -2rem;
      padding: 2.7rem 2.7rem;
      img{
        height: 36vw;
        max-width: 111rem;
      }
    }
  }
  #concept{
    margin-bottom: 4.5rem;
    .h2 {
      margin-top: 5rem;
      margin-bottom: 6rem;
    }
  }
  .recettes_semaine{
    padding-top: 2.4rem;
    padding-bottom: 3.8rem;
  }
  section.service-facile .container .right-col{
    .facile-contenu{
      margin-bottom: 2.6rem;
      margin-top: 2rem;
    }
    .h2.stitre{
      margin-top: 2.4rem;
    }
  }
  #nav-recettes-semaine{
    margin-left: 0;
  }
  .gallery_content .slider_recettes{
    .slick-prev{
      left: -4rem;
      &::before{
        font-size: 4.5rem;
      }
    }
    .slick-next{
      right: -3rem;
      &::before{
        font-size: 4.5rem;
      }
    }
  }
  .alimentation_durable{
    padding-top: 3.4rem;
    padding-bottom: 2rem;
  }
  .medias-home{
    margin-top: 4rem;
  }
  .kits_recettes {
    margin-top: 5.5rem;
    margin-bottom: 2rem;
  }
}

@media (max-width: $breakpoint-lg) {
  .page_home .header_top{
    .line1 a{
      font-size: 1.3rem;
      .icon{
        margin-right: 0.5rem;
      }
    }
    .line2{
      #logo{
        width: 21rem;
        margin-top: -.5rem;
        margin-left: 0;
        .baseline{
          font-size: 1rem;
        }
      }
    }
  }

  //.concept-text{
  //  height: 17.2rem;
  //}
  //
  .card-rassu-slider{
    width: calc(25% - 1rem);
    margin-left: .5rem;
    margin-right: .5rem;
    padding: 2.5rem 1rem 1.5rem;
  }
  #nav-recettes-semaine .nav-tabs .nav-link{
    width: 22rem;
  }
  .gallery_content .slider_recettes .slick-slide .img_recette_c img{
    height: 19rem;
  }
  .gallery_content .slider_recettes .slick-next::before,
  .gallery_content .slider_recettes .slick-prev::before {
    font-size: 3rem;
  }
  .gallery_content .slider_recettes .slick-prev{
    left: 3.2rem;
  }
  .gallery_content .slider_recettes .slick-slide .islide{
    height: 17.5rem;
    .titre-recette{
      height: 6.5rem;
    }
  }
  #concept .h2{
    margin-top: 3rem;
    margin-bottom: 5rem;
  }
  section.service-facile .container{
    padding-top: 3rem;
    .img-soleil {
      right: -6.5rem;
      width: 32rem;
    }
    .left-col{
      width: 48%;
      .push-promo-facile{
        width: 20rem;
        height: 20rem;
        top: 20px;
        right: 62%;
        .highlight {
          font-size: 7rem;
          p{
            margin-bottom: 0;
          }
          .exp{
            font-size: 4rem;
          }
        }
      }
      img{
       /* margin-top: 15rem;*/
      }
    }
    .right-col{
      width: 46.5%;
    }
  }
  .plaisir_simplicite .green_block{
    flex-wrap: wrap;
    .simplicite_card{
      width: 50%;
      padding-left: 2rem;
      justify-content: flex-start;
      .simplicite_bulle::before{
        right: 50%;
      }
      .simplicite_card_title_c .circle_icon{
        display: inline-block;
        margin-left: 1rem;
        margin-right: 0;
      }
    }
  }
  .card_durable_c .card_durable .h3{
    height: 7.6rem;
  }
  .medias-home .logo_temoignages{
    margin-top: 3.5rem;
    img{
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
}

@media (max-width: $breakpoint-md) {
  .page_home .header_top .line1,
  .page_home #primary_nav_wrap {
    display: none;
  }
  .page_home .header_top .line2{
    height: 4.5rem;
    #logo{
      margin-left: 4rem;
    }
  }
  .slider_container_home{
    .islide_content{
      position: static;
      display: flex;
      justify-content: center;
      padding: 0.6rem 1.5rem 2rem;
      transform: none;
      margin-top: -.7rem;
      .h1{
        max-width: 50rem;
      }
      .islide_description{
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
      }
      .button{
        font-size: 2rem;
        padding: 1.1rem 2.2rem;
      }
    }
    .gallery_content img{
      height: 50rem;
    }
    .gallery_content .slick-next,
    .gallery_content .slick-prev{
      top: 50%;
      bottom: auto;
      transform: translateY(-50%);
    }
  }
  .liste-rassu-slider{
    margin-top: 0;
  }
  .remise{
    .h3{
      font-size: 1.8rem;
      .highlight{
        font-size: 3rem;
      }
    }
  }

  .concept-card{
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .concept-text{
    height: 22.2rem;
  }

  a .overlay_recette{
    font-size: 1.5rem;
    padding: 1rem 0;
  }

  .recettes_semaine{
    padding-top: 1rem;
    padding-bottom: 1rem;
    .h4{
      margin-bottom: 2rem;
    }
    #nav-recettes-semaine{
      &::before{
        top: -7.6rem;
        left: -10.1rem;
        transform: scale(0.6);
      }
      .nav-tabs{
        flex-wrap: wrap;
        margin-top: 1.5rem;
        .switch-week_c{
          display: flex;
          justify-content: space-between;
          width: 100%;
          order: -1;
          text-align: center;
          margin-bottom: 2rem;
          .switch-week{
            position: static;
            &.next-week{
              margin-left: auto;
            }
          }
        }
      }
    }
  }
  .gallery_content .slider_recettes{
       .slick-prev{
         left: -2.8rem;
       }
       .slick-slide .img_recette_c img{
         height: 22rem;
       }
  }
  .card_durable_c{
    flex-wrap: wrap;
    justify-content: center;
    .card_durable{
      width: calc(100% / 3 - 0.4rem);
      margin-left: .2rem;
      margin-right: .2rem;
      .h3{
        height: 7.5rem;
        font-size: 1.5rem;
        line-height: 1.2;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }
  }
  .alimentation_durable{
    padding-top: 2rem;
  }
  section.service-facile .container{
    .img-soleil {
      width: 26rem;
    }
    .left-col .push-promo-facile{
      width: 18rem;
      height: 18rem;
      transform: scale(.5);
      top: -8rem;
      left: -3rem;
      right: auto;

      .highlight{
        font-size: 6rem;
        margin-top: 1rem;
        margin-bottom: 0;
        p{
          margin-right: -1.5rem;
        }
      }
    }
    .right-col .h2{
      font-size: 4rem;
      &.stitre{
        font-size: 2.2rem;
        margin-top: 1.4rem;
      }
    }
  }
  .medias-home .logo_temoignages img{
    max-width: 7rem;
  }
  .card-rassu-slider{
    &:hover{
      box-shadow: none;
    }
    .tooltip-rassu{
      display: none;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .slider_container_home .islide_content{
    .h1{
      max-width: 100%;
    }
    .islide_description{
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .liste-rassu-slider{
    flex-wrap: wrap;
  }

  .card-rassu-slider{
    width: calc(50% - 1rem);
    margin-bottom: 1rem;
  }

  .plaisir_simplicite .green_block .simplicite_card{
    width: 100%;
  }

  #concept{
    margin-bottom: 2rem;
    .h2{
      margin-bottom: 2rem;
    }
  }
  .concept-sep-container{
    display: none;
  }
  .concept-container{
    flex-direction: column;
    .concept-card{
      position: relative;
      > * {
        opacity: 1;
      }
      p{
        max-width: none;
        padding: .5rem 0 0;
      }
      h3,
      p{
        margin-left: 0;
        margin-right: 0;
      }
    }
    .concept-number {
      position: absolute;
      left: 1rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .concept-text{
    height: auto;
  }

  .recettes_semaine #nav-recettes-semaine::before{
    top: -6.6rem;
    left: -5.2rem;
  }

  #nav-recettes-semaine .tab-content{
    margin: 0 2rem;
    .button.button-xl{
      padding: 1.5rem 5rem;
      font-size: 2rem;
    }
  }

  .gallery_content .slider_recettes {
    .slick-slide {
      margin-left: 4rem;
      margin-right: 4rem;
      .img_recette_c img{
        height: 31.4rem;
      }
      .islide{
        padding: 1rem;
      }
    }
    .slick-prev {
      left: -1.5rem;
    }
    .slick-next {
      right: -1.5rem;
    }
  }

  .remise {
    height: 5.5rem;
  }

  section.service-facile .container{
    flex-wrap: wrap;
    .left-col,
    .right-col{
      width: 100%;
    }
  }

  .medias-home{
    margin-top: 2rem;
    .logo_temoignages{
      flex-wrap: wrap;
      justify-content: center;
      img{
        margin: .5rem 1.5rem;
      }
    }
  }

  .kits_recettes .h2{
    font-size: 2.2rem;
  }

}

@media (max-width: $breakpoint-xs) {

  .remise h3 {
    font-size: 1.6rem;
  }

  .slider_container_home{
    .slider-image-desktop{
      display: none;
    }
    .slider-image-mobile{
      display: block;
    }
    .slide img{
      height: 20rem;
      object-fit: cover;
    }

    .islide_content{
      margin-top: -0.7rem;
      .h1{
        padding: .2rem .5rem;
        font-size: 1.8rem;
      }
    }
  }

  .remise .h3 {
    font-size: 1.5rem;
    padding: 0 1rem;
  }

  .concept-container{
    .concept-card{
      margin-left: 0;
      margin-right: 0;
      margin-top: 2rem;
      .h3{
        margin-top: 1rem;
      }
    }
    .concept-number {
      position: relative;
      font-size: 3rem;
      width: 5rem;
      height: 5rem;
      line-height: 4.9rem;
      transform: none;
    }
  }

  #nav-recettes-semaine{
    .tab-content h4{
      display: none;
    }
    .gallery_content{
      .slick-prev{
        left: .2rem;
      }
      .slick-next{
        right: .2rem;
      }
      .slick-next,
      .slick-prev {
        top: 12rem;
      }
      .slick-next::before,
      .slick-prev::before {
        font-size: 2rem;
      }
      .slider_recettes .slick-slide {
        img{
          height: auto;
        }
      }
    }
    .disabled_week{
      padding: 1rem;
    }
  }
  .card_durable_c .card_durable{
    width: 100%;
    .h3{
      height: auto;
    }
  }

  .recettes_semaine #nav-recettes-semaine .nav-tabs .switch-week_c{
    margin-bottom: 1rem;
  }

  .gallery_content .slider_recettes{
    margin-bottom: 0;
    .slick-slide {
      margin-left: 3rem;
      margin-right: 3rem;
    }
    .slick-slide .islide{
      font-size: 1.4rem;
      height: auto;
      .type-recette{
        font-size: 1.2rem;
      }
      .add-recette {
        right: .5rem;
        bottom: .5rem;
        width: 3rem;
        height: 3rem;
        font-size: 3.6rem;
        line-height: 3rem;
      }
      .titre-recette{
        height: auto;
        margin-bottom: 2rem;
      }
      .liste-tags{
        margin-right: 3rem;
      }
    }
  }

  section.service-facile .container{
    .img-soleil{
      width: 20rem;
      right: -3.5rem;
    }
    .left-col .push-promo-facile{
      right: 40%;
    }
    .right-col{
      .h2{
        font-size: 3rem;
      }
      .facile-contenu{
        line-height: 1.5;
      }
    }
  }

  .alimentation_durable .h2{
    max-width: 29rem;
    margin-left: auto;
    margin-right: auto;
    span::after{
      top: 4.4rem;
    }
  }

  .medias-home{
    margin-left: -.5rem;
    margin-right: -.5rem;
    img{
      max-width: 20.5%;
      margin-left: .5rem;
      margin-right: .5rem;
    }
  }

}

@media (max-width: 450px) {
  .liste-rassu-slider{
    align-items: stretch;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    .card-rassu-slider{
      width: calc(50% - 1rem);
      margin: 0.5rem;
      .text-rassu{
        height: auto;
      }
    }
  }
}

section.producteurs {
  .bloc_producteurs {
    display: flex;
    flex-wrap: wrap;
    padding-top: 8rem;
    padding-bottom: 8rem;
    @media (max-width: 767px) {
      flex-direction: column-reverse;
    }
    .texte {
      width: 62%;
      padding-right: 8rem;
      @media (max-width: 767px) {
        width: 100%;
        padding-right: 0;
        margin-top: 3rem;
      }
      .cta {
        margin-top: 5rem;
        text-align: center;
        @media (max-width: 767px) {
          margin-top: 3rem;
        }
      }
      .description {
        margin-top: 5rem;
        @media (max-width: 767px) {
          margin-top: 3rem;
        }
      }
    }
    .images {
      width: 38%;
      @media (max-width: 767px) {
        width: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .slick-arrow {
        display: none !important;
      }
    }
  }
}

section.service-facile {
  .left-col {
    .gallery_content {
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .slick-arrow {
        display: none !important;
      }
    }
  }
}

.container_top_home {
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    flex-direction: column-reverse;
    .remise {
      margin-top: 0;
      margin-bottom: 2rem;
    }
  }
}

.slider_container_home {
  @media (max-width: 991px) {
    .slide-gallery {
      display: block !important;
    }
    .islide_content {
      position: absolute;
      left: 50%;
      bottom: 5rem;
      transform: translateX(-50%);
      top: 33%;
      .button {
        line-height: 1.1;
        @media (max-width: 425px) {
          font-size: 1.8rem;
        }
      }
      .islide {
        .h1 {
          font-size: 3rem;
          padding: 1rem;
          @media (max-width: 425px) {
            font-size: 2rem;
          }
        }
      }
    }
  }
}

.page_home {
  .social_network {
    margin-top: 10rem;
    margin-bottom: 10rem;
    @media (max-width: 1200px) {
      margin-top: 5rem;
      margin-bottom: 7rem;
    }
    @media (max-width: 991px) {
      margin-top: 3rem;
      margin-bottom: 5rem;
    }
    .h2 {
      margin-bottom: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 767px) {
        flex-direction: column;
      }
      p {
        margin-bottom: 0;
      }
      .logo {
        width: 6rem;
        margin-right: 1.5rem;
        @media (max-width: 1100px) {
          width: 4rem;
        }
        @media (max-width: 767px) {
          margin-right: 0;
          margin-bottom: 1.5rem;
        }
        img {
          max-width: 100%;
          height:auto;
        }
      }
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      padding-left: 10rem;
      padding-right: 10rem;
      justify-content: center;
      @media (max-width: 670px) {
        padding-left: 3rem;
        padding-right: 3rem;
      }
      @media (max-width: 480px) {
        padding-left: 0;
        padding-right: 0;
      }
      li {
        list-style-type: none;
        width: 30rem;
        @media (max-width: 1100px) {
          width: calc(100% / 3);
        }
        @media (max-width: 767px) {
          width: calc(100% / 2);
          display: flex;
          flex-direction: column;
          justify-content: stretch;
        }
        @media (max-width: 450px) {
          width: 100%;
        }
        .wrap_item {
          padding: 0.5rem;
          position: relative;
          height: 100%;
          a, .no_link {
            @media (max-width: 767px) {
              height: 100%;
              display: flex;
              flex-direction: column;
            }
          }
          .title {
            opacity: 0;
            position: absolute;
            left: 0.5rem;
            top: 0.5rem;
            width: calc(100% - 1rem);
            height: calc(100% - 1rem);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 1rem;
            transition: all 0.3s ease;
            @media (max-width: 767px) {
              opacity: 1;
              position: relative;
              width: 100%;
              height: auto;
              left: auto;
              top: auto;
              padding: 0;
              flex-grow: 1;
              background: $color1;
            }
            span {
              display: inline-flex;
              background-color: $color1;
              color: #FFF;
              font-family: Rasbern,sans-serif;
              padding: 0.5rem;
              line-height: 1.2;
              text-align: center;
              @media (max-width: 767px) {
                display: block;
                width: 100%;
              }
            }
          }
          &:hover {
            .title {
              opacity: 1;
            }
          }
        }
      }
    }
  }

}

section.avis_verifies {
  margin-bottom: 10rem;
}