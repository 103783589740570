#footer {
  position: relative;
  color: #fff;
  background-color: #323338;
  padding-bottom: 1rem;
  a, .lien-off, .h3{
    color: #fff;

    &:focus,
    &:hover {
      opacity: 1;
      color: $color3;
    }
  }

  .h3{
    text-align: left;
  }
  .button-xl{
    margin-top: 2rem;
    font-weight: 700;
    text-transform: none;
    border: .1rem solid #fff;
    background-color: transparent;
    &:hover{
      border-color: $color3;
    }
  }
}

.footer_content{
  border-bottom: .1rem solid #fff;
  padding: 4.5rem 1.2rem 3rem;
}

.menu_footer ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  &.niveau1 {
    display: flex;
    justify-content: space-between;
    > li{
      margin-left: 1rem;
      margin-right: 1rem;
      .logo-tickets-img{
        text-align: center;
        margin-top: 3.5rem;
      }
    }
    .h3.collapse-link{
      display: none;
    }
  }
  &.niveau2 {
    margin-top: 1.8rem;
  }
}

.newsletter_footer{
  margin-bottom: 2rem;
  .h3{
    margin-bottom: 1.2rem;
    margin-top: .2rem;
  }
  .newsletter_container{
    position: relative;
    display: inline-flex;
    align-items: start;
    flex-direction: column;

    input {
      height: 4.5rem;
      padding-left: .8rem;
      width: calc( 100% - 4.5rem);
      max-width: 40rem;
    }
    input::placeholder{
      font-style: italic;
    }
    button[type="submit"]{
      position: absolute;
      top: 0;
      right: 0;
      height: 4.5rem;
      width: 4.5rem;
      border: none;
      color: #fff;
      background-color: #36373C;
      cursor: pointer;
      transition: background-color .3s;
      &:hover{
        background-color: $color1;
      }
    }
    em.invalid {
      color: #fff;
    }
  }
}

.contact_container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;

  .link {
    margin-top: .5rem;
    .number {
      font-size: 160%;
    }
    .icon {
      font-size: 120%;
      margin-right: .4rem;
    }
  }


  @media (max-width: $breakpoint-sm) {
    justify-content: start;
    align-items: center;
  }


}

.logo-tickets-footer{
  img{
    background-color: #fff;
    padding: 1rem;
  }
}

.reseaux_footer{
  .reseaux_links{
    margin: 1.5rem -1.5rem 0;
    text-align: center;
    .icon{
      font-size: 2rem;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }
  }
}

.copyrights{
  text-align: center;
  padding: 2rem 0;
  .sep-vertical{
    background-color: #fff;
  }
}

/* Signature Kyxar */
.kyxar {
  position: absolute;
  white-space: nowrap;
  left: 50%;
  margin-top: -2rem;
  padding: 0;
  line-height: 2.5rem;
  transform: translateX(-50%);
}

.kyxar a, .kyxar .lien-off {
  color: #fff;
  font-size: 1rem;
  text-decoration: none;
  letter-spacing: 1px;
  position: relative;

  &:hover {
    color: $color1;
    .none {
      overflow: visible
    }
  }
  .none {
    position: absolute;
    bottom: 3.8rem;
    right: 2.8rem;
    height: 0;
    overflow: hidden;
    color: $color1;

    span {
      display: inline-block;
      background-color: #fff;
      border-radius: .3rem;
      padding: .5rem 1rem;
      line-height: 1;
      font-weight: 300;
      font-size: 1rem
    }
  }
}


@media (max-width: $breakpoint-lg) {
  .zsiq_theme1 div.zsiq_cnt {
    display: none !important;
  }
}

@media (max-width: $breakpoint-md) {
  .menu_footer ul.niveau1 {
    flex-wrap: wrap;
    > li{
      width: calc(50% - 2rem);
      .logo-tickets-img{
        text-align: left;
      }
    }
  }
}

@media (max-width: $breakpoint-sm) {
  #footer{
    .footer_content{
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
    .menu_footer{
      ul{
        text-align: center;
      }
      ul.niveau1{
        flex-direction: column;
        align-items: center;
        > li{
          width: 100%;
          max-width: 40rem;
          .logo-tickets-img {
            text-align: center;
            margin-top: .5rem;
            margin-bottom: 4rem;
          }
        }
        li .h3{
          padding-top: 2rem;
          padding-bottom: 2rem;
          border-bottom-color: $color10;
          text-align: center;
        }
        li.accordion_item{
          position: relative;
          .h3{
            display: none;
            &.collapse-link{
              display: block;
              padding-top: 0;
              padding-bottom: .6rem;
            }
          }
          .icon-arrow-right{
            display: inline-block;
            margin-left: 1rem;
            font-size: 1.2rem;
            transition: transform .3s;
          }
          .h3[aria-expanded="true"] .icon-arrow-right{
            transform: rotate(90deg);
          }
        }
        ul.niveau2{
          margin-top: 1rem;
        }
        .sep-horizontal-mobile {
          margin-bottom: 2rem;
        }
        li .newsletter_footer{
          margin-bottom: 3rem;
          .h3{
            margin-top: 0;
            padding-top: 0;
          }
        }
        .reseaux_footer{
          .h3{
            margin-top: 0;
          }
          .reseaux_links{
            margin-left: 5rem;
            margin-right: 2rem;
            margin-top: 0;
          }
        }
      }
    }
  }
  .zsiq_theme1{
    .zsiq_flt_rel{
      width: 50px!important;
      height: 50px!important;
    }
    .siqico-chat::before{
      line-height: 45px!important;
    }
  }
  .siq_bR{
    right: 0!important;
  }
}

@media (max-width: $breakpoint-xs) {
  .newsletter_footer input {
    width: 23rem;
  }
  #footer .menu_footer ul.niveau1 .reseaux_footer{
    flex-wrap: wrap;
    .h3{
      width: 100%;
    }
    .reseaux_links{
      margin-left: 0;
      margin-right: 0;
      .icon {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }
  }
  .copyrights {
    text-align: center;
    .sep-vertical{
      display: none;
    }
    span,
    a,
    .lien-off {
      display: block;
    }
  }
}