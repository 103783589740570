/* Page de listing des recettes */
.recettes_page{
  .green_block {
    margin: 2rem auto 3rem;
    padding: 2rem;
    border: 1px solid #dee2e6;
    form{
      text-align: center;
      margin-top: 2rem;
      margin-bottom: 2rem;
      input:not(.button){
        height: 4.5rem;
      }
      input[type="text"]{
        width: 38rem;
        padding-left: .8rem;
      }
      input[type="submit"]{
        cursor: pointer;
        margin-left: 1.5rem;
      }
    }
  }
  ul.recettes_global_list{
    padding: 0;
    li{
      list-style-type: none;
      text-align: center;
      margin-bottom: 3rem;
      a .title_container{
        padding: 2rem 2rem 1.5rem;
        background-color: #f7f7f9;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: color .3s;
        h2{
          margin-top: 1rem!important;
          height: 10.2rem;
          overflow: hidden;
        }
      }
      a:hover h2{
        color: $color3;
      }
      img{
        width: 100%;
        height: 30.9rem;
        object-fit: cover;
      }
    }
  }
}

/* Page de détail des recettes */
#recette_detail {

  .bloc-recette {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .bloc-2-item {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 2rem;
        .bloc-img,
        .bloc-desc {
          flex: 1;
        }
    }
  }
  .img_recette_detail{
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .picto-recette {
    .icon {
      transform: rotate(90deg);
      font-size: 2rem;
      margin-top: -2rem;
    }
  }
  .bloc-recette {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .bloc-2-item {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 2rem;
      .bloc-img,
      .bloc-desc {
        flex: 1;
      }
      @media (max-width: $breakpoint-sm) {
        flex-direction: column;
      }
    }
  }
  .img_recette_detail{
    text-align: center;
    margin-top: 0;
    margin-bottom: 2rem;
    img{
      width: 65rem;
    }
  }
  .icon{
    font-size: 3rem;
    margin-right: 1rem;
  }
  .green_block{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 2.5rem;
    &.two_blocks{
      max-width: 100rem;
      margin-left: auto;
      margin-right: auto;
    }
    .col-header{
      //margin-left: 1rem;
      //margin-right: 1rem;
      li{
        list-style-type: none;
      }
    }
    .col-notations{
      .h3{
        margin-right: .5rem;
      }
      > div{
        margin-bottom: 1.2rem;
        .icon{
          line-height: 0;
        }
      }
    }
    .icon-active-star,
    .icon-flame{
      color: $color3;
      font-size: 2rem;
      margin-right: 0.1rem;
      &.grey-icon{
        color: $color8;
      }
    }
  }
  .button_cabas_container{
    text-align: center;
    a{
      margin: 3rem 2rem 2rem;
    }
  }

  #concept {
    background: rgb(17, 71, 78);
    margin-bottom: 0.75rem;
    color: white;
    font-size: 2rem;
    text-align: center;
  }
  .concept-card{
    text-align: center;
    width: 100%;
    .h3{
      font-family: Poppins,sans-serif;
      color: white;
    }
    p{
      font-family: Poppins,sans-serif;
    }
    > *{
      opacity: 100%;
    }
  }
  .concept-content{
    background: #074851;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-bottom: 5rem;
  }


  .newsletter-container{
    background: rgb(17, 71, 78);
    color: white;
    padding: 4rem;
    border-radius: 5rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    @media (max-width: $breakpoint-sm) {
      padding: 2rem 1rem;
    }
    .h3 {
      color: white;
      font-size: 3rem;
      font-weight: lighter;
    }
    p {
      font-size: 2rem;
    }
    .newsletter-email {
      border-radius: 3rem;
      width:75%;
      height: 5rem;
      margin-right: 1rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      padding:1rem;
      padding-left:2rem;
      border: none;
    }
    .newsletter-email:focus-visible{
      outline: none;
    }
    .submit {
      border: none;
      background: none;
      padding: 0;
      font-size: 3rem;
      color: $color2;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover,
      &:focus {
        color: $nutri80;
      }
    }
    a{
      color:white;
      text-decoration: underline;
    }
    .bloc-cgv {
      margin: 1rem 0;
      .input {
        margin-right: .2rem;
        border: none;
      }
    }
  }

  .recette-robot,
  .recette-nutri {
    display: none;
  }

  .container-recettes-associees {
    margin-top: 3rem;
    margin-bottom: 3rem;

    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;

      line-height: 2em;
      margin: 20px 0;
      padding: 0;
      list-style: none;

      li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        width: 100%;

        margin: 0 0 20px 0;

        a {
          display: flex;
          flex-direction: row;
          justify-content: start;
          align-items: center;

          background-color: #edf6ef;
          width: 100%;
          border-radius: 1rem 3rem 3rem 1rem;
          overflow: hidden;
          line-height: 1.3;

          color: #094952;
          font-weight: bold;

          &:hover,
          &:focus {
            background-color: $color9;
          }
          img {
            margin: 0 20px 0 0;
            width: 400px;
            @media (max-width: $breakpoint-md) {
              width: 200px;
            }
            @media (max-width: $breakpoint-sm) {
              width: 100px;
            }
          }
          .text {
            flex: 1;
            @media (max-width: $breakpoint-sm) {
              font-size: 80%;
            }
          }
        }
      }
    }
  }

}

@media (max-width: $breakpoint-xl) {
  .recettes_page ul.recettes_global_list li {
    img{
      height: 23rem;
    }
    a .title_container h2{
      height: 11.8rem;
    }
  }
  #recette_detail .green_block.two_blocks{
    max-width: none;
  }
}
@media (max-width: $breakpoint-lg) {
  .block_tablet{
    display: block;
  }
  #recette_detail .img_recette_detail img {
    width: 50rem;
  }
  .recettes_page ul.recettes_global_list li {
    img{
      height: 19rem;
    }
  }
}

@media (max-width: $breakpoint-md) {
  .col-header{
    margin-bottom: 2rem;
  }
  .block_tablet{
    display: inline;
  }
  #recette_detail .header{
    margin-bottom: 0;
  }
  .recettes_page ul.recettes_global_list li {
    img{
      height: 22rem;
    }
    a .title_container h2{
      height: 7.5rem;
    }
  }
  .recettes_page .green_block form input[type="submit"]{
    margin-left: 0;
    margin-top: 1rem;
  }
}

@media (max-width: $breakpoint-sm) {
  #recette_detail {
    .button_cabas_container a{
      margin: 2rem 1rem 2rem;
    }
    .img_recette_detail img{
      width: 100%;
    }
  }
  .recettes_page ul.recettes_global_list li {
    img{
      height: 30.5rem;
    }
    a .title_container h2{
      height: 7.5rem;
    }
  }
}
@media (max-width: $breakpoint-sm) {
  #recette_detail .button_cabas_container{
    margin-bottom: 2rem;
    a{
      margin: 1rem 0;
      width: 20rem;
    }
  }
  .recettes_page ul.recettes_global_list li a .title_container h2{
    height: auto;
    line-height: 1.2;
    margin-top: 0;
    overflow: visible;
  }
}

@media (max-width: $breakpoint-xs) {
  .recettes_page {
    .green_block {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      width: auto;
      padding-left: 1rem;
      padding-right: 1rem;
      form input[type="text"],
      form input[type="submit"]{
        display: block;
        width: 100%;
      }
    }
    ul.recettes_global_list li img{
      height: auto;
    }
  }
  #recette_detail .green_block{
    justify-content: center;
    padding: 1rem;
    .col-header{
      margin-bottom: 0;
      width: 100%;
      ul{
        padding-left: 0;
        text-align: center;
      }
      .notations_values{
        display: block;
        text-align: center;
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
      }
    }
  }
}