/* Page /recettes-saison-semaine */

#recettesSaison {
	margin: 50px auto;
	color: #000;
	h2 {
		color: #094952;
	}
}

#faqSaison { 
	margin: 75px auto;
	color:#000;
	h3 {
		color:#e6a61c !important;
		font-weight: 400;
		font-style: italic;
		font-size: 2em;
	}
	#accordion {
		margin:20px 0 50px;
		.faq_card {
			a:not(.collapsed) {
				color: #000 !important;
				.icon-plus {
					transform: rotate(45deg);
					color: #000;
					left: -5px;
				}
			}
			.faq_saison {
				margin:10px;
				color:#e6a61c !important;
				font-size:1.25em;
				display:block;
				text-align:left;
				font-weight: 400;
				font-style: italic;
				.icon {
					position:relative;
					top: 5px;
					font-size:2em;
				}
			}
		}
		.card-body {
			margin: 0 20px;
		}
	}
}

.recettes-cards {
	display: flex;
    justify-content: space-around;
	.recette-card {
		width: 505px;
		min-height: 800px;
		text-align: center;
		margin: 50px 0;
		padding: 0;
		border: 2px solid #094952;
		position: relative;
		display: flex;
		flex-direction: column;
		&-libelle {
			display: block;
			background: #094952;
			padding: 20px;
			margin: 0;
			color: #e6a61c;
			font-size: 1.75em;
			font-family: Lora,sans-serif; 
		}
		&-titre, &-description {
			font-weight: bold;
		}
		&-titre {
			height: 100px;
			align-items: center;
			justify-content: center;
			display: flex;
			padding: 0 30px;
		}
		&-description {
			height: 225px;
			display: flex;
			align-items: start;
			justify-content: center;
			padding: 30px;
		}
		&-image {
			height: 285px;
			padding: 0 30px;
			overflow: hidden;
		}
		p {
			padding: 0 30px;
			margin: 0;
		}
		img {
			object-fit: cover;
			width: 100%;
			overflow: hidden;
		}
		.button {
			margin: 10px 0;
		}
	}
}

/* MEDIA QUERIES */
 
@media (max-width: $breakpoint-md) {
	.recettes-cards {
		flex-direction: column;
		align-items: center;
		.recette-card {
			width: 100%;
			height: 100%;
			overflow: hidden;
		}
	}
}

@media (max-width: $breakpoint-sm) {
	.recette-card {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
}

@media (max-width: $breakpoint-xs) {
	.recette-card {
		width: 100%;
		height: 100%;
		overflow: hidden;
		&-description {
		    min-height: 250px;
		}
		&-image {
			min-height: 285px;
			padding: 0 30px;
			overflow: hidden;
		}
		img {
			height: 100% !important;
		}
	}
}