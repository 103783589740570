#newsletter_page{
  #content_newsletter {
    max-width: 80rem;
    margin: 20px auto;
  }

  input.button-xl {
    text-transform: uppercase;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-left: 3rem;
    font-size: 2rem;
  }
}