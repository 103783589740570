/*** Style generique ***/
body,
button,
fieldset,
form,
html,
textarea {
  font-family: 'Poppins',sans-serif;
}

body,
html {
  scroll-behavior: smooth
}

html {
  font-size: 62.5%;
  height: 100%
}

body {
  font-size: 1.6rem;
  font-weight: 400;
}

.scroller {
  overflow-x: hidden
}

.overflow-hidden {
  overflow: hidden
}

.fixed {
  position: fixed
}

.relative {
  position: relative
}

.absolute {
  position: absolute
}

.static {
  position: static;
  overflow: hidden
}

.inline {
  display: inline
}

.inlineblock {
  display: inline-block
}

.block {
  display: block
}

.flex {
  display: -ms-flexbox;
  display: flex
}

.hidden,
.masque {
  display: none
}

.floatleft {
  float: left
}

.floatright {
  float: right
}

.floatnone {
  float: none
}

.textleft {
  text-align: left
}

.textright {
  text-align: right
}

.textcenter {
  text-align: center
}

.textjustify {
  text-align: justify
}

.width80 {
  width: 80%
}

.width60 {
  width: 60%
}

.width50 {
  width: 50%
}

.width45 {
  width: 45%
}

.width35 {
  width: 35%
}

.width33 {
  width: 33.33333333%
}

.width25 {
  width: 25%
}

.margin0 {
  margin: 0
}

.marginauto {
  margin: auto
}

.lineh15 {
  line-height: 1.5
}

.lineh25 {
  line-height: 2.5
}

.marginright {
  margin-right: 2rem
}

.marginleft {
  margin-left: 2rem
}

.marginbottom {
  margin-bottom: 2rem
}

.margintop {
  margin-top: 2rem
}

.maxw100 {
  max-width: 10rem;
  margin: .5rem auto;
  min-width: 10rem
}

.maxw150 {
  max-width: 15rem;
  margin: .5rem auto;
  min-width: 15rem
}

.maxw180 {
  max-width: 18rem;
  margin: .5rem auto;
  min-width: 18rem
}

.maxw280 {
  max-width: 28rem;
  margin: .5rem auto;
  min-width: 28rem
}

.padding10 {
  padding: 1rem
}

.padding25 {
  padding: 2.5rem
}

.padding30 {
  padding: 3rem
}

.padding40 {
  padding: 4rem
}

.padding60 {
  padding: 6rem
}

.padding1020 {
  padding: 1rem 2rem
}

.padding6040 {
  padding: 6rem 4rem
}

.padding7040 {
  padding: 7rem 4rem
}

.padding4015 {
  padding: 4rem 1.5rem
}

.nomarge {
  padding-right: 0;
  padding-left: 0
}

.transition {
  transition: all .3s ease-in
}

.aheight {
  display: inline-block;
  line-height: 1.2;
  vertical-align: middle
}

.vmiddle {
  vertical-align: middle
}

.vtop {
  vertical-align: top
}

.vbottom {
  vertical-align: bottom
}

.fontsize40 {
  font-size: 40%
}
.fontsize50 {
  font-size: 50%
}

.fontsize60 {
  font-size: 60%
}
.mini,
.fontsize80 {
  font-size: 80%
}

.fontsize85 {
  font-size: 85%
}

.fontsize90 {
  font-size: 90%
}

.fontsize110 {
  font-size: 110%
}

.big,
.fontsize120 {
  font-size: 120%
}

.fontsize130 {
  font-size: 130%
}
.fontsize140 {
  font-size: 140%
}
.fontsize150 {
  font-size: 150%
}
.fontsize160 {
  font-size: 160%
}
.fontsize170 {
  font-size: 170%
}
.fontsize180 {
  font-size: 180%
}

.bold,
.strong,
strong {
  font-weight: 700
}

.lowercase {
  text-transform: lowercase
}

.uppercase {
  text-transform: uppercase
}

figure {
  margin: 0;
}

.mobile,
figcaption {
  display: none;
}

.desktop {
  display: block
}

:-ms-input-placeholder,
::-moz-placeholder,
::-webkit-input-placeholder,
::placeholder {
  color: #91a1a1;
  font-style: italic;
  text-transform: none;
  font-weight: 700;
  font-size: 1.3rem
}

img {
  max-width: 100%
}

.cms img {
  height: auto
}

.icon,
.text {
  display: inline-block;
  vertical-align: middle
}

.clear {
  clear: both;
  display: block;
  overflow: hidden;
  height: 0
}

.clearfix:after,
.clearfix:before {
  content: '.';
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0
}

.clearfix:after,
.line,
.row,
.wrap {
  clear: both
}

body,
body.mce-content-body {
  color: #333;
  background-color: #fff
}

body .hidden,
body .masque {
  display: none
}

// Liens
a {
  transition: all .3s ease-in
}
.lien-off {
 cursor: pointer;
}
.link2 {
  text-decoration: underline;
  a:focus,
  a:hover {
    text-decoration: none;
  }
}
.cms a:not(.button),
.mce-content-body a:not(.button),
a {
  color: $color1;
  text-decoration: none;
  cursor: pointer
}

.cms a:not(.button):focus,
.cms a:not(.button):hover,
.mce-content-body a:not(.button):focus,
.mce-content-body a:not(.button):hover,
a:focus,
a:hover {
  opacity: .8;
  color: $color3;
  text-decoration: none
}

// Listes
#builder .texte_content ol,
#builder .texte_content ul,
.cms ol,
.cms ul,
.faq_answer ol,
.faq_answer ul,
.mce-content-body ol,
.mce-content-body ul {
  line-height: 1.5rem;
  list-style-type: inherit
}

#builder .texte_content ol li,
#builder .texte_content ul li,
.cms ol li,
.cms ul li,
.faq_answer ol li,
.faq_answer ul li,
.mce-content-body ol li,
.mce-content-body ul li {
  list-style-position: inside;
  margin: .4rem auto .4rem 2rem
}

#builder .texte_content ol,
.cms ol,
.faq_answer ol,
.mce-content-body ol {
  list-style-type: decimal
}

.liste li {
  list-style-type: square;
  list-style-position: inside;
  margin-left: 1rem;
  padding: .2rem 0
}

// Tables
.table {
  display: table;
  border: .1rem solid #e3e3e3;
  border-collapse: collapse;
  font-size: 1.1rem;
  background-color: #fff;
  color: #3d3d3d
}

.table-container {
  width: 100%;
  overflow-y: auto
}

.table td,
.table th {
  border: .1rem solid #e3e3e3;
  padding: .5rem 1rem
}

.table th {
  padding: 1rem
}

.td {
  display: table-cell
}

.cms{
  .video_container{
    max-width: 64rem;
    margin-left: auto;
    margin-right: auto;
  }
  .box_shadow {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
}

// Accordeon
.entete_accordeon,
.ui-accordion .entete_accordeon {
  background: #209cbf none repeat scroll 0 0;
  border: .1rem solid #209cbf;
  color: #fff;
  font-size: 1.9rem;
  padding: .3rem 1rem;
  border-radius: 0
}

.mce-content-body .stitre.entete_accordeon:after,
.mce-content-body h2.entete_accordeon:after,
.mce-content-body h2:after {
  border-bottom: none;
  margin: auto;
  width: auto;
  display: inline-block
}

// Video
#form-builder .liste-content .video_content,
#grid_builder .content.type-video,
#video_home .video_c {
  width: 100%;
  padding-top: 56%;
  height: 0;
  position: relative
}

#grid_builder .content.type-video .abs_ratio,
#video_home .absolute_c {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0
}

#form-builder .liste-content .video_content iframe,
#grid_builder .content.type-video iframe,
#video_home .absolute_c iframe {
  max-width: 100%;
  min-width: 100%;
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0
}

/*** /Style generique ***/

#grid_builder_content .row {
  margin: 0 auto 2rem auto;
  padding: 2rem;
  overflow: hidden;
  background-color: #fff
}

#body_cms .scroller > .content {
  margin-top: 5rem
}

.body_cms #img_accueil .img_container {
  height: 55rem
}

.body_cms h4 {
  margin-top: 3rem
}

#body_cms #img_accueil {
  margin-top: -5rem
}

#body_cms #bloc {
  padding: 2rem 2rem 0
}

#body_cms .content .container {
  margin: 0 auto;
  width: 114rem;
  overflow: hidden;
  background-color: #fff;
  min-height: 35rem
}

#body_cms .content .container .margin_content {
  padding: 0
}

#body_cms .contenu_content,
#cms {
  margin-top: 0
}

#body_cms .content a {
  color: #a72430
}

#body_cms a.bx-next,
#body_cms a.bx-prev {
  color: #fff
}

#body_cms .content a:hover {
  color: #0082b8
}

/* Section Kits Recettes */

.seoconcept{
  margin-bottom: 5rem;
  .h2{
    margin-bottom: 4rem;
  }
}

.card_seoconcept_c{
  display: flex;
  justify-content: center;
  .card_seoconcept{
    position: relative;
    .h3{
      line-height: 1.2;
      margin-top: 2rem;
      margin-bottom: 1rem;
      height: 6.5rem;
      overflow: hidden;
    }
    .card_seoconcept_desc_c{
      position: relative;
      .card_seoconcept_desc{ 
        height: 0;
        overflow: hidden; 
        .card_seoconcept_desc_content{
          padding: 0 1.5rem 1rem;
		  p {
			text-align: left;
		  }
		  h2 {
			text-align: left;
			margin-bottom: 2rem;
		  }
        }
      }
    }
  }
}

.expand_text_seoconcept{
  margin-bottom: 1rem;
  .icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    line-height: 1;
    border-radius: 50%;
    color: $color1;
    background-color: #f7f7f9;
    cursor: pointer;
    font-size: 1.8rem;
    transition: all .3s;
    margin-left: auto;
    margin-right: auto;
    &:hover{
      color: #fff;
      background-color: $color2;
    }
  }
  &.open .icon{
    transform: rotate(180deg);
    color: #fff;
    background-color: $color2;
  }
}