.recette_card .btn-quantity-c{
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 25rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  .button.button-xl{
    width: 4rem;
    height: 4rem;
    font-size: 4rem;
    padding: 0;
    line-height: 1;
    margin-top: 0;
    &.recette-card-button-moins,
    &.recette-popin-button-moins{
      line-height: 0;
      padding-bottom: 6px;
    }
  }
  .recette-card-quantite,
  .recette-popin-quantite{
    width: calc(100% - 8rem);
    //max-width: 5rem; // Désactivé pour afficher "personnes" après la quantité
    height: 4rem;
    line-height: 4rem;
    background-color: #fff;
    font-size: 2rem;
    font-weight: 700;
  }
  .recette-popin-quantite{
    background-color: #f7f7f9;
  }
}

.choix-semaine-container h3{
  font-weight: 400;
}

.content-card .option-libelle{
  margin-bottom: .6rem;
}

@media (max-width: $breakpoint-sm) {
  .recette-card .btn-quantity-c{
    max-width: 18rem;
  }
}