h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6,
.rassurance,
.text,
.t1, .t2, .t3,
.title,
.desc,
.titre,
.stitre,
.sstitre,
.news_content {
  word-wrap: normal;
  hyphens: none;
  line-height: 1;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: $color1;
  text-align: center;
  margin: 1rem 0 0.5rem;
  > p {
    margin: 0;
  }
}

h1,
.h1,
.titre {
  font-family: 'Rasbern', sans-serif;
  font-weight: 700;
  font-size: 4rem;
}

h2,
.h2,
.stitre {
  font-family: 'Rasbern', sans-serif;
  font-size: 3.4rem;
  font-weight: 700;
  color: $color1;
}

h3,
.h3,
.sstitre {
  font-family: 'Poppins', sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
  font-style: normal;
}

h4,
.h4,
.ssstitre {
  font-family: 'Poppins', sans-serif;
  font-size: 1.6rem;
  font-weight : 300;
  text-transform: uppercase;
  text-align: left;
}

h5,
.h5{
  font-family: 'Poppins', sans-serif;
  font-size: 1.6rem;
  font-weight : 700;
  text-align: left;
}

h2,
.h2,
h4,
.h4{
  &.underline{
    text-align: center;
    margin-top: 1.2rem;
    &::after{
      content:'';
      display: block;
      width: 10rem;
      height: .9rem;
      margin-top: 1.2rem;
      margin-left: auto;
      margin-right: auto;
      background-image: url("../img/theme/sep.webp");
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

.title-underline{
  margin-bottom: 2.5rem;
  span{
    position: relative;
    display: inline-block;
    &::after{
      content: "";
      position: absolute;
      bottom: -3rem;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("../img/theme/title-underline.svg");
    }
  }
}

.downwave-title,
.upwave-title{
  position: relative;
  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 250px;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../img/theme/green-wave.svg");
  }
  p{
    margin-top: 0;
  }
}

.downwave-title{
  margin-bottom: 5rem;
  text-align: left;
  &::before{
    bottom: -4rem;
    left: 0;
  }
}

.upwave-title{
  margin-top: 7rem;
  &::before{
    top: -5rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: $breakpoint-lg) {
  .h2,
  .stitre,
  h2{
    font-size: 2.6rem;
  }
}

@media (max-width: $breakpoint-md) {

  body{
    font-size: 1.5rem;
  }

  h1,
  .h1,
  .titre
  {
    font-size: 3rem
  }

  h2,
  .h2,
  .stitre{
    font-size: 2.2rem;
  }

  h3,
  .h3,
  .sstitre {
    font-size: 1.8rem
  }

  h4,
  .h4,
  .ssstitre
  {
    font-size: 1.6rem
  }
}