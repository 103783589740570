@font-face {
  font-family: "kfont";
  src: url('../fonts/kfont.eot');
  src: url('../fonts/kfont.eot?#iefix') format('eot'),
  url('../fonts/kfont.woff2') format('woff2'),
  url('../fonts/kfont.woff') format('woff'),
  url('../fonts/kfont.ttf') format('truetype'),
  url('../fonts/kfont.svg#kfont') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon-cabas:before,
.icon-compte:before,
.icon-facebook:before,
.icon-froid:before,
.icon-instagram:before,
.icon-liberte:before,
.icon-livraison:before,
.icon-service:before,
.icon-telephone:before,
.icon-arrow-left:before,
.icon-arrow-right:before,
.icon-citation:before,
.icon-arrow-up:before,
.icon-arrow-down:before,
.icon-cadeau:before,
.icon-decompte:before,
.icon-robot:before,
.icon-zoom1:before,
.icon-zoom2:before,
.icon-password-show:before,
.icon-password-hide:before,
.icon-close:before,
.icon-aide:before,
.icon-cuisson:before,
.icon-preparation:before,
.icon-corbeille:before,
.icon-mapmarker:before,
.icon-cross:before,
.icon-pinterest:before,
.icon-adresse:before,
.icon-fidelite:before,
.icon-parrainage:before,
.icon-formule:before,
.icon-recettes:before,
.icon-clock:before,
.icon-home:before,
.icon-active-star:before,
.icon-star:before,
.icon-miactive-star:before,
.icon-coupon:before {
  font-family: "kfont";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  text-decoration: inherit;
}

.icon-cabas:before { content: "\EA01" }
.icon-compte:before { content: "\EA03" }
.icon-facebook:before { content: "\EA04" }
.icon-froid:before { content: "\EA05" }
.icon-instagram:before { content: "\EA06" }
.icon-liberte:before { content: "\EA07" }
.icon-livraison:before { content: "\EA08" }
.icon-service:before { content: "\EA09" }
.icon-telephone:before { content: "\EA0A" }
.icon-arrow-left:before { content: "\EA0C" }
.icon-arrow-right:before { content: "\EA0D" }
.icon-citation:before { content: "\EA0E" }
.icon-arrow-up:before { content: "\EA0F" }
.icon-arrow-down:before { content: "\EA10" }
.icon-cadeau:before { content: "\EA12" }
.icon-decompte:before { content: "\EA13" }
.icon-robot:before { content: "\EA14" }
.icon-zoom1:before { content: "\EA15" }
.icon-zoom2:before { content: "\EA16" }
.icon-password-show:before { content: "\EA18" }
.icon-password-hide:before { content: "\EA19" }
.icon-close:before { content: "\EA1A" }
.icon-aide:before { content: "\EA1B" }
.icon-cuisson:before { content: "\EA1C" }
.icon-preparation:before { content: "\EA1D" }
.icon-corbeille:before { content: "\EA1E" }
.icon-mapmarker:before { content: "\EA1F" }
.icon-cross:before { content: "\EA20" }
.icon-pinterest:before { content: "\EA21" }
.icon-adresse:before { content: "\EA22" }
.icon-fidelite:before { content: "\EA23" }
.icon-parrainage:before { content: "\EA24" }
.icon-formule:before { content: "\EA25" }
.icon-recettes:before { content: "\EA26" }
.icon-clock:before { content: "\EA27" }
.icon-home:before { content: "\EA28" }
.icon-active-star:before { content: "\EA29" }
.icon-star:before { content: "\EA2B" }
.icon-miactive-star:before { content: "\EA2C" }
.icon-coupon:before { content: "\EA2D" }

