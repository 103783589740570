.parrainages{
  .parrainages-header{
    .underline{
      margin-bottom: 3rem;
    }
  }
  .green_block{
    position: relative;
    margin: 1rem auto;
    border: .1rem solid #dee2e6;
    padding: 2rem;
    text-align: center;
    overflow: hidden;
    .detail-parrainage{
      font-size: 1.7rem;
      line-height: 1.4;
      p{
        margin-bottom: 4rem;
      }
      .concept-number{
        text-align: center;
        margin-right: 1rem;
      }
    }
    form{
      margin-top: 4rem;
    }
    .input-effect{
      text-align: center;
    }
    .img-soleil{
      opacity: .5;
      position: absolute;
      right: -25rem;
      top: 0;
      z-index: -1;
    }
    .fb-share-button{
      margin-bottom: 1rem;
      a{
        display: flex;
        align-items: center;
        color: #fff;
        background-color: #3b5998;
        padding: .8rem 1.2rem;
        .icon{
          line-height: 0;
          margin-right: .6rem;
        }
      }
      .bribe-parrainage{
        max-width: 30rem;
        line-height: 1;
        font-size: 1.5rem;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: .6rem;
        p{
          margin-bottom: 0;
        }
      }
    }
  }
  .block-texte{
    font-weight: 700;
    margin-bottom: 5rem;
    .block-texte-titre{
      text-transform: uppercase;
      margin-bottom: 1rem;
    }
    .highlight{
      font-size: 3rem;
    }
    .icon{
      font-size: 3rem;
      margin-right: 1rem;
    }
  }
  .cta-parrainage{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: $breakpoint-md) {
  .parrainages > .row .col-presentation{
    order: 2;
  }
}