.page_panier #contenu,
.page_livraison #contenu,
.page_panier #contenu,
.page_choix-paiement #contenu{
  background-color: #F2F2F6;
}

/* blocs */
.tunnel-container{
  position:relative;
  display: flex;
  padding-bottom: 3rem;
  .h2{
    text-align: left;
  }
}
.tunnel-left{
  margin : 0;
  padding: .5rem;
  width: 55%;
}

.tunnel-right{
  padding: .5rem;
  width: 45%;
  margin: 3rem 0 0;
}

/* tableau panier */
.product_line_total{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding-left: 1.5rem;
  .h3{
    font-size: 3rem;
  }
  .text_italic{
    font-style: italic;
    font-size: 2rem;
  }
}

.btn_container_panier .button-xl{
  font-size: 2rem;
}

.recap_livraison{
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1.5rem;
  .icon{
    color: $color4;
    margin-right: .8rem;
  }
  .highlight{
    font-weight: 700;
  }
  #countdown{
    display: inline-block;
    width: 17.5rem;
    margin-left: 1rem;
  }
}

.remise_fidelite{
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  padding-left: 1.5rem;
  label{
    margin-right: 1.5rem;
  }
  input[type="text"]{
    height: 3.2rem;
    border: none;
    padding-left: .5rem;
  }
  .fidelite_panier{
    text-align: right;
    .highlight{
      font-weight: 700;
    }
  }
}

/* Panier */

/* Colonne de gauche */
.tunnel-left .step-content{
  opacity: .3;
  pointer-events: none;
  margin-top: 1rem;
  &.current{
    opacity: 1;
    pointer-events: auto;
  }
  &.active{
    pointer-events: auto;
  }
  .liv_etape_1,
  .liv_etape_2{
    margin-bottom: 2rem;
  }
}

.page_espace-client .espaceClient_tab,
.page_panier .espaceClient_tab{
  margin-top: 2rem;
  .register_container form{
    display: block;
    line-height: 1.2;
    .h3{
      text-align: left;
      margin-bottom: 1.4rem;
    }
    .form_radio_container{
      margin-bottom: 2.5rem;
      > div em{
        bottom: -1.5rem;
      }
    }
  }
  .shipping-address{
    overflow: hidden;
  }
}

.tunnel-left{
  .white_block{
    position: relative;
    padding: 1rem 3rem .5rem;
  }
  .mode_livraison,
  .choix_mode_paiement{
    header .h3{
      text-transform: uppercase;
      margin-bottom: 1rem;
      p{
        display: inline-block;
      }
    }
  }
  .mode_livraison{
    .h3{
      text-align: left;
      margin-top: 2rem;
      margin-bottom: 2rem;
      &.collapse_listener{
        margin-top: 0;
        margin-bottom: 0;
      }
      &.current{
        color: $color4;
      }
    }
    ul{
      list-style-type: none;
      padding-left: 0;
      margin-bottom: 0;
      li{
        margin: 0;
      }
    }
    .liv_etape_1 button[type="submit"]{
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
    .liv_etape_3{
      padding-bottom: 1.5rem;
    }
    .recap_adresse{
      input{
        height: 5rem;
        margin-bottom: 1.2rem;
        padding-left: 2.5rem;
        width: 100%;
        min-width: 0;
      }
    }
    .choix_transporteur{
      margin-bottom: 1.5rem;
      li{
        margin-bottom: 1rem;
      }
      .description_transporteur{
        font-size: 1.4rem;
        line-height: 1.2;
        max-width: calc(100% - 26rem);
        margin-top: 1rem;
        margin-bottom: 1rem;
        a {
          color: $color2;
        }
        .icon {
          font-size: 1.7rem;
        }
      }
    }
    .recap-mode-liv .icon{
      font-size: 2.2rem;
      line-height: 1;
    }
    .choix_jour_liste{
      display: flex;
      flex-direction: column;
      label{
        justify-content: center;
        margin-right: .5rem;
        line-height: 3.2rem;
        min-height: 3.2rem;
        border-radius: .8rem;
        color: $color1;
        max-width: 40rem;
      }
    }
  }
}

/* Colonne de droite, récap */
.recap_article{
  color: $color1;
  .highlight{
    font-size: 2.2rem;
    font-weight: 700;
    margin-top: .6rem;
    margin-bottom: .6rem;
  }
  p{
    display: inline-block;
  }
  .avis_verif .rating .icon{
    color: $color2;
  }
}

.cta-festif{
  margin-top: 2.6rem;
  a{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    font-weight: 700;
    //max-width: 45rem;
    //line-height: 1;
    //padding-left: 1.5rem;
    //padding-right: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    //animation-name: colorChange;
    //animation-duration: 5s;
    //animation-iteration-count: infinite;
    img{
      width: 6rem;
      margin-right: 1rem;
    }
    p{
      margin-bottom: 0;
      display: inline-block;
    }
    &:hover,
    &:focus{
      color: $color1;
    }
  }
}

@keyframes colorChange {
  0% {
    border-color: $color2;
    background-color: $color2;
  }
  25% {
    border-color: $color4;
    background-color: $color4;
  }
  75%{
    border-color: $color4;
    background-color: $color4;
  }
  to {
    border-color: $color2;
    background-color: $color2;
  }
}

.block_semaine_panier{
  position: relative;
  margin-bottom: 3rem;
  border: .4rem solid $color2;
  background-color: #fff;
  padding: 0 2.5rem 2rem;
  .block_recap_semaine_content{
    position: relative;
    margin-top: 3rem;
    a {
      position: absolute;
      top: 0;
      right: 0;
      + a {
        right: 4.5rem;
      }
      .icon{
        display: block;
        width: 4rem;
        height: 4rem;
        text-align: center;
        font-size: 2.2rem;
        line-height: 2;
        color: #fff;
        background-color: $color4;
        border-radius: 50%;
        transition: background-color .3s;
        &:hover{
          opacity: 1;
          background-color: $color5;
        }
      }
    }
  }
  .button{
    cursor: default;
    margin-bottom: 1.8rem;
  }
  .prix-panier{
    color: $color5;
    margin-left: 1rem;
  }
  .recettes_liste_panier{
    ul{
      padding-left: 0;
      li{
        line-height: 1.2;
        margin-bottom: 1rem;
        &.option-gratuite .icon{
          margin-right: .5rem;
        }
      }
    }
  }
  .recette_prix{
    font-size: 2.2rem;;
    font-weight: 700;
  }
  .info-prix{
    font-style: italic;
    font-size: 1.4rem;
    margin-top: -.6rem;
  }
  .prix-total .info-prix{
    margin-top: -2.6rem;
    font-weight: 400;
  }
}

.choix-formule-container,
.select-robot {
  .erreur-choix-formule{
    display: none;
    color: red;
  }
  .highlight{
    margin-bottom: 1rem;
  }
  .selectbox_formule{
    /*text-align: center;*/


    input {
      //display: none;
      &[type=radio] {
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 50%;
        height: 2rem;
        width: 2rem;
        background-color: transparent;
        border: 2px solid $color17;

        &:checked {
          position: relative;
          border: 2px solid $color4;
          &:before {
            content: '';
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: $color4;
            height: 1rem;
            width: 1rem;
          }
        }
      }
      /*&:checked + label{
        color: #fff;
        background-color: $color1;
        border-color: $color1;
      }*/
    }
    label {
      flex: 1;
      font-family: "Raleway", sans-serif;
      line-height: 1.1;

      color: $color1;
      font-weight: 700;
      border-radius: 2rem;
      padding: .2rem 1.5rem;
      text-align: left;
      margin-bottom: 0;
      cursor: pointer;
      transition: all .3s;
      margin-left: .2rem;
      margin-right: .2rem;
      &:hover {
        border-color: $color1;
      }
      @media (max-width: $breakpoint-sm) {
        font-size: 1.4rem;
      }
      @media (max-width: $breakpoint-xs) {
        font-size: 1.2rem;
      }
    }
    .formule-container {
      &.formule-unique {
        .input-content {
          input {
            display: none;
          }
        }
      }
    }
    /*.formule-container {
      input{
        &:checked + label{
          color: #fff;
          background-color: $color3;
          border-color: $color3;
        }
      }
      label{
        color: $color3;
        border: .1rem solid #CECECE;
        background-color: #fff;
        &:hover{
          border-color: $color3;
        }
      }

    }*/
  }
  .select-formule{
    label{
      /*max-width: 30rem;
      width: 100%;*/
    }
    .formule-desc{
      max-width: none;
      line-height: 1.1;
      margin-bottom: 2rem;
      margin-left: auto;
      margin-right: auto;
      margin-top: .5rem;
      padding-left: 3rem;
      ul {
        padding:0;
        list-style: inside;
      }
      @media (max-width: $breakpoint-sm) {
        font-size: 1.4rem;
      }
      @media (max-width: $breakpoint-xs) {
        font-size: 1.2rem;
      }
    }
  }

  .formule-container {
    position: relative;
    display: inline-block;
    margin-bottom: 2rem;
    width: 100%;

    border: .2rem solid $color17;
    border-radius: .8rem;
    padding-top: 2rem;
    cursor: pointer;


    .input-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      > input {
        margin-top: 0;
        margin-left: 1rem;
      }

      .popin-aide-panier{
        margin-top: -.5rem;
        margin-left: .5rem;
        margin-right: 1rem;
        font-size: 2.6rem;
        .icon {
          display: flex;
        }
      }
    }
    &.formule-checked {
      border: .2rem solid $color1;
    }
    .item-favori {
      position: absolute;
      top: -1.5rem;
      right: -1.5rem;
      display: flex;
      align-items: center;

      padding: .7rem 1rem;
      border: .1rem solid $color4;
      border-radius: .8rem;
      background-color: $color16;
      color: $color1;
      .icon {
        display: flex;
        color: $color4;
        margin-right: .5rem;
      }

    }
  }


}

.select-robot {
  text-align: center;
  margin-bottom: 2rem;
  .highlight{
    margin-bottom: 0;
    cursor: pointer;
    .icon{
      line-height: 1;
      font-size: 1.5rem;
      transition: transform .3s;
    }
    &.expanded .icon{
      transform: rotate(90deg);
    }
  }
  .selectbox_formule {
    margin-top: 1rem;
    input[type="radio"] {
      display: none;
      &:checked + label{
        color: #fff;
        background-color: $color1;
        border-color: $color1;
      }
    }
    label {
      max-width: 30rem;
      width: 100%;
      padding: .5rem 1.5rem;
      text-align: center;
      border: .1rem solid #cecece;
      margin-bottom: 1rem;
    }
  }
}

.prix-total-recap{
  font-size: 2.8rem;
  font-weight: 700;
  margin-top: -.8rem;
  &::after{
    content: "";
    display: block;
    height: .4rem;
    width: 12rem;
    background-color: $color2;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.2rem;
  }
}

.ligne-remise-avis{
  display: flex;
  align-items: center;
}

#panierCodesRemises{
  width: 100%;
  margin-bottom: 2rem;
  label{
    font-size: 1.2rem;
    margin-bottom: 0;
    margin-right: 1rem;
  }
  input[type="text"]{
    height: 4rem;
    width: 19rem;
    padding-left: .8rem;
    &::placeholder{
      color: #C9C6C5;
    }
  }
  input[type="submit"]{
    font-size: 1.6rem;
    padding: .7rem 1.7rem;
    margin-bottom: .5rem;
    cursor: pointer;
  }
  .texte{
    font-size: 1.4rem;
    line-height: 1.2;
  }
}


.marker-custom{
  list-style-type: none;
  li::before{
    content: "";
    display: inline-block;
    width: .8rem;
    height: .8rem;
    background-color: $color4;
    border-radius: 50%;
    margin-right: .8rem;
    margin-bottom: .2rem;
  }
}

.adresse_recap_panier{
  margin-bottom: 2rem;
}

.rassurances_panier{
  text-align: center;
  .sep-horizontal{
    margin-top: 2rem;
    margin-bottom: 2rem;
    + div{
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
  }
}

/**********/
/* TUNNEL */
/**********/

#panier .alert-danger{
  padding: 2rem 1rem;
  .toast-connect{
    color: $color2;
    font-weight: 700;
  }
}

.bas_panier input[name="transporteur"],
.bas_panier input[name="modepaiement"]{
  display: none;
}

.choix_date_livraison ul{
  display: flex;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  input{
    display: none;
  }
}

#adresse_liv .collapse_listener .radiobox{
  display: flex;
  input{
    margin-right: .8rem;
  }
}

.list_5_cols{
  column-count: 5;
  list-style-type: disclosure-closed;
  line-height: 1.8;
  column-gap: 6rem;
  margin-top: 3rem;
}

#contenu .mode_livraison{

  .transport-relais header{
    form{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 1.2rem;
      .champ_relais_livraison{
        width: 50%;
      }
      #code_postal,
      #ville{
        display: inline-block;
        height: 4.2rem;
        line-height: 4.8rem;
        margin-left: 2rem;
        margin-right: 1rem;
        padding-left: 8px;
      }
      #code_postal{
        max-width: 20rem;
        text-align: center;
        padding-left: .2rem;
      }
      #ville{
        max-width: 30rem;
      }
      button[type="submit"]{
        margin-left: auto;
        margin-top: 1rem;
        margin-right: auto;
        padding: 0.2rem 1.2rem;
        font-size: 2rem;
      }
    }
  }


  .custom_error{
    color: red;
    text-transform: uppercase;
    font-weight: 700;
  }
  .nav-tabs{
    justify-content: center;
    border-bottom: 0;
    .nav-item{
      position: relative;
      z-index: 1;
      margin: 0 1rem -.2rem;
      padding: 1rem 5rem;
      border-bottom: none;
      border-color: #e9ecef #e9ecef #dee2e6;
      &:hover{
        border-color: #dee2e6;
      }
      &.active{
        color: #fff;
        background-color: $color2;
        opacity: 1;
      }
    }
  }
  .tab-content .tab-pane{
    width: 50rem;
    .h2{
      color: $color3;
      text-align: left;
      margin-bottom: 1.2rem;
    }
    .choix_date_livraison + .h2{
      margin-top: 2rem;
    }
    .header-zone{
      text-align: center;
      margin: 15px 0 10px 0;
      font-weight: bold;
      color: #FE721D;
    }
  }
  .green_block{
    border: 1px solid #dee2e6;
    padding: 2rem;
    text-align: center;
    margin-bottom: 2rem;
    h2{
      margin-bottom: 1.5rem;
    }
  }
  .green_block.big_block{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 25rem;
    border: .1rem solid #dee2e6;
    padding: 2rem;
    text-align: center;
    h2{
      margin: 0;
      padding-top: 1rem;
    }
    .collapse_box .contain{
      margin: 0;
    }
    h3.collapse_listener{
      margin: 0;
    }
    .h3{
      margin: 0;
      padding-top: 2rem;
      padding-bottom: 1rem;
    }
    .choix_date_livraison{
      > li{
        margin-bottom: 1rem;
        &::after{
          content: "";
          display: block;
          width: 15rem;
          height: .1rem;
          margin: 1rem auto 0;
          background-color: $color3;
        }
      }
      ul{
        flex-wrap: wrap;
        margin: 0 -1rem;
        justify-content: center;
        li{
          margin-top: 1rem;
          margin-bottom: .5rem;
        }
      }
    }
    .transporteur-options{
      padding-left: 2rem;
      max-width: 50rem;
      margin: 0 auto;
    }
  }
  .chronopost_header{
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  #depositaires,
  #chronopost_relais{
    max-height: 75rem;
    overflow-x: auto;
    z-index: 0;
    margin-bottom: 1rem;
    > span {
      display: inline-block;
      margin-bottom: .5rem;
      font-size: 1.5rem;
    }
  }
  #map{
    position: relative;
    min-height: 45.8rem;
    max-height: 75rem;
    .leaflet-control-zoom{
      border: none;
      a{
        display: block;
        margin: 0;
        &:hover{
          background-color: $color3;
        }
      }
    }
    .leaflet-popup{
      width: 35rem;
      height: 26rem;
      .leaflet-popup-content-wrapper{
        width: 35rem;
        height: 26rem;
        .leaflet-popup-content{
          width: 35rem!important;
          height: 26rem;
          .h4{
            font-size: 1.4rem;
            margin: 0 0 1rem;
          }

        }
      }
    }
    a.leaflet-popup-close-button{
      margin: 0;
      padding: 0;
    }
  }
  .selected_relai{
    background-color: $color3;
    border-color: $color3;
    cursor: default;
    padding: .8rem;
    font-size: 1.4rem;
    &:hover{
      color: #fff;
      border-color: $color3;
    }
  }

  .select_relai_bouton{
    border-color: $color4;
    background-color: $color4;
    padding: .8rem;
    font-size: 1.4rem;
    &:hover{
      color: $color2;
      border-color: $color2;
      background-color: #fff;
    }
  }
  .transport-relais #map .leaflet-popup,
  .transport-relais #map .leaflet-popup .leaflet-popup-content-wrapper,
  .transport-relais #map .leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content{
    width: 28rem !important;
    height: 14rem;
  }
  #transport-depositaire #map .leaflet-popup,
  #transport-depositaire #map .leaflet-popup .leaflet-popup-content-wrapper,
  #transport-depositaire #map .leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content,
  .transport-relais.choix-entreprise #map .leaflet-popup,
  .transport-relais.choix-entreprise #map .leaflet-popup .leaflet-popup-content-wrapper,
  .transport-relais.choix-entreprise #map .leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content{
    height: 26rem;
  }

  #depositaires > label,
  #chronopost_relais > label{
    border: 2px solid $color4;
    width: 100%;
    margin-bottom: .6rem;
    padding: .4rem 1rem;
    cursor: pointer;
    &.checked{
      border-color: $color3;
      cursor: default;
    }
    .chronopost_radio,
    .depositaire_radio{
      display: none;
    }
    .lignepr{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .chronopost_logo{
        max-width: 10rem;
      }
      .chronopost_info{
        font-size: 1.2rem;
        line-height: 1.1;
        padding-right: 6rem;
        width: 30rem;
      }
      .depositaire_info{
        font-size: 1.2rem;
        line-height: 1.1;
      }
    }
  }
  #chronopost_relais {
    .chronopost_distance {
      position: absolute;
      top: .6rem;
      right: 3rem;
      color: $color2;
    }
    .selected_relai{
      margin-top: 3rem;
      line-height: 1;
    }
  }
  .back_c{
    display: none;
  }
  .choix_horaire{
    .h3{
      margin-top: 0;
      margin-bottom: 2rem;
    }
    li label{
      display: block;
      max-width: 35rem;
      margin-left: auto;
      margin-right: auto;
      padding: 0;
      text-align: center;
      font-size: 1.5rem;
      line-height: 5rem;
      cursor: pointer;
      margin-bottom: 1rem;
      width: 100%;
      background-color: #edf6ef;
      border: .1rem solid #64b271;
      &:hover{
        border-color: $color2;
      }
    }
    li input:checked + label{
      background-color: rgba(230,166,28,.1);
      border-color: $color2;
    }
    .liste-creneaux{
      margin-top: .4rem;
      li label{
        line-height: 4.2rem;
      }
    }
  }
}

.choix_mode_paiement .liste-paiement{
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  li{
    width: 100%;
  }
  .li_mp_pay{
    order: 1;
  }
  label{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-top: 0;
    margin-bottom: 0;
    max-width: none;
  }
  svg,
  img{
    width: 16rem;
    max-height: 6rem;
    display: inline-block;
    object-fit: contain;
  }
  .nommp{
    font-weight: 700;
  }
}

#espaceClientInt .pg-payment-method {
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid #64b271;
  border-radius: 0;
  color: #64b271;
  font-weight: 300;
  font-size: 2.3rem;
  padding: .8rem 1rem;
  margin: 10px 10px 14px;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
  transition: all .2s ease-in;
  &:hover {
    box-shadow: none;
    color: #e6a61c;
    background-color: #fff;
    border: 1px solid #e6a61c;
    text-decoration: none;
    opacity: 1;
    outline: 0;
  }

}

.paygreen-methods-container{
  .pg-payment-method{
    &::before{
      display: flex;
      justify-content: center;
      padding: 1.6rem;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      color: #fff;
      background-color: #64b271;
    }
    &::after{
      content: "";
      display: flex;
      justify-content: center;
      height: 100%;
      width: 12rem;
      margin: 2rem auto 1rem;
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 9;
    }
  }
  .pg-payment-method-bank_card{
    &::before{
      content: 'CB';
    }
    &::after{
      background-image: url('../img/theme/cb.webp');
      aspect-ratio: 1024/503;
    }
  }
  .pg-payment-method-swile{
    &::before{
      content: 'Swile';
    }
    &::after{
      background-image: url('../img/theme/swile.webp');
      aspect-ratio: 600/202;
    }
  }
  .pg-payment-method-conecs{
    &::before{
      content: 'Conecs';
    }
    &::after{
      background-image: url('../img/theme/conecs.webp');
      aspect-ratio: 600/202;
    }
  }
}

#content-paiement{
  max-width: 80rem;
  margin: 1em auto;
  border: 1px solid #dee2e6;
  padding: 20px;
  h1{
    font-size: 2.8rem;
    margin-top: 0;
    margin-bottom: 2rem;
  }
  .highlight{
    font-size: 2.2rem;
    font-weight: 700;
    color: $color2;
  }
  .form-authorize{
    display: flex;
    > input{
      height: 5.4rem;
      padding-left: 1rem;
      font-size: 1.8rem;
      outline: none;
    }
  }
  .button.button-gris {
    background-color: #aba9a9;
    color: #fff;
    border: 1px solid #aba9a9;
    font-size: 1.8rem;
    padding: .5rem 1.5rem;
    margin-top: 2rem;
    align-self: baseline;
    &:hover {
      background-color: #fff;
      color: #aba9a9;
    }
  }
}

#paypal-button-container{
  text-align: center;
  margin-top: 4rem;
}

#loading-paiement{
  margin-bottom: 3rem;
}

@media (max-width: $breakpoint-xl) {
  .step-content .espace_client_login .espaceClient_tab .tab-content form{
    flex-wrap: wrap;
    .inputs-c{
      margin-bottom: 1rem;
    }
  }
  .block_formule_panier{
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  #contenu .mode_livraison .transport-relais header form #ville{
    max-width: 25rem;
  }
  .ligne-remise-avis{
    flex-direction: column;
    margin-bottom: 1rem;
  }
}

@media (max-width: $breakpoint-lg) {
  #espaceClientInt.mode_livraison #chronopost_relais > label .lignepr .chronopost_info{
    padding-right: 4rem;
  }
  .block_formule_panier {
    padding: 3rem 2.5rem 2rem;
    margin-bottom: 0;
    .recap-total{
      flex-wrap: wrap;
    }
  }
  .block_semaine_panier{
    .prix-panier {
      margin-left: 0;
      display: block;
      margin-bottom: 1rem;
      margin-top: -0.5rem;
    }
    .block_recap_semaine_content .text-center{
      text-align: left!important;
    }
  }
  #contenu .mode_livraison{
    .transport-relais header form #ville{
      max-width: 20rem;
    }
    #chronopost_relais .chronopost_distance{
      right: 15rem;
    }
  }
  .transport-relais.choix-entreprise .label-bloc-depositaire .select_relai_bouton.tablet,
  #transport-depositaire .label-bloc-depositaire .select_relai_bouton.tablet,
  #chronopost_relais .label-bloc-transporteur .select_relai_bouton.tablet{
    display: block;
  }
  .choix_mode_paiement{
    .liste-paiement label {
      flex-wrap: wrap;
      justify-content: center;
      .nommp{
        width: 100%;
        text-align: center;
      }
    }
    .btn-choix-paiement .button{
      padding-left: 2rem;
      padding-right: 2rem;
      font-size: 2rem;
    }
  }
}

@media (max-width: $breakpoint-md) {
  .tunnel-container{
    flex-wrap: wrap;
    .tunnel-left,
    .tunnel-right{
      width: 100%;
    }
  }
  .product_line_total{
    flex-wrap: wrap;
  }
  .btn_container_panier{
    width: 100%;
    text-align: center;
    margin-top: 1.5rem;
    a{
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
    }
  }
  .avis_verif_panier{
    margin-top: 2rem;
    .avis_verif.avis_verif_horizontal img{
      margin-right: 3rem;
    }
  }
  #espaceClientInt.mode_livraison #chronopost_relais > label .lignepr{
    justify-content: space-around;
  }
  #contenu .mode_livraison #depositaires{
    max-height: 46rem;
    margin-bottom: 2rem;
  }
  #contenu .mode_livraison #chronopost_relais,
  #contenu .mode_livraison #depositaires{
    margin-bottom: 2rem;
  }
}

@media (max-width: $breakpoint-sm) {
  #panier-recap{
    padding-bottom: 1rem;
  }
  .remise_fidelite{
    flex-wrap: wrap;
    #panierCodesRemises label{
      width: 100%;
    }
  }
  #panier .mobile-hidden{
    display: none;
  }
  .block_semaine_panier{
    header .header_semaine{
      flex-wrap: wrap;
    }
  }
  .list_5_cols {
    column-count: 3;
    column-gap: 1rem;
  }
  #contenu .mode_livraison #map{
    display: none;
  }
  .transport-relais.choix-entreprise .label-bloc-depositaire .select_relai_bouton.tablet,
  #transport-depositaire .label-bloc-depositaire .select_relai_bouton.tablet,
  #chronopost_relais .label-bloc-transporteur .select_relai_bouton.tablet{
    display: none;
  }
  .transport-relais.choix-entreprise .label-bloc-depositaire .select_relai_bouton.mobile,
  #transport-depositaire .label-bloc-depositaire .select_relai_bouton.mobile,
  #chronopost_relais .label-bloc-transporteur .select_relai_bouton.mobile{
    display: block;
  }
  .tunnel-left .step-content{
    margin-top: 0;
  }
  .tunnel-left .mode_livraison{
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
  }
}

@media (max-width: $breakpoint-xs) {
  .step li{
    width: 100%;
    margin: 0 0 .1rem;
    font-size: 13px;
    height: 35px;
  }
  .step .white_arrow_before,
  .step .white_arrow_after,{
    display: none;
  }
  .step li .step_panier::before,
  .step li .step_panier::after,
  .step li.active .step_panier::before,
  .step li.active .step_panier::after{
    content: none;
  }
  .step li .step_panier .marginleft {
    margin-left: 0;
  }

  .tunnel-left .mode_livraison .h3{
    margin-top: 1rem;
  }

  #contenu .mode_livraison .transport-relais header form{
    flex-direction: column;
    align-items: center;
    text-align: center;
    .champ_relais_livraison{
      width: 100%;
    }
    #code_postal,
    #ville{
      display: block;
      width: 28rem;
      max-width: 100%;
      margin-bottom: 1rem;
      margin-left: auto;
      margin-right: auto;
      padding-left: 8px;
      text-align: left;
    }
  }
  #contenu .mode_livraison #chronopost_relais .chronopost_distance{
    right: 3rem;
  }
  #panier-recap{
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .product_line_total,
  .recap_livraison,
  .remise_fidelite{
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
  #panier-recap{
    margin-bottom: 1rem;
  }
  #panier h2{
    margin-bottom: 1rem;
  }
  .product_line_total{
    .h3{
      line-height: 1.2;
      width: 100%;
      .desktop_text{
        display: none;
      }
    }
    .text_italic{
      font-size: 1.6rem;
      line-height: 1;
      width: 100%;
      text-align: center;
    }
  }
  .remise_fidelite{
    #brd_code{
      width: calc(100% - 5rem);
    }
    input[type="submit"]{
      min-width: 0;
    }
    .fidelite_panier{
      text-align: left;
    }
  }
  .block_semaine_panier{
    padding: 0 1rem 2rem;
    header{
      padding: .5rem;
      .highlight{
        font-size: 1.4rem;
      }
      .header_semaine .highlight{
        font-size: 1.6rem;
      }
    }
    .button{
      margin-bottom: .8rem;
    }
    .block_recap_semaine_content{
      a{
        display: inline-block;
        position: static;
      }
      .recap-recette-choisie{
        line-height: 1;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }
    .recettes_liste_panier{
      flex-wrap: wrap;
      margin-bottom: 0;
      ul{
        width: 100%;
        .highlight a{
          line-height: 1;
        }
      }
      .recette_prix{
        text-align: right;
      }
    }
  }
  .recap_article .cta-festif{
    margin-left: -1.1rem;
    margin-right: -1.1rem;
  }
  .block_formule_panier .recap-total{
    justify-content: center;
    text-align: center;
    .prix-total-recap{
      margin-bottom: 1rem;
    }
    #panierCodesRemises{
      margin-bottom: 0;
      width: 100%;
      label{
        display: block;
        margin-top: 1rem;
        margin-bottom: .5rem;
      }
    }
    input[type="text"],
    input[type="submit"]{
      width: 100%;
      max-width: 25rem;
    }
    input[type="submit"]{
      margin-bottom: 0;
      margin-top: .8rem;
    }
  }
  #espaceClientInt .choix_date_livraison ul{
    flex-wrap: wrap;
    li{
      width: 100%;
      margin-top: .5rem;
      margin-bottom: .5rem;
      .custom_green_button{
        margin: 0;
        width: 100%;
        height: 5.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
    }
  }
  .espace_client .choix_mode_paiement li label,
  .espace_client .choix_transporteur li label{
    padding: .8rem 1rem;
    text-align: center;
    line-height: 2;
  }
  .espace_client .choix_mode_paiement li label svg{
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .espace_client .choix_mode_paiement{
    .green_block{
      padding: .5rem;
    }
    h2{
      font-size: 1.8rem;
      margin-top: .2rem!important;
      margin-bottom: 1.5rem!important;
    }
    li label .paiement_cb_image{
      display: inline-block;
      width: 12rem;
      + .nommp{
        display: inline-block;
      }
    }
  }
  #panier-recap.bas_panier.green_block{
    padding: .8rem;
  }
  .accept_cgv_c{
    display: flex;
    align-items: center;
    text-align: left!important;
    input{
      margin-right: 1rem;
      margin-top: .3rem;
    }
    label{
      font-size: 1.4rem;
      margin-bottom: 0;
    }
  }
  #espaceClientInt .accept_cgv_c a{
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.2;
  }
  .espace_client .choix_transporteur{
    ul{
      margin-bottom: 0;
      max-width: 34rem;
      margin-left: auto;
      margin-right: auto;
      li{
        position: relative;
        .transporteur-options{
          padding-left: 0;
        }
        label{
          font-size: 1.4rem;
          line-height: 1.2;
          display: flex;
          flex-wrap: wrap;
          text-align: center;
          align-items: center;
          justify-content: center;
          .icon{
            position: absolute;
            top: .8rem;
            left: 1rem;
            font-size: 3rem;
            line-height: 1;
          }
          .nomtr{
            display: block;
            margin-right: 0;
          }
          .cout_tr{
            color: $color2;
            float: none;
            width: 100%;
            line-height: 1.2;
          }
        }
      }
    }
  }
  .espace_client .choix_transporteur ul li .new_adress label{
    display: block;
    text-align: left;
  }
  .espace_client .choix_transporteur ul li .new_adress .radio-box label {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 5rem;
  }
  #espaceClientInt.mode_livraison #chronopost_relais > label .lignepr .chronopost_info{
    padding-right: 0;
  }
  #contenu .mode_livraison{
    .green_block.big_block{
      padding: 0.5rem;
      .choix_date_livraison{
        + .h2{
          font-size: 2rem;
        }
        ul{
          flex-wrap: nowrap;
          justify-content: space-around;
          margin: 0;
          li{
            width: calc(50% - 1rem);
          }
        }
      }
    }
    .transporteur-options{
      padding-left: 0!important;
    }
    .nav-tabs{
      justify-content: space-between;
      .nav-item{
        width: calc(50% - 5px);
        text-align: center;
        padding: 1rem 0;
        margin: 0 0 -.2rem;
      }
    }
  }
  .recap_livraison{
    margin-top: 1rem;
    .icon{
      margin-right: .5rem;
    }
    #countdown{
      width: auto;
      margin-left: 0;
    }
    .mobile-block{
      font-size: 1.4rem;
      line-height: 1.1;
    }
  }
  .remise_fidelite{
    #panierCodesRemises{
      margin-top: 1rem;
      margin-bottom: 0!important;
      label{
        font-size: 1.4rem;
        line-height: 1.1;
        margin-bottom: 0;
      }
      input[type="submit"]{
        margin-top: .5rem;
        margin-bottom: .5rem;
      }
    }
    .fidelite_panier{
      font-size: 1.4rem;
      line-height: 1.1;
      margin-top: .8rem;
      .highlight{
        margin-top: .4rem;
      }
    }
  }
  .btn_container_panier_sticky{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color3;
    padding-top: .8rem;
    padding-bottom: .5rem;
    z-index: 1;
    text-align: center;
  }
  .btn_container_panier_sticky{
    .button,
    a{
      text-transform: none;
      font-size: 2rem;
      margin: 0 1rem .5rem!important;
    }
  }

  #contenu .mode_livraison #depositaires,
  #contenu .mode_livraison #chronopost_relais{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &.choix-horaires{
      display: block;
    }
    > label{
      line-height: 1.2;
      font-size: 1.4rem;
      width: calc(50% - .5rem);
      margin-bottom: 1rem;
      padding-left: .5rem;
      padding-right: .5rem;
      .lignepr{
        flex-wrap: wrap;
        .chronopost_info{
          width: 100%;
        }
      }
      &.checked .selected_relai{
        margin: .5rem auto;
      }
    }
  }

  .tunnel-left .step-2{
    .button{
      margin-bottom: 2rem;
    }
  }

  #contenu .mode_livraison{
    .tab-content .tab-pane .header-zone{
      margin: .8rem 0 0;
    }
    .green_block{
      padding: 0 1rem;
      margin-bottom: 1.2rem;
    }
    .h2{
      font-size: 1.8rem;
      margin-top: 1.2rem;
      margin-bottom: 1.2rem;
    }
    .button{
      margin: 4px auto 0.2rem;
    }
    .chronopost_header{
      margin-bottom: 1rem;
    }
  }
  #contenu .mode_livraison{
    #code_postal,
    #ville{
      max-width: 100%;
      margin-left: 0;
    }
    #chronopost_relais > span,
    #depositaires > span{
      width: 100%;
    }
    .liv_etape_1{
      ul{
        margin-bottom: 0;
      }
    }
    .double-btn-c{
      text-align: center;
    }
  }
  #espaceClientInt.mode_livraison #chronopost_relais{
    > label .lignepr .chronopost_info{
      margin-left: 0;
      strong{
        display: inline-block;
        max-width: calc(100% - 4rem);
      }
    }
    .chronopost_distance{
      right: 2.5rem;
    }
  }
  #panier .alert-danger{
    line-height: 1;
  }
  .tunnel-left .mode_livraison{
    .liv_etape_3 {
      padding-bottom: 0;
    }
    .choix_jour_liste{
      justify-content: center;
    }
  }
  #panierCodesRemises{
    width: auto;
    input[type="submit"]{
      margin-top: 0;
    }
  }
  .tunnel-left .mode_livraison .choix_transporteur li label{
    flex-wrap: wrap;
    padding-top: 1rem;
    padding-bottom: 1rem;
    .description_transporteur{
      width: 100%;
      max-width: none;
    }
  }
  .choix_mode_paiement .white_block {
    padding: 1rem!important;
    li label{
      padding-left: .5rem;
      padding-right: .5rem;
    }
    .nommp > div{
      margin-left: .5rem;
      margin-right: .5rem;
      img{
        max-height: 4rem;
      }
    }
  }
}
