/* Commun a toutes les news */
.body_news h1{
  margin-bottom: 2rem;
}

.body_news .img_news a{
  position: relative;
  display: block;
}

.body_news .img_news .date{
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  color: #fff;
  background-color: var(--orange);
  padding: .4rem .8rem;
  line-height: 16px;
  z-index: 1;
}

/* affichage des tags */
.body_news ul.tags{
  list-style: none!important;
  display: flex;
  padding-left: 0;
}

.body_news ul.tags li{
  display: inline-block;
  margin: 0 0.3rem 1rem;
  padding: 0.1rem 0.4rem;
  border: solid 1px var(--orange);
  font-size: 1.2rem;
}

.body_news ul.tags li a{
  color: #fff;
  line-height: 20px;
}

/* Specifique a la news current */
.current_news .current_news_text{
  display: flex;
  align-items: center;
  height: 100%;
}

/*slider news avec vignettes et slider produits avec vignettes */
.current_news .main_image{
  padding-left: 0;
}

.current_news .main_image img{
  height : 40rem;
  object-fit: cover;
  width: 100%;
  -moz-box-shadow: 6px 6px 6px 0 #cccccc;
  -webkit-box-shadow: 6px 6px 6px 0 #cccccc;
  -o-box-shadow: 6px 6px 6px 0 #cccccc;
  box-shadow: 6px 6px 6px 0 #cccccc;
  filter: progid:DXImageTransform.Microsoft.Shadow(color=#cccccc, Direction=135, Strength=6);
}

.current_news .main_image_slider{
  display: none;
}

.current_news .main_image_noslider{
  display: flex;
  height: 100%;
  align-items: center;
}

.current_news .main_image_noslider li{
  width: 100%;
}

.current_news .main_image_noslider .img_defaut_container{
  position: relative;
}

.current_news .main_image_noslider img.img_defaut{
  object-fit: contain;
  background-color: #444;
}

.current_news #lst_vignette{
  margin: .5rem -.2rem 1rem;
}

.current_news #lst_vignette a{
  display : inline-block;
  margin : 0 .2rem;
}

.current_news #lst_vignette a img{
  width : 6.3rem;
  height: 6.3rem;
  object-fit: cover;
}

/* Spe aux listing */
.newslist article{
  display: flex;
}

.newslist section,
.newslist footer{
  color: #fff;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.newslist footer {
  text-align: center;
}

.newslist .card_news {
  -moz-box-shadow: 6px 6px 6px 0 #cccccc;
  -webkit-box-shadow: 6px 6px 6px 0 #cccccc;
  -o-box-shadow: 6px 6px 6px 0 #cccccc;
  box-shadow: 6px 6px 6px 0 #cccccc;
  filter: progid:DXImageTransform.Microsoft.Shadow(color=#cccccc, Direction=135, Strength=6);
  background-color: var(--hard-dark);
  margin-bottom: 2rem;
}

.newslist img {
  display: block;
  width: 100%;
  height: 36rem;
  object-fit: cover;
  background-color: #444;
}

.newslist img.img_defaut {
  object-fit: contain;
  padding-left: 3rem;
  padding-right: 3rem;
}

.newslist h3 a{
  display: block;
  height: 8.5rem;
  overflow: hidden;
  padding-top: 2rem;
  margin-bottom: 1.5rem;
  color: var(--orange);
}

.newslist h3 a:hover{
  color: var(--dark-grey);
}

.newslist .button{
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.newslist .news_txt{
  height: 9rem;
  line-height: 1.4;
  overflow: hidden;
}

/* Spe au listing avec 3 news sur la meme ligne */
.newslist .medium_list_news img {
  height: 25rem;
}
