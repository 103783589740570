.fidelite .green_block{
  padding-top: 5rem;
  padding-bottom: 4rem;
  h2{
    margin-top: 0;
  }
  .container{
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.fidelite .container{
  margin-top: 3rem;
  margin-bottom: 3rem;
  .fidelite_card_c{
    display: flex;
    flex-direction: column;
    justify-content: center;
    h3{
      font-size: 2.2rem;
      margin-bottom: 2rem;
      text-align: left;
    }
    > div + div{
      margin-top: 5rem;
    }
  }
  .fidelite_image_c{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    img{
      max-height: 45rem;
    }
  }
}

@media (max-width: $breakpoint-md) {
  .fidelite .green_block{
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

@media (max-width: $breakpoint-sm) {
  .fidelite{
    .green_block {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    .fidelite_img_c{
      display: none;
    }
    .container {
      margin-top: 0;
      margin-bottom: 2rem;
      .fidelite_card_c {
        text-align: center;
        h3 {
          text-align: center;
        }
        > div {
          margin-top: 1.5rem;
        }
        > div + div {
          margin-top: 1.5rem;
        }
      }
    }
  }
}