.fancybox-container {
	b, strong {
		font-weight: bold;
	}
}

body .skeepers-widget-wrapper-60c04e32-951b-4d7c-a040-6f2c55276418 {
  z-index: 1003 !important;
}

#hello {
	display: none;
}

body .skeepers-widget-wrapper-60c04e32-951b-4d7c-a040-6f2c55276418 {
  top: 170px !important;
  z-index: 1003 !important;

  @media (max-width: $breakpoint-sm) {
    display: none !important;
  }

}
.page_home .skeepers-widget-wrapper-60c04e32-951b-4d7c-a040-6f2c55276418 {
  @media (max-width: $breakpoint-sm) {
    display: block !important;
    position: fixed;
    top: 60px !important;
  }
}

.page_cms .skeepers-widget-wrapper-60c04e32-951b-4d7c-a040-6f2c55276418 {
  top: 170px !important;
}

.header_top {
  .line1,
  .line1-bis {
    .container{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 3.6rem;
      transition: all 0s;
    }
    a{
      color: #fff;
      &:hover,
      &:focus{
        color: $color4;
      }
      .icon{
        line-height: 1;
        color: $color4;
        font-size: 1.6rem;
        margin-right: .2rem;
      }
    }
    p{
      color: #fff;
      position: relative;
      z-index: 1;
      font-size: 1.4rem;
      margin-bottom: 0;
      line-height: 1.1;
    }
    .click-tel{
      margin-left: 4.5rem;
    }
  }
  .line1{
    background-color: $color1;
  }
  .line1-bis{
    display: block;
    background-color: $color1;
  }
  .line2{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 7.5rem;
    transition: all 0s;
    #logo{
      width: 30rem;
      margin-top: -2rem;
      margin-left: 0;
      img{
        width: 100%;
        height: auto;
      }
      .baseline{
        display: block;
        transition: all 0s;
        font-family: 'Raleway', sans-serif;
        font-weight: 300;
        color: $color1;
        font-size: 1.6rem;
        letter-spacing: .11rem;
      }
    }
    .panier_link{
      font-weight: 700;
      + .panier_link{
        margin-left: 2rem;
      }
      &.current{
        cursor: default;
        color: #fff;
        background-color: #781629;
        border-color: #781629;
        &.button-color12{
          background-color: #a0a02e;
          border-color: #a0a02e;
        }
      }
    }
    .icon-compte{
      font-size: 3rem;
      margin-left: auto;
      margin-right: auto;
      transition: color .3s;
    }
    .isLogged{
      line-height: 1;
      margin-left: auto;
    }
    .noLogged .icon-compte{
      display: none;
    }
    .button-icon{
      margin-left: 2rem;
    }
  }
}

.panier{
  display: flex;
  align-items: center;
  .client_header_c{
    position: relative;
    margin-right: 1rem;
    margin-left: 1rem;
    .espace_client_link{
      position: relative;
      display: flex;
      align-items: center;
      .texte_connexion{
        min-width: 13rem;
        text-align: center;
        font-size: 1.3rem;
        line-height: 1.1;
        padding-left: .8rem;
        padding-right: .8rem;
      }
      &.not_connected{
        .texte_connexion {
          font-size: 1.6rem;
          text-transform: none;
        }
      }
    }
    .icon-cross{
      position: absolute;
      right: 1.2rem;
      top: -.2rem;
      display: block;
      width: 2rem;
      height: 2rem;
      text-align: center;
      line-height: 2.5;
      font-size: .8rem;
      color: #fff;
      background-color: $color2;
      border: 1px solid $color2;
      border-radius: 50%;
      &:hover{
        color: $color2;
        background-color: #fff;
      }
    }
  }
  .header_panier_c{
    position: relative;
    margin-right: 1.5rem;
    .icon-cabas-v2{
      font-size: 3.5rem;
      color: $color1;
      line-height: 1;
      transition: color .3s;
      &:hover{
        color: $color2;
      }
    }
    .text-cabas{
      font-family: 'Raleway', sans-serif;
      position: absolute;
      top: .2rem;
      right: -1.5rem;
      display: block;
      height: 2rem;
      width: 2rem;
      color: #fff;
      background-color: $color2;
      border-radius: 50%;
      text-align: center;
      line-height: 1.7rem;
    }
  }
}

/* Gestion alternance menu / Step tunnel */
#header .header-tunnel{
  display: none;
}
#article,
#panier,
#livraison,
#choix-paiement{
  .header-tunnel{
    display: flex;
  }
  .header_top .line2{
    height: 12rem;
    #logo{
      width: 20rem;
      margin-left: 7rem;
      img{
        width: 29rem;
        height: auto;
        transition: all 0s;
      }
      .baseline{
        font-size: 1rem;
        letter-spacing: .1rem;
      }
    }
  }
  #menu_launcher {
    display: block;
  }
  #primary_nav_wrap{
    display: none;
  }
  .espace_client_link .texte_connexion{
    display: block;
  }
}

/* Menu principal */
#primary_nav_wrap,
.header-tunnel {
  width: calc(100% - 40rem);
}

#primary_nav_wrap {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul.first_level {
    display: flex;
    justify-content: center;
    align-items: center;
    & > li {
      display: flex;
      align-items: center;
      height: 100%;
      margin: 0 2.5rem;
      cursor: pointer;
      padding: 2.5rem 0;
    }
  }

  .js-link {
      text-transform: uppercase;
      color: #fff;
      font-size: 1.4rem;
  }
}

#primary_nav_wrap .second_level {
  position: absolute;
  z-index: 2;
  top: 100%;
  left: 50%;
  background-color: #fff;
  color: $color1;
  cursor: initial;
  min-width: 30rem;
  opacity: 0;
  transform: translateX(-50%);
  visibility: hidden;
  transition: opacity .3s, visibility .3s;
  a {
    color: $color1;
  }

  .title_smenu {
    font-size: 4.5rem;
    font-weight: 400;
    color: $color1;
    height: 16.4rem;
    text-align: center;
    padding-top: 4rem;
    &::after {
      content:'';
      position: absolute;
      top: 2.6rem;
      right: 0;
      display: inline-block;
      width: .1rem;
      height: 11.2rem;
      background-color: $color1;
    }
  }
  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 0;
    margin: 1rem auto;
  }
  .s_menu {
    padding: 1.5rem;
    > a {
      font-size: 1.6rem;
      &:hover{
        color: $color2;
      }
    }
    &.catalogue {
      background-color: rgba(255,255,255,0.8);
      color: $color6;
      a { color: $color6; }
    }
  }
}

#primary_nav_wrap .dropdown.show .second_level{
  opacity: 1;
  visibility: visible;
}

/* Sticky */
#header.header_top.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $color11;
  z-index: 1002;
  border-bottom: none;
  box-shadow: 0 .2rem 2rem 0 rgba(0, 0, 0, .3);
  .line2{
    height: 4rem;
    margin-top: .5rem;
    margin-bottom: .5rem;
    #logo{
      width: 18rem;
    }
    .client-nom-header{
      display: none;
    }
    .isLogged .icon-compte{
      font-size: 3rem;
      vertical-align: middle;
      line-height: 1.2;
    }
    .button-icon{
      padding: .2rem .5rem;
      .icon{
        font-size: 1.6rem;
        margin-right: .5rem;
      }
    }
  }
  #primary_nav_wrap ul.first_level > li{
    padding: 1.3rem 0;
  }
  #logo {
    img{
      width: 29rem;
    }
    .baseline{
      display: none;
    }
  }
  .espace_client_link .isLogged .texte_connexion{
    display: none;
  }
}

#header.header_top.sticky + #contenu{
  margin-top: 10rem;
}

/* ACCES */
#acces {
  ul {
    list-style: none;
    padding: 0;
  }
  #accesmenu {
    margin: auto 0 auto auto;
  }
  ul.first_level {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > li {
      display: flex;
      align-items: center;
      height: 100%;
      cursor: pointer;
      position: relative;
      a {
        color: $color8;
        font-size: 14px;
        font-weight: 500;
        transition: all .2s ease-in;
        > .icon,
        > .text {
          vertical-align: baseline;
        }


        &:hover,
        &:focus {
          color: $color6;
        }
      }
      &.dropdown .icon {
        font-size:9px;
      }
    }

    & > li.dropdown:hover > .second_level,
    & > li.dropdown > a:hover ~ .second_level,
    & > li.dropdown > a:focus ~ .second_level {
      opacity: 1;
      visibility: visible;
    }
    & > li.dropdown:hover > a > .icon {
      opacity: 1;
      visibility: visible;
    }
  }
  .second_level {
    position: absolute;
    z-index: 2;
    top: calc(100% - 10px);
    left: 0;
    width:200px;
    background-color: $color2;
    color: $color6;
    cursor: initial;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s, visibility .3s;
    > ul {
      padding: 10px 15px;
    }
  }
}
@keyframes move_overlay {
  0% {
    background-color: rgba(0,0,0,.7);
  }
  50% {
    background-color: rgba(0,0,0,.7);
  }
  100% {
    background-color: rgba(0,0,0,0);
  }
}
/* toast/alert message */
.alert-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    /*background-color: rgba(0,0,0,.7);*/
    height: 100vh;
    width: 100vw;
    pointer-events: none;
    animation: 2s linear 0s 1 normal move_overlay;
  }
  .alert {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1.5rem;
    margin-bottom: 0;
    font-size: 1.8rem;

    p {
      margin-bottom: 0;
    }

  }
}
body .fancybox-content {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  .icon {
    font-size: 2.5rem;
    margin-right: 1rem;
    @media (max-width: $breakpoint-sm) {
      font-size: 2rem;
    }
  }
  .content-button {
    width: 100%;
    text-align: center;
    margin-top: 1rem;
  }
  &.alert-success {
    background-color: #EDF6EF !important;
  }
}
/* Header step tunnel */
.header-tunnel{
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 80rem;
  .step-tunnel{
    position: relative;
    width: 18rem;
    text-align: center;
    opacity: .4;
    pointer-events: none;
    &.active{
      opacity: 1;
      pointer-events: auto;
      &.current{
        pointer-events: none;
      }
    }
    .num-tunnel{
      width: 5.5rem;
      height: 5.5rem;
      padding-top: .8rem;
      margin-left: auto;
      margin-right: auto;
      border-radius: 50%;
      background-color: $color2;
      @include flex_center;
      font-family: 'Rasbern', sans-serif;
      font-size: 3.6rem;
      color: #fff;
      transition: 0s;
    }
    .text{
      font-size: 1.5rem;
      font-weight: 700;
      color: $color1;
    }
    .sep{
      position: absolute;
      top: 16%;
      right: -7.3rem;
      width: 12rem;
      background-color: #C8C8C8;
    }
  }
}

.header_top.sticky .header-tunnel .step-tunnel {
  .num-tunnel{
    width: 4rem;
    height: 4rem;
  }
  .text{
    display: none;
  }
}

/* Popin Newsletter */
.popin-nl{
  max-width: 64rem;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  border: .3rem solid $color2;
  padding: 1.5rem 2rem 2rem;
  img {
    margin-bottom: 2rem;
  }
  p{
    font-size: 2rem;
    line-height: 1.4;
    font-weight: 700;
    font-family: Raleway,sans-serif;
  }
  ul {
    list-style-type: none;
    margin-top: 2rem;
    margin-bottom: 2.5rem;
    li{
      padding-left: .8rem;
      font-weight: 700;
      margin-bottom: .3rem;
      &::before{
        content: "\EA43";
        font-family: kfont;
        margin-right: 1rem;
        vertical-align: bottom;
      }
    }
  }
  input,
  .button{
    height: 5.2rem;
    width: 28.5rem;
  }
  input{
    padding-left: 1rem;
    font-weight: 700;
    margin-left: auto;
    margin-right: auto;
    &::placeholder{
      text-align: center;
    }
  }
  .button{
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1.4rem;
    padding: .8rem 0.5rem;
  }
}

.js-link {
    cursor: pointer;
    transition: all .3s ease-in;
}
.js-link:hover {
    opacity: 1;
    color: #e6a61c !important;
}

/* MEDIA QUERIES */
.container.container-xxl {
  max-width: calc(100% - 8rem);
}

@media (min-width: $breakpoint-xl) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: $breakpoint-xl;
    margin-left: auto;
    margin-right: auto;
    padding-right: 0;
    padding-left: 0;
  }
}

@media (max-width: $breakpoint-xl) {
  #primary_nav_wrap {
    width: calc(100% - 20rem);
    ul.first_level > li{
      font-size: 1.5rem;
    }
  }
  .header_top .line2{
    height: 7.5rem;
    #logo{
      .baseline{
        letter-spacing: 0;
      }
    }
  }
  .panier .isLogged{
    margin-right: 0;
    .texte_connexion{
      display: none;
    }
  }
  .panier .client_header_c .espace_client_link.not_connected .texte_connexion {
    font-size: 1.4rem;
    min-width: 11rem;
  }
  .header-tunnel .step-tunnel{
    .num-tunnel {
      width: 4rem;
      height: 4rem;
      padding-top: 0.6rem;
      font-size: 3rem;
      margin-bottom: 0.3rem;
    }
    .text{
      font-size: 1.4rem;
      line-height: 0.9;
    }
    .sep{
      top: 10%;
      right: -3.3rem;
      width: 7rem;
    }
  }
}

@media (max-width: $breakpoint-lg) {
  #primary_nav_wrap {
    width: calc(100% - 15rem);
    ul.first_level > li{
      font-size: 1.3rem;
      margin: 0 1.2rem;
    }
  }

  .header_top .line2{
    #logo{
      width: 19rem;
      margin-left: 5rem;
      img{
        width: 16rem;
      }
      .baseline {
        font-size: 1rem;
      }
    }
    .panier_link{
      padding: .6rem .5rem;
      font-size: 1.3rem;
      & + .panier_link{
        margin-left: 1rem;
      }
    }
    .panier{
      .button-icon {
        margin-left: 2rem;
      }
    }
  }
  .header-tunnel{
    width: calc(100% - 40rem);
    .step-tunnel .text{
      font-size: 1.3rem;
    }
  }
}

@media (max-width: $breakpoint-md) {
  #primary_nav_wrap{
    display: none;
  }
  .header_top{
    .line2 {
      margin-top: .6rem;
      margin-bottom: 0;
      height: 4.5rem;
      .client-nom-header{
        display: none;
      }
      .panier{
        .panier_link,
        .sep-vertical{
          display: none;
        }
        .client_header_c{
          margin-right: 1.5rem;
          .icon-cross{
            right: -.7rem;
          }
        }
      }
      #logo{
        margin-left: 4rem;
        .baseline{
          display: none;
        }
      }
    }
  }
  .header-tunnel .step-tunnel{
    .sep,
    .text{
      display: none;
    }
  }
  #header #menu_launcher{
    top: .7rem;
    left: 0;
  }
  #home #header.header_top.sticky + #contenu,
  #header.header_top.sticky + #contenu {
    margin-top: 6.2rem;
  }
  .panier .client_header_c .espace_client_link{
    width: auto;
    &.not_connected .texte_connexion{
      display: none;
    }
  }
  .header_top .line2 .noLogged .icon-compte{
    display: block;
    margin-right: .4rem;
  }
  #article .header_top .line2,
  #choix-paiement .header_top .line2,
  #livraison .header_top .line2,
  #panier .header_top .line2{
    height: 4.5rem;
  }
  #article .espace_client_link .texte_connexion,
  #choix-paiement .espace_client_link .texte_connexion,
  #livraison .espace_client_link .texte_connexion,
  #panier .espace_client_link .texte_connexion{
    display: none;
  }
}


@media (max-width: $breakpoint-sm) {
  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    padding-right: 10px;
    padding-left: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  #article .header_top .line2 #logo,
  #choix-paiement .header_top .line2 #logo,
  #livraison .header_top .line2 #logo,
  #panier .header_top .line2 #logo{
    margin-top: 0;
    margin-left: 4rem;
    margin-right: 1rem;
  }

  .header_top .line1-bis .container {
    height: auto;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  #header.header_top.sticky .line2 {
    .icon-compte{
      line-height: 1;
    }
    .button-icon {
      padding: 0;
      .icon{
        font-size: 3rem;
        &::before{
          line-height: 1;
        }
      }
    }
  }
  .header-tunnel {
    width: calc(100% - 30rem);
  }
}

@media (max-width: $breakpoint-xs) {
  #hello {
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-weight: 700;
    //display: flex;
    display: none;
    justify-content: center;
    width: 100%;
    font-size: 1.4rem;
    color: #000;
  }
  #home_backoffice{
    .buttons-cta {
      a {
        margin: 0 0 20px 0;
      }
    }
  }
  .header_top .line1-bis {
    display: none;
  }
  #home .header_top .line2,
  .header_top .line2{
    .panier {
      right: 3rem;
      .icon-cross{
        margin-left: .3rem;
        margin-right: .3rem;
        right: -.5rem;
      }
      .client_header_c{
        display: none;
      }
      .no_client{
        margin-right: 1.2rem;
      }
      .button-icon{
        margin-left: 0;
      }
    }
  }
  .alert-container{
    .alert{
      padding: .8rem;
    }
  }
  .page_home{
    .panier{
      width: auto;
    }
    .header_top .line2 #logo .baseline{
      display: none;
    }
  }
  .header_top.sticky .line2{
    .panier .icon-cross{
      top: -.5rem;
    }
  }
  .header_top .line1-bis p{
    font-size: 1.2rem;
  }
  .header-tunnel,
  #article .header-tunnel,
  #choix-paiement .header-tunnel,
  #livraison .header-tunnel,
  #panier .header-tunnel{
    display: none;
  }
}

