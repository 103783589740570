$color1: #11474E;
$color2: #FABC4E;
$color3: #e6a61c;
$color4: #64B271;
$color5: #FE9600;
$color6: #707070;

$color7: #b4b632;
$color8: #b4b4b4;
$color9: #f4f4f6;
$color10: #b2b2b2;
$color11: #f2f2f1;
$color12: #A0A02E;
$color13: #e62176;
$color14: #25D366;
$color15: #767676;
$color16: #F5FAF6;
$color17: #DEE2E6;

/* Nutriscore */
$nutri0: #f0f0f5;
$nutri20: #d44427;
$nutri40: #e1832f;
$nutri60: #f7cd45;
$nutri80: #92b943;
$nutri100: #377d45;

/* Variables couleurs */
$colors: (
        "1": #11474E,
        "2": #FABC4E,
        "3": #e6a61c,
        "4": #64B271,
        "5": #FE9600,
        "6": #707070,

        "7": #b4b632,
        "8": #b4b4b4,
        "9": #f4f4f6,
        "10": #b2b2b2,
        "11": #f2f2f1,
        "12": #A0A02E,
        "13": #e62176,
        "14": #25D366,
        "15": #767676,
        "16": #F5FAF6,
        "17": #DEE2E6,

);

.color1{
  color: #11474E;
}
.color2{
  color: #FABC4E;
}
.color3{
  color: #e6a61c;
}
.color4{
  color: #64B271;
}


/* desactive pour le backo */
/* body:not([class*="breakpoint"]) {} */
body,
#cms .content {

  /* Genere automatiquement les styles generiques du projet en fonction des couleurs */
  @each $number-color, $color in $colors {

    /* color */
    .color#{$number-color},
    .color#{$number-color} a {
      color: $color;
    }

    /* background-color */
    .bg#{$number-color} {
      background-color: $color;
    }

    /* border color */
    .border#{$number-color} {
      border: .1rem solid $color;
    }

    /* separator color */
    .sep#{$number-color} {
      margin: 1.5rem 0;
      height: .1rem;
      background-color: $color;
      width: 100%;
    }

    /* button generique */
    /* Si blanc */
    @if ($color == $color1 or $color == $color4 or $color == $color5) {
      .button.button#{$number-color} {
        background-color: $color;
        color: $color7;
        border: .1rem solid $color;

        &:hover, &:focus {
          background-color: #333;
          color: $color;
          border: .1rem solid $color;
        }
      }
    } @else {
      .button.button#{$number-color} {
        background-color: $color;
        color: $color1;
        border: .1rem solid $color;

        &:hover, &:focus {
          background-color: $color1;
          color: $color;
          border: .1rem solid $color;
        }
      }
    }

  }
}
