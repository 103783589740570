/* Section FAQ */
.faq{
  position: relative;
  .green_block{
    background-color: #EDF6EF;
    padding-top: 4rem;
    padding-bottom: 2.4rem;
  }
  .h2{
    font-size: 3rem;
    margin-bottom: 4rem;
  }
  .button_c{
    @include flex_center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    .button.button-xl {
      display: inline-flex;
      align-items: center;
      height: 7rem;
      margin-left: auto;
      margin-right: auto;
      padding: .2rem 1.3rem;
      .icon{
        font-size: 4rem;
        line-height: 1;
      }
      .icon-chat{
        font-size: 3.3rem;
        margin-right: 5.5rem;
      }
    }
  }
}

#faq_question_container{
  padding-left: 3rem;
  padding-right: 3rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 120rem;
  .faq_question_card{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    .faq_question{
      display: flex;
      font-weight: 700;
      padding-bottom: .5rem;
      .icon-arrow-right{
        margin-left: auto;
        transition: transform .3s;
        height: 2.4rem;
      }
      &:not(.collapsed) .icon-arrow-right{
        transform: rotate(90deg);
      }
  }
    .faq_reponse{
      font-style: italic;
      font-weight: 300;
      padding-left: 2rem;
      padding-right: 4rem;
      p{
        margin: 0;
      }
    }
  }
}

@media (max-width: $breakpoint-xl) {
  .faq::before,
  .faq::after{
    width: calc((100% - 114.3rem) / 2);
  }
}

@media (max-width: $breakpoint-lg) {
  .faq::before,
  .faq::after{
    width: calc((100% - 96.3rem) / 2);
  }
}

@media (max-width: $breakpoint-md) {
  .faq::before,
  .faq::after{
    width: calc((100% - 72.1rem)/ 2);
  }
  .faq .underline{
    padding-bottom: 1rem;
  }
}

@media (min-width: $breakpoint-sm) {
  #faq_title{
    .icon{
      display: none;
    }
  }
  #faq_question_container {
    display: block;
    height: auto !important;
    visibility: visible;
  }
}

@media (max-width: $breakpoint-sm) {
  body:not(#home) #faq_title{
    cursor: pointer;
    &:hover .h2{
      color: $color3;
    }
    .h2{
      position: relative;
      transition: color .3s;
      .icon{
        position: absolute;
        bottom: -1rem;
        right: 2rem;
        transition: transform .3s;
      }
    }
    &:not(.collapsed) .icon{
      transform: rotate(90deg);
    }
  }

  .faq{
    &::before,
    &::after{
      content: none;
    }
    .container{
      max-width: none;
      width: 100%;
      .green_block{
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }
    .faq_img_mobile{
      display: block;
    }
    #faq_question_container{
      padding-left: 0;
      padding-right: 0;
      #faq_question_container .faq_question_card {
        margin-top: 1.5rem;
        margin-bottom: 1rem;
      }
    }
    .h2{
      margin-top: 0;
    }
    .button_c{
      padding-bottom: 2rem;
      margin-left: 0;
      margin-right: 0;
      .button.button-xl{
        margin-right: 2rem;
        margin-left: 2rem;
        width: 100%;
      }
    }
  }
}

@media (max-width: $breakpoint-xs) {
  body:not(#home) #faq_title .h2 .icon{
    right: -1rem;
  }
  #faq_question_container .faq_question_card {
    padding-left: 0;
    padding-right: 0;
    .faq_reponse{
      padding-left: 0;
      padding-right: 0;
      table{
        display: block;
        overflow-x: auto;
        height: auto!important;
      }
    }
  }
  .faq .green_block{
    padding-top: 2rem;
    padding-bottom: .5rem;
    .h2{
      margin-bottom: 2rem;
    }
    .button_c{
      flex-wrap: wrap;
      padding-bottom: 0;
      .button.button-xl{
        width: calc(100% - 2rem);
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1.5rem;
        .icon-chat{
          margin-right: 4.5rem;
        }
      }
    }
  }
}