.recette-card-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  .recette_card{
    position: relative;
    width: calc(100% / 3 - 2rem);
    margin-bottom: 3rem;
    background-color: #fff;
    margin-left: 1rem;
    margin-right: 1rem;
    overflow: hidden;
    @include box-shadow;
    &.recette-gratuite{
      box-shadow: 2px 2px 5px 0 $color2;
      order: -1;
      h3 a{
        color: $color2;
      }
      .icon-cadeau{
        margin-right: .5rem;
      }
    }
  }
  .recette_card.added{
    @include box_shadow_selected;
  }
}

.options-list-c .recette_card{
  box-shadow: none;
}

.img_container_card{
  position: relative;
  transition: filter .2s;
  a{
    position: relative;
    display: block;
    &:hover{
      opacity: 1;
      color: $color1;
      .overlay_recette{
        opacity: 1;
        visibility: visible;
      }
    }
    img{
      object-fit: cover;
      height: 30.9rem;
      width: 100%;
      background-color: #fff;
      transition: filter .3s linear;
    }
    .icon-zoom{
      position: absolute;
      top: 1rem;
      left: 2rem;
      color: #fff;
      font-size: 3.5rem;
    }
    .icon-robot{
      position: absolute;
      bottom: 1rem;
      right: 1rem;
    }
    .icon-leaf{
      display: none;
      position: absolute;
      top: 1rem;
      left: 2rem;
      width: 5rem;
      height: 5rem;
      padding: 10px;
      background-color: #04B404;
	    color: #ffffff;
      font-size: 3rem;
    }
    img.label{
      position: absolute;
      top: 1rem;
      right: 1rem;
      width: 6rem;
      height: auto;
    }
    .overlay_recette{
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(189, 215, 219, 0.75);;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 1.8rem;
      font-weight: 500;
      text-transform: uppercase;
      opacity: 0;
      visibility: hidden;
      transition: opacity .3s;
      .icon{
        font-size: 3.6rem;
      }
    }
  }
}

.img_container_card.card-selected img{
  filter: blur(.4rem);
}

#espaceClientInt .img_container_card a img.recette {
  height:auto;
}

.liste-tags{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-left: -.4rem;
  padding-right: -.4rem;
  max-width: calc(100% - 6.8rem);
  margin-left: auto;
  margin-right: auto;
  .tag{
    margin-left: .2rem;
    margin-right: .2rem;
    font-size: 1.5rem;
    color: $color4;
    text-transform: uppercase;
    &::after{
      content: '-';
      display: inline-block;
      margin-left: .2rem;
    }
    &:last-child::after{
      content: none;
    }
  }
}

.add-recette{
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  display: block;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  line-height: 1;
  text-align: center;
  color: #fff;
  background-color: $color2;
  font-weight: 300;
  cursor: pointer;
  transition: background-color .3s, transform .3s, color .3s;
  outline: none;
  border: none;
  padding: 0;
  .icon{
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 4rem;
    color: #fff;
  }
  .icon-tick2{
    display: none;
  }
  &:hover{
    background-color: $color4;
  }
  &:focus{
    outline: 0;
  }
  &.recette-selected{
    background-color: $color4;
    &:hover{
      background-color: #efefef;
    }
    .icon-add-recette{
      display: none;
    }
    .icon-tick2{
      display: block;
      font-size: 2.5rem;
      margin-top: .8rem;
    }
    &:hover{
      .icon-tick2{
        display: none;
      }
      .icon-add-recette{
        display: block;
        transform: rotate(135deg);
        color: #eb0505;
        line-height: 0;
      }
    }
  }
}

.content_card{
  padding: 2rem 1.8rem 3.4rem;
  text-align: center;
  h3,
  .h3{
    min-height: 3.6rem;
    line-height: 1.3;
    .icon{
      color: $color2;
      margin-left: 1rem;
      font-size: 2.5rem;
    }
  }
  h4,
  .h4{
    font-size: 1.8rem;
    text-align: center;
    max-width: calc(100% - 7rem);
    margin: 1.5rem auto 1rem;

  }
  .supplement_recette{
    text-align: center;
    font-weight: 700;
    font-size: 2rem;
  }
  .button{
    display: inline-block;
    width: 64%;
    max-width: 30rem;
    font-size: 2.2rem;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    &.add_article{
      display: block;
    }
    &.remove_article{
      display: none;
    }
    &.view {
      width: 33%;
      span {
        margin: 0;
        padding: 0;
        line-height: 0;
      }
    }
  }
}

.recette_card.selected{
  .button.add_article{
    display: none;
  }
  .button.remove_article{
    display: inline-block;
  }
}

.popin_zoom_recette{
  position: relative;
  padding: 4rem 3rem 1rem;
  width: 100%;
  max-width: 140rem;
  display: none;
  .fancybox-close-small{
    display: none;
  }
  .nav-pills{
    justify-content: center;
    .custom_green_button{
      color: #11474e;
      background-color: #fff;
      &.active {
        color: #fff;
        background-color: $color4;
      }
      &:hover{
        color: #333;
        background-color: rgba(9,73,82,.1);
      }
      &:focus {
        opacity: 1;
      }
    }
  }
  .tab-content{
    margin-top: 4rem;
    margin-bottom: 3rem;
    .preparation-container .tab-desc{
      display: flex;
      justify-content: space-between;
    }
  }
  .underline_link_container{
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
  .popin-add-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 32rem;
    height: 4.4rem;
    margin-left: auto;
    margin-top: 2rem;
    font-size: 1.6rem;
    font-weight: 500;
    padding: .8rem 2rem;
    border-radius: 4rem;
    border-color: $color4;
    background-color: $color4;
    transition: all .3s;
    .icon {
      margin-right: 1rem;
    }
    .recette-a-ajouter {
      color: #fff;
      border-color: $color4;
      transition: all .3s;
    }
    &:hover {
      border-color: $color4;
      background-color: #fff;
      .recette-a-ajouter {
        color: $color4;
      }
    }
    .recette-ajoutee {
      display: none;
    }
    &.recette-ajoutee {
      border-color: #64b271;
      color: #64b271;
      background-color: #fff;
      &:hover {
        color: #64b271;
      }
      .recette-ajoutee {
        display: block;
      }
      .recette-a-ajouter {
        display: none;
      }
    }

    &.js-selected,
    &.js-not-selected {
      .item_flex {
        display: flex !important;
      }
      .item_none {
        display: none !important;
      }
    }
    &.js-selected {

      .recette-ajoutee {
        display: flex;
      }
      .recette-a-ajouter {
        display: none;
      }
    }
    &.js-not-selected {
      .recette-ajoutee {
        display: none;
      }
      .recette-a-ajouter {
        display: flex;
      }
    }
    &.disable {
      background-color: #D3D3D3;
      border: 1px solid #D3D3D3;
      pointer-events: none;
      cursor: not-allowed;

      + .desc_disable {
        display: flex;
        flex-direction: column;
        color: $color5;
      }
    }

  }

}
.desc_disable {
  display: none;
  text-align: right;
  line-height: 1.2;
  @media (max-width: $breakpoint-xl) {
    font-size: 1.3rem;
  }
  @media (max-width: $breakpoint-md) {
    font-size: 1.1rem;
  }
  @media (max-width: $breakpoint-xs) {
    font-size: 1.3rem;
  }
}

/* Onglet préparation */
.preparation-container{
  .recette_card{
    width: calc(35% - 3rem);
    .prepa_card{
      display: flex;
      justify-content: space-between;
      font-size: 1.2rem;
      font-weight: 700;
      margin-top: 2rem;
      margin-bottom: .8rem;
      .icon{
        font-size: 2.5rem;
        color: $color2;
        margin-right: .5rem;
      }
    }
  }
  .popin_zoom_recette_desc{
    width: calc(65% - 3rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    header{
      font-style: italic;
      margin-bottom: 3rem;
    }
    h5{
      margin-bottom: .8rem;
    }
    .robot_container{
      display: flex;
      align-items: center;
      margin-top: 2rem;
      .text-sm{
        font-size: 1.2rem;
        margin-left: 1rem;
      }
    }
    .veggie_container{
      display: flex;
      align-items: center;
      margin-top: 2rem;
      .text-sm{
        font-size: 1.2rem;
        margin-left: 1rem;
      }
    }
  }
}

/* Onglet nutrition */
.nutrition_container{
  font-size: 1.2rem;
  .h6{
    font-size: 1.6rem;
    text-align: left;
    margin-bottom: 4.5rem;
  }
  .highlight{
    font-weight: 700;
    //display: block;
  }
  .table_nutrition{
    font-size: 1.4rem;
  }
}
.progress-bar-nutrition-container{
  position: relative;
  display: block;
  width: 100%;
  height: .3rem;
  background-color: $nutri0;
  margin-bottom: 5rem;
  border-radius: 1rem;
  .progress-bar-nutrition{
    position: absolute;
    height: .3rem;
    top: 0;
    .progress-bar-nutrition-text{
      position: absolute;
      top: 1rem;
      left: 0;
      margin-right: .5rem;
      line-height: 1.2;
    }
    &:nth-child(2n) .progress-bar-nutrition-text{
      top: auto;
      bottom: 1rem;
    }
    &.lipides-bar{
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
      background-color: $nutri20;
      left: 0;
    }
    &.proteines-bar{
      background-color: $nutri60;
    }
    &.glucides-bar{
      background-color: $nutri100;
    }
    &.sel-bar {
      background-color: purple;
    }
    &.fibres-bar {
      border-top-right-radius: 1rem;
      border-bottom-right-radius: 1rem;
      background-color: #3a9efd;
    }
  }
}
.progress-bar-nutrition-mobile{
  display: none;
}
.table_nutrition{
  width: 100%;
  text-align: center;
  margin-top: .5rem;
  thead{
    tr td{
      width: calc(100% / 3);
      line-height: 5rem;
      font-size: 1.4rem;
    }
  }
  tbody{
    .highlight{
      text-align: left;
      padding-left: 1rem;
      font-size: 1.4rem;
    }
    tr{
      line-height: 4rem;
      border: 1px solid #fff;
    }
    tr:nth-child(2n){
      background-color: #ffedbf;
    }
    tr:nth-child(2n+1){
      background-color: #fff8e5;
    }
  }
}
.infos-origines{
  display: flex;
  flex-direction: column;
  .img_container_origines{
    text-align: right;
    .nutriscore-libelle{
      font-size: 1.6rem;
      margin-right: 3.8rem;
      font-weight: 700;
    }
    img{
      max-width: 16rem;
    }
  }
  h5, .h5 {
    margin-top: 2rem;
    margin-bottom: 3rem;
  }
  .origines-container-text{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }
  .progress-bar-origines-container{
    position: relative;
    display: block;
    width: 100%;
    height: .3rem;
    background-color: $nutri0;
    margin-bottom: 3rem;
    border-radius: 1rem;
    .progress-bar-origines{
      position: absolute;
      height: .3rem;
      top: 0;
      left: 0;
      right: calc(100% - 51%);
      background-color: $nutri20;
      border-radius: 1rem;
    }
  }
  .sep-horizontal{
    margin-top: auto;
    margin-bottom: 2rem;
  }
  .img_etiquettable_container{
    text-align: right;
    img{
      max-width: 20rem;
    }
  }
}



@media (max-width: $breakpoint-xl) {
  .img_container_card a img{
    height: 23rem;
  }
  .option_recette .content_card h3,
  .content_card h3 {
    min-height: 5.4rem;
  }
  .liste-tags .tag{
    font-size: 1rem;
  }
}

@media (max-width: $breakpoint-lg) {
  .popin_zoom_recette{
    padding: 2rem 2rem 1rem;
  }
  .preparation-container .recette_card .prepa_card{
      flex-wrap: wrap;
  }
  .infos-origines{
    h5{
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
    .progress-bar-origines-container{
      margin-bottom: 2rem;
    }
    .img_container_origines img{
      max-width: 12rem;
    }
  }
  .table_nutrition tbody tr{
    line-height: 3.2rem;
  }
  .img_container_card a img{
    height: 19rem;
  }
  .content_card h3 {
    min-height: 7.2rem;
  }
  .recette-card-list .recette_card{
    width: calc(100% / 3 - 1rem);
    margin-left: .5rem;
    margin-right: .5rem;
    margin-bottom: 1rem;
  }
  .liste-tags .tag{
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }
}

@media (max-width: $breakpoint-md) {
  .content_card{
    padding: 1rem 0.8rem 3.2rem;
  }
  .content_card h3,
  .content_card .h3{
    font-size: 1.6rem;
    min-height: 8rem;
    overflow: hidden;
  }
  .recette-card-list .recette_card {
    width: calc(50% - 1rem);
  }
  .preparation-container .recette_card {
    width: calc(37% - 1rem);
  }
  .nutrition_container .infos-origines {
    margin-top: 1.5rem;
  }
  .img_container_card a img{
    height: 14.4rem;
  }
}
@media (max-width: $breakpoint-sm) {
  .popin_zoom_recette {
    padding: 2rem 1.5rem 1rem;
    .underline_link_container{
      top: 1rem;
      bottom: 1rem;
    }
    .nav-pills{
      .nav-item{
        width: 100%;
        text-align: center;
      }
      .custom_green_button{
        display: inline-block;
        width: 32rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        padding-top: .5rem;
        padding-bottom: .5rem;
      }
    }
    .tab-content{
      margin-top: 1rem;
      margin-bottom: 1rem;
      .preparation-container,
      .tab-desc{
        flex-wrap: wrap;
      }
      .recette_card,
      .popin_zoom_recette_desc {
        width: 100%;
      }
      .content_card{
        padding-top: .2rem;
        h3,
        .h3{
          height: auto;
        }
        h4,
        .h4{
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
      }
      .prepa_card{
        margin-top: 1rem;
      }
    }
    .progress-bar-nutrition-desktop{
      display: none;
    }
    .progress-bar-nutrition-mobile{
      display: block;
      .progress-bar-nutrition-container{
        margin-bottom: 4rem;
        .progress-bar-nutrition-text{
          position: static;
          display: block;
          margin-top: .8rem;
          width: 25rem;
        }
      }
    }
  }
  .img_container_card a img{
    height: 16.4rem;
    &.label{
      width: 5rem;
    }
  }
  .add-recette:hover{
    background-color: $color2;
  }
  .add-recette.recette-selected:hover{
    background-color: $color4;
    .icon-add-recette{
      display: none;
    }
    .icon-tick2 {
      display: block;
    }
  }
}
@media (max-width: $breakpoint-xs) {
  .recette-card-list .recette_card{
    width: 100%;
  }
  .popin_zoom_recette{
    padding-top: 3rem;
    .underline_link_container > a {
      line-height: 0.4;
    }
    .nav-pills .custom_green_button{
      width: 28rem;
      font-size: 1.5rem;
      margin-left: 0;
      margin-right: 0;
    }
    .table_nutrition tbody{
      .highlight{
        font-size: 1.3rem;
      }
      tr{
        line-height: 2.8rem;
      }
    }
  }
  .img_container_card a img{
    height: auto;
    max-height: 25rem;
  }
  .option_recette .content_card h3,
  .content_card h3 {
    min-height: 3.5rem;
    line-height: 1.2;
    margin-right: 5rem;
  }
  .liste-tags{
    max-width: 100%;
    margin-right: 5rem;
  }
  .content_card{
    padding: .5rem .5rem .8rem;
    h3,
    .h3{
      font-size: 1.4rem;
      margin-top: .5rem;
    }
    h4,
    .h4{
      font-size: 1.2rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
}