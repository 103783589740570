#gotop{
  position: fixed;
  bottom: 8rem;
  right: 2rem;
  border-radius: 50%;
  color: #fff;
  background-color: $color4;
  border: .1rem solid #fff;
  opacity: .85;
  width: 4rem;
  height: 4rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  cursor: pointer;
  font-size: 1.8rem;
  transition: all .3s;
  &:hover{
    background-color: $color1;
  }
}

.sticky-compose{
  position: fixed;
  top: 400px;
  right: -.1rem;
  transform: translateY(-50%);
  border: .1rem solid #fff;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  background-color: $color4;
  z-index: 9;
  transition: background-color .3s;
  &:hover{
    background-color: $color1;
  }
  .sticky-compose-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    padding: 1.2rem 1.4rem 1rem;
    &:focus,
    &:hover{
      color: #fff;
      opacity: 1;
    }
    .text{
      font-size: 1.4rem;
      margin-bottom: 1.2rem;
      line-height: 1.2;
      text-transform: uppercase;
      margin-top: .4rem;
    }
    .icon{
      font-size: 3.5rem;
      line-height: 1;
    }
  }
}

/* Desactivation sticky dans espace personnel */
.page_index-espace-client,
.page_mes-achats,
.page_profile,
.page_recettes,
.page_Recettes,
.page_espace-client-fidelite,
.page_espace-client-bons-reductions,
.page_abonnement,
.page_parrainages,
.page_mes-adresses{
  .sticky-compose{
    display: none;
  }
}

@media (max-width: $breakpoint-md) {
  .sticky-compose-content {
    .text{
      display: none;
    }
  }
}