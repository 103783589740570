@mixin cover_img{
  display: block;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
}

@mixin flex_center{
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex_between{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin absolute_container{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@mixin box_shadow{
  box-shadow: 2px 2px 5px 0 #cfcfcf;
}

@mixin box_shadow_selected{
  box-shadow: 2px 2px 5px 0 #094952;
}

.text-right{
  text-align: right;
}

.desktop-only{
  display: block;
}
.tablet-only,
.mobile-only{
  display: none;
}

/* Limite le nombre de ligne du champ, NE PAS METTRE DE HAUTEUR */
@mixin line-clamp($nb-line:3) {
  display: -webkit-box;
  -webkit-line-clamp: $nb-line;
  line-clamp: $nb-line;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  overflow: hidden;
}

@media (max-width: $breakpoint-sm) {
  .desktop-only{
    display: none;
  }
  .tablet-only{
    display: block;
  }
}

@media (max-width: $breakpoint-xs) {
  .mobile-hidden{
    display: none;
  }
  .mobile-block,
  .mobile-only{
    display: block;
  }
  span.mobile-only{
    display: inline-block;
  }
}

// Permet de garder le ratio.
// Pour changer le ratio (carré par défaut), ajouter sur l'élément container avec la classe ratio un style="--ratio: 16/9"
.ratio {
  position: relative;
  min-height: 20rem;
}

.ratio::before {
  content: '';
  display: block;
  padding-bottom: calc(100% / (var(--ratio, calc(1))));
}

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* chemin img */
$base-img-url: "../img/theme";